import React, { useEffect, useState } from "react";
import Profile from "../../components/USER/Profile";
import { getUserProfile } from "../../helper/APIs/Auth";
import { Box, CircularProgress } from "@mui/material";

const ProfilePage = () => {
  const [profileInfo, setProfileInfo] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getUserProfile()
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setProfileInfo(res.data);
        }
      })
      .catch((err) => setLoading(false));
  }, []);

  return (
    <div>
      {loading ? (
        <div style={{ margin: "15rem 0rem" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <Profile profileData={profileInfo} />
      )}
    </div>
  );
};

export default ProfilePage;
