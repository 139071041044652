import React, { useEffect, useState } from "react";
import DynamicFormField from "./DynamicFormField";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EditDynamicFormFields from "./EditDynamicFormFields";

const Amenities = ({
  setFormFieldValue,
  formFieldValue,
  getInputObject,
  categoryChanges,
}) => {
  const params = useParams();

  const getAllCategories = useSelector(
    (state) => state.categoryReducer.categories
  );

  const [dynamicInputs, setDynamicInputs] = useState(getInputObject);
  const [categoryListingData, setCategoryListingData] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [category, setCategory] = React.useState([]);
  useEffect(() => {
    setDynamicInputs(getInputObject);
  }, [getInputObject]);

  useEffect(() => {
    setCategory(getAllCategories);
  }, [getAllCategories]);

  useEffect(() => {
    if (category) {
      const listingCategory = category?.filter(
        (item) => item.id === dynamicInputs?.categoryId
      );
      setCategoryListingData(listingCategory);
    }
  }, [category, dynamicInputs?.categoryId]);
  useEffect(() => {
    if (params.id) {
      const newArray = categoryListingData[0]?.categoryFormFields?.map(
        (newItem) => {
          let value;
          const existingItem = dynamicInputs?.listingFormFields?.find(
            (editItem) => editItem.categoryFormField.id === newItem.id
          );

          if (existingItem) {
            if (
              existingItem.chosenValue &&
              existingItem.chosenValue.length > 0
            ) {
              value = existingItem;
            } else if (newItem.formField.ffType !== "MULTI_CHECK") {
              value = newItem;
            }
          } else {
            value = {
              id: "",
              listingId: "",
              categoryFormField: newItem,
            };
          }
          return value;
        }
      );
      setFilteredArray(newArray);
    }
  }, [categoryListingData, dynamicInputs?.listingFormFields, params.id]);

  const transformedArray = dynamicInputs?.categoryFormFields?.map((item) => {
    return {
      categoryFormField: item,
      chosenValue: [],
    };
  });
  return (
    <div>
      {!params.id && !categoryChanges && (
        <DynamicFormField
          newFields={dynamicInputs?.categoryFormFields}
          setFormFieldValue={setFormFieldValue}
          formFieldValue={formFieldValue}
          categoryChanges={categoryChanges}
          transformedArray={transformedArray}
        />
      )}

      {params.id && (
        <EditDynamicFormFields
          newFields={filteredArray}
          oldVal={dynamicInputs?.listingFormFields}
          setFormFieldValue={setFormFieldValue}
          formFieldValue={formFieldValue}
        />
      )}
    </div>
  );
};

export default Amenities;
