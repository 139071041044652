import { UPDATE_LOGIN_POPUP_STATE, UPDATE_ROUTE } from "./types";

export const updatePreviousRoute = (payload) => {
  return {
    type: UPDATE_ROUTE,
    payload,
  };
};

export const updateLoginPopup = (payload) => {
  return {
    type: UPDATE_LOGIN_POPUP_STATE,
    payload,
  };
};
