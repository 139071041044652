import React from "react";

const MoreInfoInputIcon = ({ label }) => {
  return (
    <i
      className=" icon-info-circled cursor-pointer fs-6 ms-2 "
      data-toggle="tooltip"
      data-placement="right"
      title={label}
      style={{
        fontSize: "1.1rem",
      }}
    ></i>
  );
};

export default MoreInfoInputIcon;
