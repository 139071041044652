import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

const LocationPopUp = ({
  open,
  handleSubmit,
  placeId,
  countryId,
  inputRef,
  loading,
  selectedCategories,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ zIndex: "10" }}
      closeAfterTransition
      disablePortal
    >
      <DialogTitle>
        <div className="small-dialog-header d-flex justify-content-center">
          <h3>Enter Location</h3>
        </div>
      </DialogTitle>

      <DialogContent>
        <div
          className="custom-search-input-2 mt-5"
          style={{
            position: "relative",
            zIndex: "5000",
            border: "1px solid #d2d8dd",
          }}
        >
          <input
            className="form-control"
            type="text"
            placeholder="Select Your City"
            ref={(node) => {
              inputRef.current = node;
            }}
            autoFocus
          />
        </div>

        <div className="d-flex justify-content-center">
          <button
            type="submit"
            disabled={loading}
            className="btn_1 rounded full-width mt-5"
            onClick={() => handleSubmit(placeId, selectedCategories, countryId)}
          >
            Search Listing
            {loading && (
              <span
                className="spinner-border spinner-border-sm mx-3"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LocationPopUp;
