import { ClearOutlined } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import CardWrapper from "./CardWrapper.js";
import MoreInfoInputIcon from "../../../../shared/InputFieldsWithLabels/MoreInfoInputIcon.js";
import { getDayName } from "../../../../shared/Constants/constants.js";

// Function to format the time
const formatTime = (time) => {
  return `${time.toString().padStart(2, "0")}`;
};

const BusinessHours = ({ timeSlots, setTimeSlots }) => {
  // const [timeSlots, setTimeSlots] = React.useState([]);
  const [dayOfWeek, setDayOfWeek] = React.useState("MONDAY");
  const [slot1StartTime, setSlot1StartTime] = React.useState("09:00");
  const [slot1EndTime, setSlot1EndTime] = React.useState("12:00");
  const [slot2StartTime, setSlot2StartTime] = useState("");
  const [slot2EndTime, setSlot2EndTime] = useState("");
  const [addSecondSlot, setAddSecondSlot] = React.useState(false);
  const [allDayOpen, setAllDayOpen] = React.useState(false);
  const [alwaysOpen, setAlwaysOpen] = React.useState(false);
  const handleDayChange = (event) => {
    setDayOfWeek(event.target.value);
  };
  const handleOpenTimeChange = (event) => {
    setSlot1StartTime(event.target.value);
  };

  const handleCloseTimeChange = (event) => {
    setSlot1EndTime(event.target.value);
  };

  const handleAddTimeSlot = () => {
    // Check if all fields are selected
    if (!dayOfWeek || !slot1StartTime || !slot1EndTime) {
      return;
    }

    // Check if the day already exists
    const existingSlotIndex = timeSlots.findIndex(
      (slot) => slot.dayOfWeek === dayOfWeek
    );
    if (existingSlotIndex !== -1) {
      // Update the existing slot
      const updatedSlots = [...timeSlots];
      updatedSlots[existingSlotIndex].slot1StartTime = slot1StartTime;
      updatedSlots[existingSlotIndex].slot1EndTime = slot1EndTime;

      if (addSecondSlot) {
        // Add the second time slot if checked
        updatedSlots[existingSlotIndex].slot2StartTime = slot2StartTime;
        updatedSlots[existingSlotIndex].slot2EndTime = slot2EndTime;
      } else {
        // Clear the second time slot
        delete updatedSlots[existingSlotIndex].slot2StartTime;
        delete updatedSlots[existingSlotIndex].slot2EndTime;
      }

      setTimeSlots(updatedSlots);
    } else {
      // Add a new slot
      const newSlot = {
        id: null,
        listingId: null,
        dayOfWeek,
        slot1StartTime,
        slot1EndTime,
        allDayOpen: addSecondSlot,
        alwaysOpen: true,
        permanentlyClosed: false,
        slot2StartTime,
        slot2EndTime,
      };
      setTimeSlots([...timeSlots, newSlot]);
    }

    // Clear the input fields
    // setSlot1StartTime("");
    // setSlot1EndTime("");
    setSlot2StartTime("");
    setSlot2EndTime("");
    setAddSecondSlot(false);
  };

  const handleRemoveTimeSlot = (index) => {
    const updatedTimeSlots = [...timeSlots];
    updatedTimeSlots.splice(index, 1);
    setTimeSlots(updatedTimeSlots);
  };

  const newSlot = {
    dayOfWeek: slot1StartTime.toUpperCase(),
    slot1StartTime: formatTime(slot1StartTime),
    slot1EndTime: formatTime(slot1EndTime),
    allDayOpen: allDayOpen,
    alwaysOpen: alwaysOpen,
    permanentlyClosed: "",
  };

  if (addSecondSlot) {
    newSlot.slot2StartTime = formatTime(slot2StartTime);
    newSlot.slot2EndTime = formatTime(slot2EndTime);
  }

  const handle24By7Check = (e) => {
    setAllDayOpen(e.target.checked);
  };
  const handleAlwaysOpen = (e) => {
    setAlwaysOpen(e.target.checked);
    // if (e.target.checked) {
    //   setTimeSlots([
    //     {
    //       dayOfWeek: "",
    //       slot1StartTime: "",
    //       slot1EndTime: "",
    //       allDayOpen: allDayOpen,
    //       alwaysOpen: alwaysOpen,
    //       permanentlyClosed: "",
    //       slot2StartTime: "",
    //       slot2EndTime: "",
    //     },
    //   ]);
    // }
  };

  return (
    <CardWrapper>
      <label className="fw-bold">Business Hours</label>
      <MoreInfoInputIcon label={"Business Hours"} />
      <hr />
      <div className="col-lg-6 d-flex align-items-center">
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={<Checkbox onChange={(e) => handleAlwaysOpen(e)} />}
              label="Always Open"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </div>
      {!alwaysOpen && (
        <>
          <div className="row">
            {timeSlots.map((slot, index) => (
              <>
                <div className="col-lg-3 fw-bold">
                  {getDayName(slot.dayOfWeek.toLowerCase())}
                </div>
                <div className="col-lg-3">
                  {formatTime(slot.slot1StartTime)} -{" "}
                  {formatTime(slot.slot1EndTime)}
                </div>
                <div className="col-lg-3">
                  {slot?.slot2StartTime && slot?.slot2EndTime ? (
                    <>
                      {formatTime(slot.slot2StartTime)} -{" "}
                      {formatTime(slot.slot2EndTime)}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 text-end">
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveTimeSlot(index)}
                  >
                    <ClearOutlined />
                  </IconButton>
                </div>
                <div className="container">
                  <hr />
                </div>
              </>
            ))}
          </div>
          <div>
            <div className="row">
              <div className="col-lg-2">
                {" "}
                <select
                  label="Day"
                  select
                  value={dayOfWeek}
                  onChange={handleDayChange}
                  className="form-select form-control"
                  disabled={allDayOpen}
                >
                  <option value="SUNDAY">Sunday</option>
                  <option value="MONDAY">Monday</option>
                  <option value="TUESDAY">Tuesday</option>
                  <option value="WEDNESDAY">Wednesday</option>
                  <option value="THURSDAY">Thursday</option>
                  <option value="FRIDAY">Friday</option>
                  <option value="SATURDAY">Saturday</option>
                </select>
              </div>
              <div className="col-lg-2">
                <select
                  label="Open Time"
                  className="form-select form-control"
                  value={slot1StartTime}
                  onChange={handleOpenTimeChange}
                  sx={{ marginBottom: 2 }}
                  disabled={allDayOpen}
                >
                  {Array.from({ length: 23 }, (_, index) => index + 1).map(
                    (hour) => (
                      <option
                        key={hour}
                        value={`${hour.toString().padStart(2, "0")}:00`}
                      >
                        {`${hour.toString().padStart(2, "0")}:00`}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="col-lg-2">
                {" "}
                <select
                  label="Close Time"
                  className="form-select form-control"
                  value={slot1EndTime}
                  onChange={handleCloseTimeChange}
                  sx={{ marginBottom: 2 }}
                  disabled={allDayOpen}
                >
                  {Array.from({ length: 23 }, (_, index) => index + 1).map(
                    (hour) => (
                      <option
                        key={hour}
                        value={`${hour.toString().padStart(2, "0")}:00`}
                      >
                        {`${hour.toString().padStart(2, "0")}:00`}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className="col-lg-6 d-flex align-items-center">
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="end"
                      control={<Checkbox onChange={handle24By7Check} />}
                      label="24 Hours"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="end"
                      disabled={allDayOpen}
                      control={
                        <Checkbox
                          checked={addSecondSlot}
                          onChange={(event) =>
                            setAddSecondSlot(event.target.checked)
                          }
                        />
                      }
                      label="Add 2nd Time Slot"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
                <i
                  className="icon-plus-squared"
                  onClick={handleAddTimeSlot}
                  style={{
                    fontSize: "1.2rem",
                    color: "#fff",
                    background: "#3B71CA",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <div className="col-lg-12 mt-3">
                <div className="row">
                  {addSecondSlot && (
                    <div className="col-lg-2">
                      <select
                        label="2nd Slot Open Time"
                        className="form-select form-control"
                        value={slot2StartTime}
                        onChange={(event) =>
                          setSlot2StartTime(event.target.value)
                        }
                        sx={{ marginBottom: 2 }}
                        disabled={allDayOpen}
                      >
                        {Array.from(
                          { length: 23 },
                          (_, index) => index + 1
                        ).map((hour) => (
                          <option
                            key={hour}
                            value={`${hour.toString().padStart(2, "0")}:00`}
                          >
                            {`${hour.toString().padStart(2, "0")}:00`}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {addSecondSlot && (
                    <div className="col-lg-2">
                      <select
                        label="2nd Slot Close Time"
                        className="form-select form-control"
                        value={slot2EndTime}
                        onChange={(event) =>
                          setSlot2EndTime(event.target.value)
                        }
                        sx={{ marginBottom: 2 }}
                        disabled={allDayOpen}
                      >
                        {Array.from(
                          { length: 23 },
                          (_, index) => index + 1
                        ).map((hour) => (
                          <option
                            key={hour}
                            value={`${hour.toString().padStart(2, "0")}:00`}
                          >
                            {`${hour.toString().padStart(2, "0")}:00`}
                          </option>
                        ))}
                        {/* Dropdown options for the second slot close time */}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </CardWrapper>
  );
};

export default BusinessHours;
