import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const responsive = {
  0: {
    items: 1,
  },
  600: {
    items: 1,
  },
  767: {
    items: 1,
  },
  1000: {
    items: 1,
  },
  1400: {
    items: 1,
  },
};

const ListingCarousal = ({ listingDetails, startPosition }) => {
  const [listingMedias, setListingMedias] = useState(
    listingDetails?.listingMedias
  );

  useEffect(() => {
    setListingMedias(listingDetails?.listingMedias);
  }, [listingDetails]);

  return (
    <OwlCarousel
      id="carousel_in"
      className="owl-carousel owl-theme add_bottom_30"
      loop
      margin={50}
      nav={true}
      items={1}
      center
      responsive={responsive}
      dots={true}
      startPosition={startPosition || 0}
      navText={[
        "<svg className='nav-btn  prev-slide ' width='24' height='24'><path d='M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z' /></svg>",
        "<svg className='nav-btn next-slide' width='24' height='24'><path d='M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z' /></svg>",
      ]}
    >
      {listingMedias?.length ? (
        listingMedias?.map((img, index) => (
          <div className="item" key={index}>
            <img src={img?.mediaUrl} alt="" />
          </div>
        ))
      ) : (
        <>
          <div className="item">
            <img src="assets/img/carousel_detail_2.jpg" alt="" />
          </div>
          <div className="item">
            <img src="assets/img/carousel_detail_3.jpg" alt="" />
          </div>
        </>
      )}
    </OwlCarousel>
  );
};

export default ListingCarousal;
