import React from "react";

const StoryCard = () => {
  return (
    <div className="bg_color_1">
      <div className="container margin_80_55">
        <div className="main_title_2">
          <span>
            <em></em>
          </span>
          <h2 className="wow zoomIn">Explore 100xHelper</h2>
          <p className="wow zoomIn">Your Trusted Partner</p>
        </div>
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-12 wow bounceInUp">
            <p>
              At 100xHelper, we're committed to making your life easier by connecting you with reliable, verified helpers for everyday tasks. Whether you need assistance with a one-time project or ongoing support, our platform offers a reliable way to find the right help quickly and affordably.
            </p>
            <b>Our Mission</b>
            <p>
              Our mission is to simplify your everyday life by providing an easy-to-use platform where you can find reliable, affordable help for any task. We aim to build a community where trust and quality are paramount, ensuring that every interaction on 100xHelper is a positive experience.
            </p>
            <b>Our Vision</b>
            <p>
              We envision a world where finding help is straightforward, accessible, and empowering for all. By fostering a network of verified helpers and offering a diverse range of services, we strive to make 100xHelper the go-to solution for individuals and families looking to simplify their lives.
            </p>
            <b>Why 100xHelper?</b>
            <p>
              We envision a world where finding help is straightforward, accessible, and empowering for all. By fostering a network of verified helpers and offering a diverse range of services, we strive to make 100xHelper the go-to solution for individuals and families looking to simplify their lives.
            </p>
            <p>
              ● <b>Cost-Effective Solutions :</b> Our platform enables you to set your budget and find helpers willing to work at rates that suit you. This flexibility ensures that you can access quality services without overspending.
            </p>
            <p>
              ● <b>Personalized Matches :</b> Choose helpers that suit your specific needs and communicate directly to ensure quality service.
            </p>
            <p>
              ● <b>Verified Helpers :</b> We prioritize safety and reliability with comprehensive ID checks.
            </p>
            <p>
              ● <b>Diverse Services :</b> From household chores to specialized tasks, find the right help easily.
            </p>
            <b>Join Us</b>
            <p>
              Become part of our community and discover how 100xHelper can streamline your daily life, connecting you with skilled helpers ready to assist, all at rates that fit your budget.
            </p>
          </div>
        </div>
        {/* <!--/row--> */}
      </div>
      {/* <!--/container--> */}
    </div>
  );
};

export default StoryCard;
