import { Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';

import { getTimeWiseGreeting } from "../../../../shared/Constants/constants";
import { RightSideArrow } from "../../../../shared/Constants/icons";

import "./index.css";
import "swiper/css";
// import 'swiper/swiper-bundle.min.css';
import 'swiper/css/navigation';  // Navigation styles
// import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

// SwiperCore.use([Autoplay, Pagination, Navigation]);

const PostTaskSearch = () => {
  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );

  const [inputValue, setInputValue] = useState("");
  const [formError, setFormError] = useState({
    taskTitle: "",
  });

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (inputValue.trim() === "") {
      toast.error("Please enter a few words.");
      setFormError({
        taskTitle: "Please enter a few words.",
      });
      return;
    } else if (inputValue.length < 8) {
      toast.error("Minimum 8 characters required");
      setFormError({
        taskTitle: "Minimum 8 characters required",
      });
      return;
    } else {
      navigate(`/add-task?title=${encodeURIComponent(inputValue)}`);
    }
  };

  return (
    <section className="hero_single version_5">
      <div className="wrapper">
        <div className="container">
          <div className="d-flex post-container align-items-center">
            <div className="d-flex justify-content-start align-items-start flex-column post-main">
              <Typography className="wow m--0" data-wow-delay="0.5s">
                {getTimeWiseGreeting()}
                {profileInfo ? `, ${profileInfo.fullname}` : ""}
              </Typography>
              <h3 className="wow mt-3" data-wow-delay="0.5s">
                Post a task. Get it done.
              </h3>
              <form
                className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 mt-3 wow position-relative"
                data-wow-delay="0.7s"
                onSubmit={handleSubmit}
              >
                <input
                  type="text"
                  name="taskTitle"
                  className={`full-width search-post-input  ${
                    !!formError.taskTitle ? "error-border" : ""
                  }`}
                  placeholder="In a few words, What do you need done?"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    setFormError({
                      taskTitle: "",
                    });
                  }}
                />

                <button type="submit" className="offer-button">
                  Get Offers <RightSideArrow />
                </button>
              </form>
            </div>
            <div className="swiper-main">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                // centeredSlides={true}     // Center active slide
                className="swiper-container"
                loop={true}
                // speed={500} // Set the sliding speed (in milliseconds)
                // effect={"slide"} // Use the slide effect for smooth transitions
                autoplay={{
                  delay: 2000,
                  waitForTransition: true
                }}
                modules={[Autoplay]}

                speed={2000}
                >
                <SwiperSlide>
                  <img className="user-image" src="assets/images/cleaning.png" alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="user-image" src="assets/images/handyman.png" alt="Slide 2" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="user-image" src="assets/images/laundry.png" alt="Slide 3" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PostTaskSearch;
