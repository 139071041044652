import { AfterAuthApi, ApiCall } from "../..";
import {
  GET_ALL_CHAT_USER,
  GET_MESSAGE,
  GET_SENDER_PROFILE,
  INITIATE_NEW_CHAT,
  SEND_MESSAGE,
} from "../../url";

export const getAllChatUsers = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_ALL_CHAT_USER, "get")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const initChat = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(INITIATE_NEW_CHAT, "post", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getChatById = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_ALL_CHAT_USER, "post", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const sendMessage = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(SEND_MESSAGE, "post", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMessageById = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(`${GET_MESSAGE}/${submitData.id}`, "get")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSenderProfileId = (id) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(`${GET_SENDER_PROFILE}/${id}`, "get")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
