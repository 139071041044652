import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as listingAction from "../../../../redux/Listing/action";
import MoreInfoInputIcon from "../../../../shared/InputFieldsWithLabels/MoreInfoInputIcon.js";
import CardWrapper from "./CardWrapper.js";

const ListingCategory = ({
  setCategory,
  category,
  listingDetail,
  setGetInputObject,
  setFormFieldValue,
  setChosenValue,
  loading,
  setCategoryChanged,
}) => {
  const params = useParams();
  const dispatch = useDispatch();

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    const selectedObject = JSON.parse(selectedValue);

    setGetInputObject(selectedObject);
    setCategory(selectedObject.id);
    setFormFieldValue([]);
    setChosenValue([]);

    dispatch(listingAction.setRemoveEditListing());
    if (params.id && selectedValue) {
      setCategoryChanged(true);
      setCategory(selectedObject.id);
      setGetInputObject(selectedObject);
      setFormFieldValue([]);
      setChosenValue([]);

      dispatch(listingAction.setRemoveEditListing());
    }
  };

  const editSelectCategory = useMemo(
    () => listingDetail.filter((item) => item.id === category),
    [category, listingDetail]
  );

  return (
    <CardWrapper>
      <form className="was-validated">
        <label className="fw-bold" htmlFor="Category">
          Category *
        </label>
        <MoreInfoInputIcon label={"Category *"} />
        <hr />
        <div className="row">
          {loading ? (
            <div className="col-12">
              <i className="icon-spin5 animate-spin"></i>
            </div>
          ) : (
            <div className="col-12">
              <select
                id="Category"
                className="form-select form-control"
                value={
                  editSelectCategory.length
                    ? JSON.stringify(editSelectCategory[0])
                    : ""
                }
                aria-label="Default select example"
                onChange={handleOptionChange}
                required
                disabled={params.id ? true : false}
              >
                <option disabled value="">
                  Choose Your Category
                </option>
                {listingDetail?.length ? (
                  listingDetail?.map((obj, index) => (
                    <option value={JSON.stringify(obj)} key={index}>
                      {obj.categoryName}
                    </option>
                  ))
                ) : (
                  <option value="">No Category Available</option>
                )}
              </select>
            </div>
          )}
        </div>
      </form>
    </CardWrapper>
  );
};

export default ListingCategory;
