import React from "react";
import InputFields from "../../../../shared/InputFieldsWithLabels";

const OrganizerSelect = ({ setOrganizer }) => {
  return (
    <div className="container margin_30">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-8 col-md-8">
          <div className="box_account">
            <small className="float-end pt-5">* Required Fields</small>
            <div className="form_container">
              <h3 className="fw-bold">Create New Event</h3>
              <hr />
              <label className="fw-bold mt-2 me-2">
                Choose An Event Organizer's Listing
              </label>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                onChange={(e) => setOrganizer(e.target.value)}
              >
                <option selected>Select Listing</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            {/* <!-- /form_container --> */}
          </div>
          {/* <!-- /box_account --> */}
        </div>
      </div>
      {/* <!-- /row --> */}
    </div>
  );
};

export default OrganizerSelect;
