import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FLEXIBLE_DATE, TASK_DUE_DATE } from "../../../Constants/constants";
import { useSelector } from "react-redux";

const FirstStep = ({ handleChange, values, setIsFormValid /* errors */ }) => {
  const getAllCategories = useSelector(
    (state) => state?.categoryReducer?.categories
  );
  const [error, setError] = useState("");

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const isTaskDueDateValid =
      values.taskDueDate === "" || values.taskDueDate >= today;

    const isValid =
      values.categoryId !== "" &&
      values.title.trim() !== "" &&
      values.title.trim().length > 8 &&
      values.taskDueDateType !== "" &&
      ((values.taskDueDateType === "BEFORE_DATE" &&
        values.taskDueDate !== "" &&
        isTaskDueDateValid) ||
        (values.taskDueDateType === "ON_DATE" &&
          values.taskDueDate !== "" &&
          isTaskDueDateValid) ||
        values.taskDueDateType === "FLEXIBLE" ||
        values.taskDueDateType === "AS_SOON_AS_POSSIBLE");
    setIsFormValid((prevState) => ({
      ...prevState,
      stepOne: isValid,
    }));
  }, [values, setIsFormValid]);

  // useEffect(() => {
  //   const valueUpdated = () => {
  //     handleChange({
  //       target: {
  //         name: "preferredTimeOfDay",
  //         value: [],
  //       },
  //     });
  //   };
  //   if (!values.isFlexible) {
  //     valueUpdated();
  //   }
  // }, [handleChange, values.isFlexible]);

  const handleFlexibleItem = (value) => {
    const tempValue = [...values.preferredTimeOfDay];
    const index = values.preferredTimeOfDay.findIndex((i) => i === value);
    if (index > -1) {
      tempValue.splice(index, 1);
    } else {
      tempValue.push(value);
    }

    handleChange({
      target: {
        name: "preferredTimeOfDay",
        value: tempValue,
      },
    });
  };
  return (
    <Box>
      <Box className="mt--20">
        <FormControl fullWidth>
          <TextField
            fullWidth
            size="small"
            label="Title*"
            name="title"
            placeholder="Write a title for your task"
            value={values.title}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            inputProps={{ maxLength: 155 }}
            InputLabelProps={{
              sx: {
                fontFamily: "Poppins",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                  top: "-5px",
                },
              },
            }}
            InputProps={{
              sx: {
                input: {
                  width: "100%",
                  fontFamily: "Poppins",
                },
                "@media (max-width: 600px)": {
                  input: {
                    fontSize: "14px",
                    padding: "10px",
                  },
                },
              },
            }}
          />
          <FormHelperText
            className="m--0"
            sx={{
              fontFamily: "Poppins",
            }}
          >
            Note: Title should be 8 characters or more
          </FormHelperText>
        </FormControl>
      </Box>
      <Box className="mt--20">
        <div className="form-group">
          <select
            name="categoryId"
            value={values.categoryId}
            className="form-select form-control d-block"
            onChange={handleChange}
          >
            <option disabled value="">
              Select Category
            </option>
            {getAllCategories?.map((val, index) => (
              <option value={val.id} key={index}>
                {val.categoryName}
              </option>
            ))}
          </select>
        </div>
      </Box>
      <Box className="mt--20">
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className="fw-bold"
          sx={{
            fontFamily: "Poppins",
          }}
        >
          When do you need this done? *
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="taskDueDateType"
          value={values.taskDueDateType}
          onChange={(e) =>
            handleChange(e, {
              taskDueDate: "",
            })
          }
        >
          {TASK_DUE_DATE.map((item) => (
            <FormControlLabel
              key={item.key}
              value={item.value}
              control={
                <Radio
                  sx={{
                    fontFamily: "Poppins",
                    "&.Mui-checked": {
                      color: "#283350", // Replace 'customColor' with your desired color
                    },
                  }}
                />
              }
              label={item.key}
              sx={{
                "&.MuiFormControlLabel-root": {
                  marginRight: "10px",
                  fontFamily: "Poppins",
                },
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  fontFamily: "Poppins",
                },
              }}
            />
          ))}
        </RadioGroup>
        <Box>
          {["ON_DATE", "BEFORE_DATE"].includes(values.taskDueDateType) && (
            <FormControl>
              <TextField
                label="Task due date*"
                name="taskDueDate"
                type="date"
                error={!!error}
                helperText={error}
                value={values.taskDueDate}
                onChange={(e) => {
                  const today = new Date().toISOString().split("T")[0];
                  const { name, value } = e.target;
                  if (name === "taskDueDate" && value < today) {
                    setError("Due date cannot be in the past");
                  } else {
                    setError("");
                  }
                  handleChange(e);
                }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  fontFamily: "Poppins",
                  sx: {
                    "@media (max-width: 600px)": {
                      fontSize: "1rem",
                    },
                  },
                }}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                  sx: {
                    fontFamily: "Poppins",
                    textAlign: "left",
                    "@media (max-width: 600px)": {
                      input: {
                        fontSize: "14px",
                        padding: "10px",
                      },
                    },
                  },
                }}
              />
            </FormControl>
          )}
        </Box>
        <Box>
          {values.taskDueDateType !== "" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="isFlexible"
                  checked={values.isFlexible}
                  onChange={handleChange}
                  sx={{
                    "&.Mui-checked": {
                      color: "#283350", // Replace 'customColor' with your desired color
                    },
                  }}
                />
              }
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontFamily: "Poppins", // Apply the Poppins font family
                },
              }}
              label="I need a certain time of day"
            />
          )}
        </Box>
        <Box>
          {values.isFlexible && (
            <Box
              className="d-flex mt--10"
              gap={{
                xs: "10px",
                sm: "10px",
                md: "20px",
                lg: "20px",
              }}
            >
              {FLEXIBLE_DATE.map((item) => {
                return (
                  <Box
                    key={item.label}
                    onClick={() => {
                      handleFlexibleItem(item.value);
                    }}
                    className={`cursor-pointer ${
                      values.preferredTimeOfDay.includes(item.value)
                        ? "active-flexible"
                        : "tasker-items"
                    } flexible-wrapper`}
                  >
                    {item.icon}
                    <Typography
                      className="item-title"
                      sx={{
                        fontFamily: "Poppins",
                      }}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                      }}
                    >
                      {item.subText}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default FirstStep;
