import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import {
  Avatar,
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { listingMediaUpload } from "../../../../helper/APIs/USER/Listing";
import { LANGUAGES_MENU, SKILLS_MENU } from "../../../Constants/constants";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { submitTakserForm } from "../../../../helper/APIs/USER/BecomeTasker";
import MoreInfoInputIcon from "../../../InputFieldsWithLabels/MoreInfoInputIcon";
import { BECOME_TASKER_STATUS } from "../../../Constants/enum";
import { LOGIN_USER_SUCCESSFUL } from "../../../../redux/Auth/type";

const BecomeTasker = ({ isOpen, handleClose }) => {
  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );

  const loginData = useSelector((state) => state.loginReducer.user);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    mediaUrl: "",
    aboutMe: "",
    address: "",
    skills: [],
    languages: [],
    phone: null,
    dateOfBirth: "",
    gender: "",
    nationality: "",
  });
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleRemoveImage = (val) => {
    setFormData((prevData) => ({ ...prevData, mediaUrl: "" }));
  };

  const handleFileDrop = (acceptedFiles) => {
    let allFilesWithinLimit = true;
    setLoading(true);

    // Filter out files that exceed the size limit
    const newFiles = acceptedFiles.filter((file) => {
      if (file.size > 5 * 1024 * 1024) {
        toast.error(`${file.name} size exceeds the 5MB limit`);
        allFilesWithinLimit = false;
        return false;
      }
      return true;
    });

    if (allFilesWithinLimit) {
      const uploadPromises = newFiles.map((file) => {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        return listingMediaUpload(fileFormData)
          .then((response) => ({
            id: "",
            listingId: "",
            mediaUrl: response.data.mediaUrl,
            isFeaturedImage: false,
          }))
          .catch((error) => {
            console.error("Error uploading file:", file.name, error);
            toast.error(`Error uploading ${file.name}`);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((responses) => {
          // Filter out null values which represent skipped files
          const validResponses = responses.filter(
            (response) => response !== null
          );

          setFormData((prevData) => ({
            ...prevData,
            mediaUrl: validResponses[0].mediaUrl,
          }));

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error uploading files", err);
        });
    } else {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const calculateAge = (birthday) => {
    const today = new Date();
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleFormValidation = () => {
    let errors = {};
    const {
      mediaUrl,
      aboutMe,
      address,
      skills,
      languages,
      phone,
      dateOfBirth,
      gender,
      nationality,
    } = formData;
    if (!mediaUrl.trim()) {
      errors.mediaUrl = "Medial url is required";
    }
    if (!aboutMe.trim()) {
      errors.aboutMe = "About me is required";
    } else if (aboutMe.trim().length < 15) {
      errors.aboutMe = "Minimum 15 characters required";
    }
    if (!address.trim()) {
      errors.address = "Address is required";
    } else if (address.trim().length < 15) {
      errors.address = "Minimum 15 characters required";
    }
    if (isEmpty(skills)) {
      errors.skills = "Skills is required";
    } else if (skills.length > 5) {
      errors.skills = "Select only 5 skills";
    }
    if (isEmpty(languages)) {
      errors.languages = "Languages is required";
    } else if (languages.length > 10) {
      errors.languages = "Select only 10 languages";
    }
    if (phone === null) {
      errors.phone = "Phone is required";
    } else if (phone.length < 8) {
      errors.phone = "Phone should be 8 digits";
    }
    const age = calculateAge(dateOfBirth);
    if (dateOfBirth === "") {
      errors.dateOfBirth = "Birth date is required";
    } else if (age < 16 || age > 100) {
      errors.dateOfBirth = "Age must be between 16 and 100 years";
    }

    if (gender === "") {
      errors.gender = "Gender is required";
    }

    if (!nationality.trim()) {
      errors.nationality = "Nationality is required";
    } else if (nationality.trim().length < 3) {
      errors.nationality = "Minimum 3 characters required";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!handleFormValidation()) {
      setIsSubmitted(true);

      await submitTakserForm(profileInfo.id, formData)
        .then((res) => {
          const loginUser = {
            ...loginData,
            data: {
              ...loginData.data,
              userProfile: {
                ...loginData.data?.userProfile,
                isUserActiveHelper: false,
                helperApplicationStatus: BECOME_TASKER_STATUS.PENDING,
              },
            },
          };
          setIsSubmitted(false);
          toast.success("Your request has been sent successfully");
          dispatch({
            type: LOGIN_USER_SUCCESSFUL,
            payload: { loginUser, userType: "user" },
          });
          handleClose();
        })
        .catch((err) => {
          setIsSubmitted(false);
          handleClose();
          toast.error(
            err?.response?.data?.error || err?.response?.data?.errors[0]
          );
        });
    }
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle
        id="alert-dialog-title"
        className="text--left fw-bold"
        sx={{ fontSize: { xs: "18px", md: "22px" } }}
      >
        {"Become a Helper"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="position-absolute"
        sx={{ right: 8, top: 10 }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit} className="p--20">
        <Box className="mt--10">
          <FormControl error={!!errors.mediaUrl} fullWidth>
            <Box className="full-width">
              <label className="fw-bold d-flex align-items--center">
                Profile Photo *
                <MoreInfoInputIcon
                  label={
                    "Only .jpg, .png files are accepted. Image should not be larger than 10mb."
                  }
                />
              </label>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {formData.mediaUrl !== "" && (
                  <Badge
                    color="primary"
                    sx={{
                      borderRadius: "50%",
                    }}
                    badgeContent={
                      <Box
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveImage(formData.mediaUrl)}
                      >
                        &#x2715;
                      </Box>
                    }
                  >
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        border: "1px solid black",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "8px",
                      }}
                      variant="square"
                      alt="Remy Sharp"
                      src={formData.mediaUrl}
                    />
                  </Badge>
                )}
              </Box>

              {formData.mediaUrl === "" && (
                <Dropzone onDrop={handleFileDrop} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      {loading ? (
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={loading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      ) : (
                        ""
                      )}
                      <div
                        {...getRootProps()}
                        className="stepper-media-upload"
                        style={{
                          border: !!errors.mediaUrl
                            ? " 2px solid #d32f2f"
                            : "2px solid #eeeeee",
                        }}
                      >
                        <input
                          {...getInputProps({
                            accept: "image/jpeg, image/png, image/gif",
                            multiple: false,
                          })}
                        />
                        <svg width="24" height="24" viewBox="0 0 24 24">
                          <path
                            d="M12 24C10.34 24 8.78 23.6848 7.32 23.0544C5.86 22.4248 4.59 21.57 3.51 20.49C2.43 19.41 1.5752 18.14 0.9456 16.68C0.3152 15.22 0 13.66 0 12C0 10.34 0.3152 8.78 0.9456 7.32C1.5752 5.86 2.43 4.59 3.51 3.51C4.59 2.43 5.86 1.5748 7.32 0.9444C8.78 0.3148 10.34 0 12 0C13.66 0 15.22 0.3148 16.68 0.9444C18.14 1.5748 19.41 2.43 20.49 3.51C21.57 4.59 22.4248 5.86 23.0544 7.32C23.6848 8.78 24 10.34 24 12C24 13.66 23.6848 15.22 23.0544 16.68C22.4248 18.14 21.57 19.41 20.49 20.49C19.41 21.57 18.14 22.4248 16.68 23.0544C15.22 23.6848 13.66 24 12 24ZM12 21.6C14.68 21.6 16.95 20.67 18.81 18.81C20.67 16.95 21.6 14.68 21.6 12C21.6 9.32 20.67 7.05 18.81 5.19C16.95 3.33 14.68 2.4 12 2.4C9.32 2.4 7.05 3.33 5.19 5.19C3.33 7.05 2.4 9.32 2.4 12C2.4 14.68 3.33 16.95 5.19 18.81C7.05 20.67 9.32 21.6 12 21.6Z"
                            fill="#283350"
                          ></path>
                          <path
                            d="M13.2 18H10.8V13.2H6V10.8H10.8V6H13.2V10.8H18V13.2H13.2V18Z"
                            fill="#283350"
                          ></path>
                        </svg>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </Box>
            <FormHelperText>{errors.mediaUrl}</FormHelperText>
          </FormControl>
        </Box>
        <Box
          className="mt--10 d-flex align-items-center"
          sx={{
            gap: { xs: "10px", sm: "10px", md: "20px" },
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <TextField
            fullWidth
            label="About me *"
            name="aboutMe"
            size="small"
            value={formData.aboutMe}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            multiline
            rows={2}
            error={!!errors.aboutMe}
            helperText={errors.aboutMe}
            inputProps={{ maxLength: 2000 }}
            placeholder="Describe your self"
            sx={{
              mb: { xs: 0, sm: 0, md: 2 },
              "& .MuiInputLabel-root": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiOutlinedInput-input": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiFormLabel-root": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiInputBase-root": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiInputBase-input::placeholder": {
                "@media (max-width: 600px)": {
                  fontSize: "0.9rem",
                },
              },
            }}
          />

          <TextField
            fullWidth
            label="Address *"
            name="address"
            size="small"
            value={formData.address}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            multiline
            rows={2}
            error={!!errors.address}
            helperText={errors.address}
            inputProps={{ maxLength: 1000 }}
            placeholder="Please provide your full address with postal code"
            sx={{
              mb: { xs: 0, sm: 0, md: 2 },
              mt: { xs: 0, sm: 0, md: 2 },
              "& .MuiInputLabel-root": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiOutlinedInput-input": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiFormLabel-root": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiInputBase-root": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiInputBase-input::placeholder": {
                "@media (max-width: 600px)": {
                  fontSize: "0.9rem",
                },
              },
            }}
          />
        </Box>
        <Box
          className="mt--10 d-flex align-items-center"
          sx={{
            gap: { xs: "10px", sm: "10px", md: "15px" },
            flexDirection: { xs: "column", sm: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          <Box
            className="mt--10  d-flex flex-column"
            sx={{ width: { xs: "100%", sm: "100%", md: "270px" } }}
          >
            <FormControl
              error={!!errors.skills}
              sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}
            >
              <InputLabel id="demo-multiple-name-label-skills">
                Skills *
              </InputLabel>
              <Select
                size="medium"
                id="demo-multiple-name"
                name="skills"
                labelId="demo-multiple-name-label-skills"
                multiple
                fullWidth
                input={<OutlinedInput label="Skills *" />}
                value={formData.skills}
                error={!!errors.skills}
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {SKILLS_MENU.map((items) => (
                  <MenuItem key={items.key} value={items.value}>
                    {items.key}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.skills}</FormHelperText>
            </FormControl>
          </Box>
          <Box
            className="mt--10  d-flex flex-column"
            sx={{ width: { xs: "100%", sm: "100%", md: "270px" } }}
          >
            <FormControl
              error={!!errors.languages}
              sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}
            >
              <InputLabel id="demo-multiple-name-label">Languages *</InputLabel>
              <Select
                size="medium"
                id="demo-multiple-name"
                name="languages"
                labelId="demo-multiple-name-label"
                multiple
                fullWidth
                input={<OutlinedInput label="Languages *" />}
                value={formData.languages}
                onChange={handleChange}
                error={!!errors.languages}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {LANGUAGES_MENU.map((items) => (
                  <MenuItem key={items.key} value={items.value}>
                    {items.key}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.languages}</FormHelperText>
            </FormControl>
          </Box>
        </Box>
        <Box
          className="mt--10 d-flex align-items-center"
          sx={{
            gap: { xs: "10px", sm: "10px", md: "20px" },
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <TextField
            fullWidth
            label="Phone *"
            name="phone"
            size="medium"
            type="number" // Use "text" to prevent numeric keyboard arrows
            value={formData.phone}
            onChange={(e) => {
              if (e.target.value.length <= 15) {
                handleChange(e);
              }
            }}
            margin="normal"
            variant="outlined"
            error={!!errors.phone}
            helperText={errors.phone}
            sx={{
              mb: { xs: 0, sm: 0, md: 2 },
            }}
            inputProps={{
              min: 0,
              maxLength: 15, // Set maximum length to 15
              inputMode: "numeric", // Use numeric mode for mobile keyboards
              pattern: "\\d*", // Ensure only digits can be entered
              style: {
                // Hide the arrows for number input in Webkit browsers
                WebkitAppearance: "none",
                MozAppearance: "textfield",
                appearance: "textfield",
              },
            }}
            InputLabelProps={{
              sx: {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                  top: "-5px",
                },
              },
            }}
            InputProps={{
              sx: {
                minHeight: "62px",

                "& input": {
                  "@media (max-width: 600px)": {
                    fontSize: "14px",
                    padding: "10px",
                  },
                  // Hide the arrows for number input in Webkit browsers
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  // Hide the arrows for number input in Firefox
                  "&[type=number]": {
                    MozAppearance: "textfield",
                  },
                },
              },
            }}
          />

          <TextField
            fullWidth
            label="Birth Date*"
            name="dateOfBirth"
            type="date"
            value={formData.dateOfBirth}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            sx={{
              mb: { xs: 0, sm: 0, md: 2 },
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
            }}
            error={!!errors.dateOfBirth}
            helperText={errors.dateOfBirth}
            inputProps={{
              sx: {
                textAlign: "left",
                "@media (max-width: 600px)": {
                  input: {
                    fontSize: "14px",
                    padding: "10px",
                  },
                },
              },
            }}
          />
        </Box>
        <Box
          className="mt--10 d-flex align-items-center"
          sx={{
            gap: { xs: "10px", sm: "10px", md: "20px" },
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <FormControl
            sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}
            error={!!errors.gender}
          >
            <FormLabel id="demo-row-radio-buttons-group-label">
              Gender *
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gender"
              error={!!errors.gender}
              value={formData.gender}
              onChange={handleChange}
            >
              {["MALE", "FEMALE", "OTHERS"].map((item) => (
                <FormControlLabel
                  key={item}
                  value={item}
                  control={<Radio />}
                  label={item}
                  sx={{
                    "&.MuiFormControlLabel-root": {
                      marginRight: "10px",
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "12px", // Set desired font size here
                    },
                  }}
                />
              ))}
            </RadioGroup>
            <FormHelperText>{errors.gender}</FormHelperText>
          </FormControl>
          <Box sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}>
            <TextField
              fullWidth
              label="Nationality *"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              margin="normal"
              size="medium"
              variant="outlined"
              error={!!errors.nationality}
              helperText={errors.nationality}
              inputProps={{ maxLength: 120 }}
              sx={{
                mb: { xs: 0, sm: 0, md: 2 },
              }}
              InputLabelProps={{
                sx: {
                  "@media (max-width: 600px)": {
                    fontSize: "1rem",
                    top: "-5px",
                  },
                },
              }}
              InputProps={{
                sx: {
                  minHeight: "62px",
                  "@media (max-width: 600px)": {
                    input: {
                      fontSize: "14px",
                      padding: "10px",
                    },
                  },
                },
              }}
            />
          </Box>
        </Box>
        <Box className="mt--10">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: "#283350",
              "&:hover": {
                backgroundColor: "#ffc107",
                color: "#283350",
              },
            }}
            disable={isSubmitted}
            className={isSubmitted ? "opacity--50" : ""}
          >
            {isSubmitted && (
              <span
                className="spinner-border spinner-border-sm mx-3"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Submit
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};
export default BecomeTasker;
