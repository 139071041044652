import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as categoryAction from "../../../redux/ListingCategory/action";
import HowItWorks from "./HowItWorks";
import PostTaskSearch from "./PostTaskSearch";
import UpdatedHowIsWorks from "./HowItWorks/updatedHowIsWork";
import FAQSection from "./FAQ";
const HomeComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(categoryAction.getCategory());
  }, [dispatch]);

  return (
    <>
      <PostTaskSearch />
      {/* <HowItWorks /> */}
      <UpdatedHowIsWorks />
      <FAQSection />
    </>
  );
};

export default HomeComponent;
