import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import { Avatar } from "@mui/material";
import { Close } from "@mui/icons-material";

const MediaPreview = ({ medias, setEditMediaImages }) => {
  const handleRemoveImage = (val) => {
    const updatedImages = medias.filter((item) => item.id !== val.id);
    setEditMediaImages(updatedImages);
  };

  return (
    <>
      {medias.map((val, index) => (
        <Badge
          color="primary"
          sx={{
            margin: "1rem",
            borderRadius: "50%",
          }}
          badgeContent={
            <Close
              sx={{ padding: "0.2rem", cursor: "pointer" }}
              onClick={() => handleRemoveImage(val)}
            />
          }
          key={index}
        >
          <Avatar
            sx={{
              width: 80,
              height: 80,
              border: "1px solid black",
              display: "flex",
              justifyContent: "center",
            }}
            variant="square"
            alt="Remy Sharp"
            src={val.mediaUrl}
          />
        </Badge>
      ))}
    </>
  );
};

export default MediaPreview;
