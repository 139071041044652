import { ApiCall } from "../../helper";
import {
  LOGIN_USER_SUCCESSFUL,
  LOGIN_PROCESSING,
  STOP_LOGIN_PROCESSING,
  LOGOUT_SUCCESSFUL,
  SET_ACCESS_TOKEN,
  REGISTER_PROCESSING,
  REGISTER_USER_SUCCESSFUL,
  STOP_REGISTER_PROCESSING,
  TEMP_REGISTER_DATA,
  TEMP_ACCESS_TOKEN,
} from "./type";
import { LOGIN_USER, REGISTER_USER } from "../../helper/url";

export const setLoginUser = (loginUser, userType) => {
  return {
    type: LOGIN_USER_SUCCESSFUL,
    payload: { loginUser, userType },
  };
};
export const setRegisterUser = (registerUser) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: registerUser,
  };
};
export const setAccessToken = (payload) => {
  return {
    type: SET_ACCESS_TOKEN,
    payload,
  };
};
export const setVerifiedRegisteredUsers = (payload) => {
  return {
    type: TEMP_REGISTER_DATA,
    payload,
  };
};
export const setRegisteredUsersAccessToken = (payload) => {
  return {
    type: TEMP_ACCESS_TOKEN,
    payload,
  };
};

export const loginUser = (payload, userType) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: LOGIN_PROCESSING });
    ApiCall(LOGIN_USER, "post", payload)
      .then((loginUser) => {
        setAccessToken(loginUser.data.jwtToken);
        dispatch({
          type: LOGIN_USER_SUCCESSFUL,
          payload: { loginUser, userType },
        });
        resolve(loginUser);
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: STOP_LOGIN_PROCESSING });
      });
  });
};

export const registerUser = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: REGISTER_PROCESSING });
    ApiCall(REGISTER_USER, "post", payload)
      .then((registerUser) => {
        // setAccessToken(registerUser.data.jwtToken);
        dispatch({
          type: TEMP_REGISTER_DATA,
          payload: registerUser,
        });
        resolve(registerUser);
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: STOP_REGISTER_PROCESSING });
      });
  });
};

export const logout = (payload) => {
  return {
    type: LOGOUT_SUCCESSFUL,
    payload,
  };
};
