import React, { useEffect, useState } from "react";
import CardWrapper from "./CardWrapper.js";
import MoreInfoInputIcon from "../../../../shared/InputFieldsWithLabels/MoreInfoInputIcon.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor.js";
import { connect } from "react-redux";

const MoreInfo = ({ editorState, setEditorState, isEdit }) => {
  const [state, setState] = useState(editorState);

  useEffect(() => {
    setState(editorState);
  }, [editorState]);

  return (
    <div className="App">
      <CardWrapper>
        <label className="fw-bold">More Info </label>

        <MoreInfoInputIcon label={"More Info"} />
        <hr />
        <div className="row">
          <div className="col-12 col-sm-12 col-md-7 col-lg-7">
            <div>
              <CKEditor
                editor={Editor}
                data={state.length ? state : ""}
                onReady={(editor) => {}}
                onBlur={(event, editor) => {
                  const data = editor.getData();
                  setEditorState(data);
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-5 col-lg-5">
            <p>
              <b>Tip: </b>
              Write an appealing ad description to grab the attention of those
              interested. A carefully written description can make a world of
              difference!
            </p>
          </div>
        </div>
      </CardWrapper>
    </div>
  );
};

const mapStateToProp = (state) => {
  return {
    isEdit: state?.listingReducer?.isEdit,
  };
};

export default connect(mapStateToProp)(MoreInfo);
