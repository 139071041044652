import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { gapi } from "gapi-script";
import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GOOGLE_CLIENT_ID } from "../../../config";
import { googleAuth, verifyEmailOtp } from "../../../helper/APIs/Auth";
import * as loginAction from "../../../redux/Auth/action";
import * as registerAction from "../../../redux/Auth/action";
import InputFields from "../../../shared/InputFieldsWithLabels";
import PasswordFields from "../../../shared/InputFieldsWithLabels/PasswordFields";
import LoginPopUp from "./LoginPopUp";

const RegisterPopUp = ({ open, handleClose, closeLogin }) => {
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const registrationData = useSelector((state) => state.loginReducer.data);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [openLoginPopUp, setOpenLoginPopUp] = React.useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirm_password: "",
    fullName: "",
  });
  // const [remember, setRemember] = useState(true);
  const [error, setError] = useState("");
  const [otp, setOtp] = useState(null);
  const [step, setStep] = useState(0);
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const handleChange = (e) => {
    setError("");
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formValues.fullName) {
      setError("Please enter first name.");
      setLoading(false);
    } else if (!formValues.email) {
      setError("Please enter email.");
      setLoading(false);
    } else if (!formValues.password) {
      setError("Please enter password.");
      setLoading(false);
    } else if (!formValues.confirm_password) {
      setError("Please enter confirm password.");
      setLoading(false);
    } else if (formValues.password !== formValues.confirm_password) {
      setError("Password and confirm password don't match.");
      setLoading(false);
    } else {
      const submitData = {
        loginType: "EMAIL_PASS",
        loginInfoDto: {
          userProfile: {
            fullname: formValues.fullName,
            email: formValues.email,
            addressVisibility: "REGISTERED_USERS",
            phoneVisibility: "REGISTERED_USERS",
            emailVisibility: "REGISTERED_USERS",
            userConsents: [
              {
                consent: "GIVEN",
                consentRecordTimestamp: Math.floor(Date.now() / 1000),
              },
            ],
          },
          password: formValues.password,
        },
      };
      dispatch(registerAction.registerUser(submitData))
        .then((res) => {
          if (res.status === 200) {
            setStep(1);
            setIsLogin(false);
            toast.success("User registered successfully.");
            setLoading(false);
            setError("");
          }
        })
        .catch((err) => {
          setLoading(false);

          if (err.response.status === 403) {
            setError("Enter required parameters.");
          } else {
            setError(err?.response?.data.errors.toString());
          }
        });
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmitStepOne = (e) => {
    e.preventDefault();
    setLoading(true);
    let submitData = { email: formValues.email, otp };
    if (!otp) {
      toast.error("Please enter OTP");
      setLoading(false);
    } else {
      verifyEmailOtp(submitData)
        .then((res) => {
          if (res.success) {
            setLoading(false);
            if (isLogin) {
              const data = {
                email: formValues.email,
                pwd: formValues.password,
                isLogin,
              };

              dispatch(loginAction.loginUser(data, "user"))
                .then((res) => {
                  if (res.status === 200) {
                    navigate("/");
                    setLoading(false);
                    handleClose();
                    closeLogin();
                  }
                })
                .catch((err) => {
                  setLoading(false);

                  if (err?.response?.status === 403 && err?.response?.data) {
                    toast.error(err.response.data.errors.toString());
                  } else {
                    toast.error("Enter required parameters.");
                  }
                });
            } else {
              handleClose();
              closeLogin();
              setOpenLoginPopUp(false);
              dispatch(loginAction.setRegisterUser(registrationData));
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const responseGoogle = (response) => {
    if (response?.tokenObj?.id_token) {
      const submitData = {
        idToken: response.tokenObj.id_token,
      };
      googleAuth(submitData).then((res) => {
        if (res.success) {
          dispatch(loginAction.setRegisterUser(res, "user"));
          handleClose();
          toast.success("Login Successfully.");
        }
      });
    }
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>
          <div className="small-dialog-header d-flex justify-content-between">
            <h3>Register</h3>
            <CancelIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
                closeLogin();
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div id="sign-in-dialog" className="zoom-anim-dialog ">
            {step === 0 && (
              <form className="" onSubmit={handleSubmit}>
                <div className="sign-in-wrapper">
                  <div className="access_social">
                    <GoogleLogin
                      clientId={GOOGLE_CLIENT_ID}
                      icon={true}
                      theme="dark"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                      className="social_bt"
                    />
                  </div>
                  <div className="divider">
                    <span>Or</span>
                  </div>
                  <InputFields
                    label="Your Name"
                    type="text"
                    id="name"
                    iconClass="fa fa-user"
                    name="fullName"
                    onChange={handleChange}
                    placeholder="Enter Full Name"
                    required={true}
                    value={formValues.fullName}
                  />
                  <InputFields
                    label="Your Email"
                    type="email"
                    id="email"
                    iconClass="fa fa-envelope"
                    name="email"
                    onChange={handleChange}
                    placeholder="Enter Email"
                    required={true}
                    value={formValues.email}
                  />

                  <PasswordFields
                    label="Your Password"
                    type="password"
                    id="password1"
                    iconClass="fa fa-lock"
                    name="password"
                    onChange={handleChange}
                    placeholder="Enter Password"
                    required={false}
                    defaultValue={formValues.password}
                  />
                  <PasswordFields
                    label="Confirm Password"
                    type="password"
                    id="password2"
                    iconClass="fa fa-lock"
                    name="confirm_password"
                    onChange={handleChange}
                    placeholder="Enter Password Again"
                    required={false}
                    defaultValue={formValues.confirm_password}
                  />

                  <em>
                    By clicking Register Now!, you agree to our
                    <Link to="/terms"> Terms & Conditions</Link>. Learn how we
                    collect, use and share your data in our{" "}
                    <Link to="/privacy-policy">Privacy Policy</Link>. You will
                    receive transactional email notifications from us such as
                    OTP and contact inquiries for your ads.
                  </em>

                  <div
                    id="pass-info"
                    className="clearfix"
                    style={{ color: "red", fontSize: "1rem", fontWeight: 600 }}
                  >
                    {error}
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn_1 rounded full-width add_top_30"
                  >
                    Register Now!
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>

                  <div className="text-center add_top_10">
                    Already have an account?{" "}
                    <strong>
                      <span
                        className="auth_footer_link"
                        onClick={() => {
                          setOpenLoginPopUp(true);
                          handleClose();
                        }}
                      >
                        Sign In
                      </span>
                    </strong>
                  </div>
                </div>
                <div className="copy text-center add_top_10 text-muted">
                  Copyright © {new Date().getFullYear()}&nbsp; 100xHelper
                  <br /> All Rights Reserved
                </div>
              </form>
            )}
            {step === 1 && (
              <form className="was-validated" onSubmit={handleSubmitStepOne}>
                <div className="sign-in-wrapper">
                  <InputFields
                    label="OTP"
                    type="text"
                    id="otp"
                    name="otp"
                    iconClass="fa fa-keyboard-o"
                    onChange={handleOtpChange}
                    required={true}
                  />

                  <button
                    type="submit"
                    className="btn_1 rounded full-width"
                    disabled={loading}
                    onClick={handleSubmitStepOne}
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Submit
                  </button>
                </div>
                <div className="copy text-center add_top_10 text-muted">
                  Copyright © {new Date().getFullYear()}&nbsp; 100xHelper
                  <br /> All Rights Reserved
                </div>
              </form>
            )}
          </div>
        </DialogContent>
      </Dialog>
      {openLoginPopUp && (
        <LoginPopUp
          open={openLoginPopUp}
          handleClose={() => setOpenLoginPopUp(false)}
        />
      )}
    </div>
  );
};

export default RegisterPopUp;
