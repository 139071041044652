import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "react-rte";
import { toast } from "react-toastify";
import {
  addListing,
  editSingleListing,
  getEditListingData,
  getEditListingDataWithoutToken,
  getListingCategories,
} from "../../../../helper/APIs/USER/Listing";
import * as listingAction from "../../../../redux/Listing/action";
import PagesHeader from "../../../../shared/Navbar/PagesHeader";
import BusinessHours from "./BusinessHours";
import CardWrapper from "./CardWrapper.js";
import CountryMultiSelect from "./CountryMultiSelect";
import ListingCategory from "./ListingCategory";
import ListingFeatures from "./ListingFeatures";
import ListingType from "./ListingType";
import MediaUpload from "./MediaUpload";
import MoreInfo from "./MoreInfo";
import PrimaryDetails from "./PrimaryDetails";
import { isEmpty } from "lodash";

const Listing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isEdit = useSelector((state) => state?.listingReducer?.isEdit);
  const editListingData = useSelector(
    (state) => state?.listingReducer?.editListing
  );
  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);

  const [editData, setEditData] = useState(editListingData);
  useEffect(() => {
    setEditData(editListingData);
  }, [editListingData]);

  const [formValues, setFormValues] = useState({
    title: "",
    address1: null,
    city: "",
    phone: "",
    whatsapp: "",
    website: "",
  });

  const [editorState, setEditorState] = useState("");
  const [getInputObject, setGetInputObject] = React.useState();
  const [categoryChanges, setCategoryChanged] = React.useState(false);
  const [address, setAddress] = React.useState(null);
  const [localAddress, setLocalAddress] = React.useState({
    addressLine1: null,
    postalCode: null,
    latitude: null,
    longitude: null,
    locationPlaceId: null,
  });
  const [newAddress, setNewAddress] = React.useState(editData?.listingLocation);
  const [latLong, setLatLong] = React.useState({
    latitude: null,
    longitude: null,
  });

  const [formFieldValue, setFormFieldValue] = useState([]);
  // BUSINESS HOUrS
  const [timeSlots, setTimeSlots] = React.useState([]);
  // END BUSINESS HOURS
  const [listingType, setListingType] = useState("");
  const [chosenValue, setChosenValue] = useState([]);
  const [listingDetail, setListingDetail] = React.useState([]);
  const [category, setCategory] = React.useState("");

  const [faqs, setFaqs] = useState();

  const [files, setFiles] = useState([]);
  const [featureImages, setFeatureImages] = useState([]);
  const [editFeatureImages, setEditFeatureImages] = useState([]);
  const [mediaImages, setMediaImages] = useState([]);
  const [editMediaImages, setEditMediaImages] = useState([]);
  const [agree, setAgree] = useState(isEdit ? editData?.termsAccepted : false);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [tempAddress, setTempAddress] = useState("");
  const [tempStreetAddress, setTempStreetAddress] = useState("");
  const [warning, setWarning] = useState("");
  const [streetWarning, setStreetWarning] = useState("");
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    getListingCategories()
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setListingDetail(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    if (params.id) {
      if (isLoggedIn) {
        getEditListingData(params.id)
          .then((res) => {
            if (res.success) {
              dispatch(listingAction.setEditListing(res.data));
              setCategory(res.data.categoryId);
              setFormValues({
                title: res.data?.title,
                address1: res.data?.listingLocation?.addressLine2,
                city: "",
                phone: res.data?.phoneNumber,
                whatsapp: res.data?.phoneNumber,
                website: res.data?.externalUrl,
              });
              setListingType(res.data.listingType);
              let listingImage = res.data.listingMedias.filter(
                (item) => item.isFeaturedImage === false
              );
              let featureImage = res.data.listingMedias.filter(
                (item) => item.isFeaturedImage === true
              );
              setEditMediaImages(listingImage);
              setEditFeatureImages(featureImage);

              setGetInputObject(res.data);
              setTimeSlots(res.data.listingBusinessHours);
              setFaqs(res.data.faqs);
              setEditorState(res.data?.description);
              setChosenValue(res.data?.listingFeatures);
              setAddress({ ...res.data?.listingLocation?.city });
              setNewAddress(res.data?.listingLocation);
              setLocalAddress({
                addressLine1: res.data?.listingLocation?.addressLine1,
                postalCode: res.data?.listingLocation?.postalCode,
                id: res.data?.listingLocation?.id,
                latitude: res.data?.listingLocation?.latitude,
                longitude: res.data?.listingLocation?.longitude,
                locationPlaceId: res.data?.listingLocation?.locationPlaceId,
              });
              setLatLong({
                latitude: res.data?.listingLocation?.latitude,
                longitude: res.data?.listingLocation?.longitude,
              });
              setTempStreetAddress(res.data?.listingLocation?.addressLine1);
              setTempAddress(res.data?.listingLocation?.city?.cityName);
            }
          })
          .catch((err) => console.log("err", err));
      } else {
        getEditListingDataWithoutToken(params.id)
          .then((res) => {
            if (res.success) {
              dispatch(listingAction.setEditListing(res.data));
              setCategory(res.data.categoryId);
              setFormValues({
                title: res.data?.title,
                address1: res.data?.listingLocation?.addressLine2,
                city: "",
                phone: res.data?.phoneNumber,
                whatsapp: res.data?.phoneNumber,
                website: res.data?.externalUrl,
              });
              setChosenValue(res.data?.listingFeatures);
              setListingType(res.data.listingType);
              let listingImage = res.data.listingMedias.filter(
                (item) => item.isFeaturedImage === false
              );
              let featureImage = res.data.listingMedias.filter(
                (item) => item.isFeaturedImage === true
              );
              setEditMediaImages(listingImage);
              setEditFeatureImages(featureImage);

              setGetInputObject(res.data);
              setTimeSlots(res.data.listingBusinessHours);
              setFaqs(res.data.faqs);
              setEditorState(
                res.data?.description
                  ? res.data?.description
                  : RichTextEditor.createEmptyValue()
              );
              setLocalAddress({
                addressLine1: res.data?.listingLocation?.addressLine1,
                postalCode: res.data?.listingLocation?.postalCode,
                id: res.data?.listingLocation?.id,
                latitude: res.data?.listingLocation?.latitude,
                longitude: res.data?.listingLocation?.longitude,
                locationPlaceId: res.data?.listingLocation?.locationPlaceId,
              });
              setAddress({ ...res.data?.listingLocation?.city });
              setNewAddress(res.data?.listingLocation);
            }
          })
          .catch((err) => console.log("err", err));
      }
    }
  }, [dispatch, isLoggedIn, params.id]);

  const validateForm = () => {
    let fields;
    let isValid = true;

    if (!params.id && !categoryChanges) {
      fields = getInputObject?.categoryFormFields || [];
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i].formField;
        const fieldValue = formFieldValue.find(
          (f) => f.ffName === field.ffName
        )?.ffValue;
        if (!fields[i].required) {
          if (
            (field.ffType === "TEXT" ||
              field.ffType === "DATE" ||
              field.ffType === "DATE_AND_TIME") &&
            (!fieldValue || fieldValue.trim() === "")
          ) {
            toast.error(`Please enter ${field.ffDisplayName} value`);
            isValid = false;
            break; // Stop validation and show the error
          }

          if (
            (field.ffType === "TEXT" ||
              field.ffType === "DATE" ||
              field.ffType === "DATE_AND_TIME") &&
            fieldValue &&
            fieldValue.length > 64
          ) {
            toast.error(
              `Form field value for ${field.ffDisplayName} is too big`
            );
            isValid = false;
            break; // Stop validation and show the error
          }

          if (field.ffType === "DROPDOWN" && !fieldValue) {
            toast.error(`Please select ${field.ffDisplayName} value`);
            isValid = false;
            break; // Stop validation and show the error
          }

          if (field.ffType === "RADIO" && !fieldValue) {
            toast.error(`Please select ${field.ffDisplayName} value`);
            isValid = false;
            break; // Stop validation and show the error
          }

          if (
            field.ffType === "MULTI_CHECK" &&
            (!fieldValue || fieldValue.length === 0)
          ) {
            toast.error(`Please select at least one ${field.ffDisplayName}`);
            isValid = false;
            break; // Stop validation and show the error
          }
        }
        // Add other field validation conditions as needed
      }
    } else if (params.id && !categoryChanges) {
      fields = getInputObject?.listingFormFields || [];
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]?.categoryFormField?.formField;
        const fieldValue = formFieldValue?.find(
          (f) => f.ffName === field.ffName
        )?.ffValue;

        if (!fields[i].required) {
          if (
            (field.ffType === "TEXT" ||
              field.ffType === "DATE" ||
              field.ffType === "DATE_AND_TIME") &&
            (!fieldValue || fieldValue.trim() === "")
          ) {
            toast.error(`Please enter ${field.ffDisplayName} value`);
            isValid = false;
            break; // Stop validation and show the error
          }

          if (
            (field.ffType === "TEXT" ||
              field.ffType === "DATE" ||
              field.ffType === "DATE_AND_TIME") &&
            fieldValue &&
            fieldValue.length > 64
          ) {
            toast.error(
              `Form field value for ${field.ffDisplayName} is too big`
            );
            isValid = false;
            break; // Stop validation and show the error
          }

          if (field.ffType === "DROPDOWN" && !fieldValue) {
            toast.error(`Please select ${field.ffDisplayName} value`);
            isValid = false;
            break; // Stop validation and show the error
          }

          if (field.ffType === "RADIO" && !fieldValue) {
            toast.error(`Please select ${field.ffDisplayName} value`);
            isValid = false;
            break; // Stop validation and show the error
          }

          if (
            field.ffType === "MULTI_CHECK" &&
            (!fieldValue || fieldValue.length === 0)
          ) {
            toast.error(`Please select at least one ${field.ffDisplayName}`);
            isValid = false;
            break; // Stop validation and show the error
          }
        }
        // Add other field validation conditions as needed
      }
    } else {
      fields = getInputObject?.categoryFormFields || [];
      fields = getInputObject?.categoryFormFields || [];
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i].formField;
        const fieldValue = formFieldValue.find(
          (f) => f.ffName === field.ffName
        )?.ffValue;

        if (!fields[i].required) {
          if (
            (field.ffType === "TEXT" ||
              field.ffType === "DATE" ||
              field.ffType === "DATE_AND_TIME") &&
            (!fieldValue || fieldValue.trim() === "")
          ) {
            toast.error(`Please enter ${field.ffDisplayName} value`);
            isValid = false;
            break; // Stop validation and show the error
          }

          if (
            (field.ffType === "TEXT" ||
              field.ffType === "DATE" ||
              field.ffType === "DATE_AND_TIME") &&
            fieldValue &&
            fieldValue.length > 64
          ) {
            toast.error(
              `Form field value for ${field.ffDisplayName} is too big`
            );
            isValid = false;
            break; // Stop validation and show the error
          }

          if (field.ffType === "DROPDOWN" && !fieldValue) {
            toast.error(`Please select ${field.ffDisplayName} value`);
            isValid = false;
            break; // Stop validation and show the error
          }

          if (field.ffType === "RADIO" && !fieldValue) {
            toast.error(`Please select ${field.ffDisplayName} value`);
            isValid = false;
            break; // Stop validation and show the error
          }

          if (
            field.ffType === "MULTI_CHECK" &&
            (!fieldValue || fieldValue.length === 0)
          ) {
            toast.error(`Please select at least one ${field.ffDisplayName}`);
            isValid = false;
            break; // Stop validation and show the error
          }
        }
        // Add other field validation conditions as needed
      }
    }

    return isValid;
  };

  const checkArraysLength = (array1, array2) => {
    return array1.length > 0 || array2.length > 0 ? false : true;
  };

  const handleSubmitListing = (e) => {
    e.preventDefault();
    if (checkArraysLength(mediaImages, editMediaImages)) {
      toast.error("Please select an image.");
      setLoading(false);
    } else if (!listingType) {
      toast.error("Please choose listing type.");
      setLoading(false);
    } else if (!formValues.title) {
      toast.error("Please enter title.");
      setLoading(false);
    } else if (formValues.title.length > 250) {
      toast.error("Title should be max. 250 character.");
      setLoading(false);
    } else if (
      localAddress.addressLine1 !== "" &&
      localAddress.addressLine1 !== tempStreetAddress &&
      listingType !== "SEARCHING"
    ) {
      setStreetWarning(
        "You must pick up your address from Google Map. If Exact address is not available on Google Map then you can pick at least street name or building name from google map and rest of the information such as building number, floor, etc you can provide in Address Line 2"
      );

      setLoading(false);
    } else if (!address) {
      toast.error("Please enter city.");
      setLoading(false);
    } else if (address?.cityName !== tempAddress) {
      setWarning("Please select city from list.");
      setLoading(false);
    } else if (!formValues.phone) {
      toast.error("Please enter phone.");
      setLoading(false);
    } else if (formValues.phone.length > 20) {
      toast.error("Phone should be max. 20 character.");
      setLoading(false);
    } else if (!category) {
      toast.error("Please choose category.");
      setLoading(false);
    } else if (
      category &&
      !Object.keys(formFieldValue).length === 0 &&
      formFieldValue.constructor === Object
    ) {
      const isValid = validateForm();
      if (
        isValid &&
        formValues.title &&
        formValues.address1 &&
        formValues.city &&
        formValues.phone
      ) {
        Object.values(formErrors).forEach((error) => {
          toast.error(error);
        });
        setLoading(false);
      }
    } else if (!timeSlots.length && listingType === "BUSINESS_PAGE") {
      toast.error("At least one listing business hour should be prooided.");
      setLoading(false);
    } else if (!agree) {
      toast.error("Please agree to our terms and conditions.");
      setLoading(false);
    } else {
      if (listingType === "SEARCHING") {
        setFormValues({
          ...formValues,
          address1: "",
        });
        setLocalAddress({
          addressLine1: null,
          postalCode: null,
          latitude: null,
          longitude: null,
          locationPlaceId: null,
        });
      }
      if (!params.id) {
        const submitData = {
          id: null,
          ownerUserProfile: null,
          description: editorState,
          listingType,
          title: formValues.title,
          phoneNumber: formValues.phone,
          phoneNumberVerified: false,
          phoneVisibility: null,
          listingStatus: "PENDING_REVIEW",
          externalUrl: formValues.website,
          categoryId: category,
          termsAccepted: agree,
          faqs: faqs,
          listingBusinessHours: timeSlots,
          listingLocation: {
            id: null,
            listingId: null,
            addressLine2:
              listingType !== "SEARCHING" ? formValues.address1 : "",
            city: address,
            ...localAddress,
            addressLine1:
              listingType !== "SEARCHING" ? localAddress.addressLine1 : "",
            postalCode:
              listingType !== "SEARCHING" ? localAddress.postalCode : null,

            locationPlaceId:
              listingType !== "SEARCHING" ? localAddress.locationPlaceId : null,
            ...latLong,
          },

          listingFeatures: chosenValue,
          listingFormFields: formFieldValue,

          listingTags: [
            {
              id: null,
              listingId: null,
              tagsDto: {
                id: null,
                tag: "#ThisIsTag155",
                slug: "tag-155",
              },
            },
          ],
          listingMedias: [...mediaImages, ...featureImages],
        };

        addListing(submitData)
          .then((res) => {
            if (res.success) {
              toast.success(
                "Great! Your ad is in review. Once approved, it will be live. Thanks for choosing 100xHelper!"
              );
              navigate(`/my-ads`);
              setLoading(false);
              setAddress(null);
              setLocalAddress(null);
              setTimeSlots([]);
            }
          })
          .catch((ERR) => {
            // toast.error(ERR?.response?.data?.errors[0]);
            setLoading(false);
          });
      } else {
        const submitData = {
          id: editData.id,
          ownerUserProfile: profileInfo,
          description: editorState,
          listingType,
          title: formValues.title,
          phoneNumber: formValues.phone,
          phoneNumberVerified: false,
          phoneVisibility: null,
          listingStatus: "PENDING_REVIEW",
          externalUrl: formValues.website,
          categoryId: category,
          termsAccepted: agree,
          faqs: faqs,
          listingBusinessHours: timeSlots,

          listingLocation: {
            city: address,
            ...localAddress,
            addressLine2:
              listingType !== "SEARCHING" ? formValues.address1 : null,
            addressLine1:
              listingType !== "SEARCHING" ? localAddress.addressLine1 : null,
            postalCode:
              listingType !== "SEARCHING" ? localAddress.postalCode : null,
            locationPlaceId:
              listingType !== "SEARCHING" ? localAddress.locationPlaceId : null,
            ...latLong,
          },

          listingFeatures: chosenValue,
          listingFormFields: formFieldValue,

          listingTags: editData.listingTags,
          listingMedias: [
            ...mediaImages,
            ...editMediaImages,
            ...editFeatureImages,
            ...featureImages,
          ],
        };
        editSingleListing(editData.id, submitData)
          .then((res) => {
            if (res.success) {
              navigate("/my-ads");
              toast.success(
                "Success! Your changes have been submitted and are now pending review by our team. Thank you for your patience!",
                {
                  autoClose: 6000, //3 seconds
                }
              );
              setEditData(null);

              dispatch(listingAction.setRemoveEditListing());
              setLoading(false);
              setAddress(null);
              setLocalAddress(null);
              setTimeSlots([]);
            }
          })
          .catch((ERR) => {
            console.log("ERR", ERR);
            if (ERR?.response?.data) {
              toast.error(ERR?.response?.data?.errors[0]);
            }
            setLoading(false);
          });
      }
    }
  };

  return (
    <div id="page">
      <PagesHeader />
      <main className="call_section pattern">
        <div className="sub_header_in sticky_header">
          <div className="container text-center">
            <h1 className="wow bounceIn">
              {!params.id
                ? "Post Your Ad"
                : !isEmpty(editData) && editData.listingStatus === "DELETED"
                ? "Edit & Reactivate Your Ad"
                : "Update Your Ad"}
            </h1>
          </div>
          {/* <!-- /container --> */}
        </div>

        <CountryMultiSelect
          setListingType={setListingType}
          listingType={listingType}
          setTimeSlots={setTimeSlots}
          params={params.id}
        />
        <ListingCategory
          setCategory={setCategory}
          category={category}
          listingDetail={listingDetail}
          setGetInputObject={setGetInputObject}
          setFormFieldValue={setFormFieldValue}
          setChosenValue={setChosenValue}
          loading={loading}
          setCategoryChanged={setCategoryChanged}
        />
        <MediaUpload
          handleSubmitListing={handleSubmitListing}
          files={files}
          setFiles={setFiles}
          setMediaImages={setMediaImages}
          mediaImages={mediaImages}
          editMediaImages={editMediaImages}
          setEditMediaImages={setEditMediaImages}
        />
        <PrimaryDetails
          setAddress={setAddress}
          formValues={formValues}
          setFormValues={setFormValues}
          address={address}
          newAddress={newAddress}
          localAddress={localAddress}
          setLocalAddress={setLocalAddress}
          setLatLong={setLatLong}
          setTempAddress={setTempAddress}
          warning={warning}
          setTempStreetAddress={setTempStreetAddress}
          setStreetWarning={setStreetWarning}
          streetWarning={streetWarning}
          setWarning={setWarning}
          listingType={listingType}
        />
        {getInputObject && (
          <ListingFeatures
            getInputObject={getInputObject}
            setChosenValue={setChosenValue}
          />
        )}

        {getInputObject && (
          <ListingType
            setListingType={setListingType}
            listingType={listingType}
            setTimeSlots={setTimeSlots}
            setFormFieldValue={setFormFieldValue}
            setFormErrors={setFormErrors}
            formErrors={formErrors}
            formFieldValue={formFieldValue}
            getInputObject={getInputObject}
            setChosenValue={setChosenValue}
            chosenValue={chosenValue}
            categoryChanges={categoryChanges}
            params={params.id}
          />
        )}
        {listingType === "BUSINESS_PAGE" && (
          <BusinessHours timeSlots={timeSlots} setTimeSlots={setTimeSlots} />
        )}
        <MoreInfo editorState={editorState} setEditorState={setEditorState} />
        <CardWrapper>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={<Checkbox defaultChecked={agree} />}
                label="By submitting this ad/listing, I agree to the Terms and Conditions and Privacy Policy of 100xHelper. *"
                labelPlacement="end"
                onChange={(e) => setAgree(e.target.checked)}
              />
            </FormGroup>
          </FormControl>
        </CardWrapper>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-8">
              <div className="box_account d-flex justify-content-center">
                {/* <div className="form_container"> */}{" "}
                <button
                  className="listing_add"
                  // style={{ background: "#283350" }}
                  onClick={handleSubmitListing}
                  disabled={loading}
                >
                  {params.id
                    ? !isEmpty(editData) && editData.listingStatus === "DELETED"
                      ? "Reactivate Your Ad"
                      : "Update Your Ad"
                    : "Save and Preview"}
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm mx-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
                <button
                  className="btn_delete mx-1"
                  onClick={() => navigate(-1)}
                  // disabled={row?.listingStatus === "DELETED"}
                >
                  Cancel
                </button>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Listing;
