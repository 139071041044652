import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";

const AddListingFeatures = ({ formFields, setChosenValue }) => {
  const handleCheckboxChange = (event, featureId) => {
    const { checked } = event.target;
    if (checked) {
      setChosenValue((prevValue) => [
        ...prevValue,
        {
          id: null,
          listingId: null,
          categoryFeature: featureId,
        },
      ]);
    } else {
      setChosenValue((prevValue) =>
        prevValue.filter(
          (f) => f.categoryFeature.feature.id !== featureId.feature.id
        )
      );
    }
  };

  return (
    <div className="row">
      <div className="form-group col-12 col-sm-12 col-md-7 col-lg-7">
        <label className="fw-bold" style={{ fontSize: "1.3rem" }}>
          {"Listing Features"}
        </label>
        <br />
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            {formFields?.map((field) => (
              <FormControlLabel
                key={field.feature.id}
                control={
                  <Checkbox
                    onChange={(e) => handleCheckboxChange(e, field)}
                    sx={{ fontWeight: 900 }}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "1rem" }}>
                    {field.feature.featureDisplayName}
                  </Typography>
                }
                labelPlacement="end"
                sx={{ fontSize: "3rem" }}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
      <div className="col-12 col-sm-12 col-md-5 col-lg-5">
        <p>
          <b>Tip: </b>
          If you're offering accommodation, choose all available features. If
          you're looking for accommodation, select your desired features.
        </p>
      </div>
    </div>
  );
};

export default AddListingFeatures;
