import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

const EditListingFeatures = ({ formFields, setChosenValue, newArray }) => {
  const handleCheckboxChange = (event, featureId) => {
    const { checked } = event.target;

    if (checked) {
      setChosenValue((prevValue) => [
        ...prevValue,
        {
          id: featureId.id,
          listingId: featureId.listingId,
          categoryFeature: featureId.categoryFeature,
        },
      ]);
    } else {
      setChosenValue((prevValue) =>
        prevValue.filter(
          (f) =>
            f.categoryFeature.feature.id !==
            featureId.categoryFeature.feature.id
        )
      );
    }
  };
  const handleNewCheckboxChange = (event, featureId) => {
    const { checked } = event.target;
    if (checked) {
      setChosenValue((prevValue) => [
        ...prevValue,
        {
          id: null,
          listingId: null,
          categoryFeature: featureId,
        },
      ]);
    } else {
      setChosenValue((prevValue) =>
        prevValue.filter(
          (f) => f.categoryFeature.feature.id !== featureId.feature.id
        )
      );
    }
  };

  return (
    <div>
      <div className="form-group col-12 col-sm-12 col-md-7 col-lg-7">
        <label className="fw-bold">{"Listing Features"}</label>
        <br />
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            {formFields?.map((field) => (
              <FormControlLabel
                key={field.categoryFeature.feature.id}
                control={
                  <Checkbox
                    defaultChecked={formFields
                      ?.map(
                        (item) =>
                          item.categoryFeature.feature.featureDisplayName
                      )
                      ?.includes(field.categoryFeature.feature.featureName)}
                  />
                }
                onChange={(e) => handleCheckboxChange(e, field)}
                value={field}
                label={field.categoryFeature.feature.featureDisplayName}
                labelPlacement="end"
              />
            ))}
            {newArray?.map((field) => (
              <FormControlLabel
                key={field?.feature?.id}
                control={
                  <Checkbox
                    defaultChecked={formFields
                      ?.map((item) => item?.feature?.featureDisplayName)
                      ?.includes(field?.feature?.featureName)}
                  />
                }
                onChange={(e) => handleNewCheckboxChange(e, field)}
                value={field}
                label={field?.feature?.featureDisplayName}
                labelPlacement="end"
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
      <div className="col-5 col-sm-12 col-md-5 col-lg-5">
        <p>
          <b>Tip: </b>
          If you're listing accommodation, choose all available features. If
          you're searching for accommodation, select your desired features.
        </p>
      </div>
    </div>
  );
};

export default EditListingFeatures;
