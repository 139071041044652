import { UPDATE_LOGIN_POPUP_STATE, UPDATE_ROUTE } from "./types";

const defaultReducer = {
  isLoginPopup: false,
  prevPath: "/",
};

const loginLocationReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case UPDATE_ROUTE:
      return {
        ...state,
        prevPath: action.payload,
      };
    case UPDATE_LOGIN_POPUP_STATE:
      return {
        ...state,
        isLoginPopup: action.payload,
      };
    default:
      return state;
  }
};
export default loginLocationReducer;
