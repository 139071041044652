import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

const LocationSearch = ({
  setAddress,
  address,
  newAddress,
  setLatLong,
  setTempAddress,
  warning,
  setWarning,
}) => {
  const [oldAddress, setOldAddress] = useState(newAddress);
  const [newWarning, setNewWarning] = useState(warning);
  // const [tempAddress, setTempAddress] = useState("");
  useEffect(() => {
    setOldAddress(newAddress);
  }, [newAddress]);
  useEffect(() => {
    setNewWarning(warning);
  }, [warning]);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const params = useParams();
  const options = {
    // componentRestrictions: { country: "ng" },
    fields: [
      "formatted_address",
      "address_components",
      "geometry",
      "icon",
      "name",
      "place_id",
    ],
    types: ["(cities)"],
  };
  useEffect(() => {
    if (window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();

        if (place) {
          const placeId = place.place_id;

          if (place) {
            const {
              formatted_address,
              geometry,
              place_id,
              address_components,
            } = place;
            const { lat, lng } = geometry.location;

            // Get the city name
            const cityComponent = address_components.find((component) =>
              component.types.includes("locality")
            );
            const countryComponent = address_components.find((component) =>
              component.types.includes("country")
            );

            const countryName = countryComponent
              ? countryComponent.long_name
              : "";
            const countryShortName = countryComponent
              ? countryComponent.short_name
              : "";
            const cityName = cityComponent ? cityComponent.long_name : "";

            let postalCode = null;

            for (const component of address_components) {
              if (component.types.includes("postal_code")) {
                postalCode = component.long_name;
                break;
              }
            }
            setTempAddress(cityName);
            if (params.id) {
              setAddress({
                ...newAddress?.city,
                id: newAddress?.city?.id ? newAddress?.city.id : null,
                cityName: cityName,
                shortCityName: null,
                country: {
                  id: newAddress?.city?.country?.id
                    ? newAddress?.city?.country?.id
                    : null,
                  countryName: countryName,
                  shortCountryName: countryShortName,
                  countryPlaceId: place_id,
                  description: null,
                  currency: null,
                  indianPopulation: null,
                  populationLastUpdateTime: null,
                  multimediaUrl: null,
                },
                placeId: place_id,
                description: null,
                indianPopulation: null,
                populationLastUpdateTime: null,
                multimediaUrl: null,
              });
              setLatLong({
                latitude: geometry.location.lat(),
                longitude: geometry.location.lng(),
              });
            } else {
              setAddress({
                id: null,
                cityName: cityName,
                shortCityName: null,
                country: {
                  id: null,
                  countryName: countryName,
                  shortCountryName: countryShortName,
                  countryPlaceId: place_id,
                  description: null,
                  currency: null,
                  indianPopulation: null,
                  populationLastUpdateTime: null,
                  multimediaUrl: null,
                },
                placeId: place_id,
                description: null,
                indianPopulation: null,
                populationLastUpdateTime: null,
                multimediaUrl: null,
              });
              setLatLong({
                latitude: geometry.location.lat(),
                longitude: geometry.location.lng(),
              });
            }
          }
        } else {
        }
      });
    }
  }, [window.google]);

  const handleChange = (e) => {
    setTempAddress(e.target.value);
    setWarning("");
  };

  return (
    <div>
      <div>
        <div className="form-group mt-1">
          <label className={"fw-bold"} htmlFor={"address"}>
            {"City *"}
          </label>
          <i
            className=" icon-info-circled cursor-pointer fs-6 ms-2 "
            data-toggle="tooltip"
            data-placement="right"
            title="City"
            style={{
              fontSize: "1.1rem",
            }}
          ></i>
          <input
            className="form-control"
            id="address1"
            ref={inputRef}
            type="text"
            name={"address1"}
            placeholder="Select your city using Google Map"
            defaultValue={address?.cityName}
            onChange={handleChange}
            required
          />
          {newWarning ? <em style={{ color: "red" }}>{newWarning}</em> : ""}
        </div>
      </div>
    </div>
  );
};

export default LocationSearch;
