export const LOGIN_USER_SUCCESSFUL = "LOGIN_USER_SUCCESSFUL";
export const LOGIN_PROCESSING = "LOGIN_PROCESSING";
export const STOP_LOGIN_PROCESSING = "STOP_LOGIN_PROCESSING";

export const REGISTER_USER_SUCCESSFUL = "REGISTER_USER_SUCCESSFUL";
export const REGISTER_PROCESSING = "REGISTER_PROCESSING";
export const STOP_REGISTER_PROCESSING = "STOP_REGISTER_PROCESSING";

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const LOGOUT_SUCCESSFUL = "LOGOUT_SUCCESSFUL";

export const TEMP_REGISTER_DATA = "LOGOUT_SUCCESSFUL";
export const TEMP_ACCESS_TOKEN = "LOGOUT_SUCCESSFUL";
