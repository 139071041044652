import React from "react";

const Error = () => {
  return (
    <div id="page">
      <main>
        <div id="error_page">
          <div className="wrapper">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-xl-7 col-lg-9">
                  <h2>
                    404 <i className="icon_error-triangle_alt"></i>
                  </h2>
                  <p>
                    We're sorry, but the page you were looking for doesn't
                    exist.
                  </p>
                  {/* <form>
								<div className="search_bar_error">
									<input type="text" className="form-control" placeholder="What are you looking for?">
									<input type="submit" value="Search">
								</div>
							</form> */}
                </div>
              </div>
              {/* <!-- /row --> */}
            </div>
            {/* <!-- /container --> */}
          </div>
          {/* <!-- /wrapper --> */}
        </div>
        {/* <!-- /error_page --> */}
      </main>
    </div>
  );
};

export default Error;
