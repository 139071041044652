import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { updateNotificationStatus } from "../../../helper/APIs/USER/Notification";
import * as notificationActions from "../../../redux/Notifications/actions";
import {
  NOTIFICATION_STATUS_ACKNOWLEDGED,
  NOTIFICATION_STATUS_CREATED,
} from "../../../redux/Notifications/types";

import "./notification.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const NotificationMapper = {
  CREATED: "created-notification",
  SEEN: "seen-notification",
  ACKNOWLEDGED: "acknowledge-notification",
};

const NotificationsPage = ({ handleClose }) => {
  const dispatch = useDispatch();
  const getAllNotifications = useSelector(
    (state) => state?.notificationReducer?.notifications
  );
  const handleClickMessage = async (index) => {
    try {
      const tempData = getAllNotifications;
      if (!tempData[index]) {
        toast.error("Something went wrong!");
        return;
      }
      tempData[index] = {
        ...tempData[index],
        status: NOTIFICATION_STATUS_ACKNOWLEDGED,
      };

      const params = [tempData[index].id];
      // window.open(tempData[index].cta, "_blank");
      const res = await updateNotificationStatus(
        NOTIFICATION_STATUS_ACKNOWLEDGED,
        params
      );
      if (res.success) {
        dispatch(notificationActions.updateNotifications([...tempData]));
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  return (
    <div className="postion-relative">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="position-absolute"
        sx={{ right: 8, top: 10 }}
      >
        <CloseIcon />
      </IconButton>
      <div
        className="sub_header_in sticky_header"
        style={{ marginTop: "58px", padding: "20px 0" }}
      >
        <div className="px-2">
          <h1 className="wow bounceIn">Notifications</h1>
        </div>
      </div>
      <div className="user-notification__wrapper ">
        {isEmpty(getAllNotifications) && (
          <div className="full-width d-flex justify-content-center align-items-center ">
            <p className="mt-4 font-weight-bold font-size-16">
              No Notifications Found
            </p>
          </div>
        )}
        {!isEmpty(getAllNotifications) && (
          <ul className="user-notification__list">
            {getAllNotifications.map((notification, index) => {
              return (
                <li
                  key={index}
                  className={`p-2 d-flex justify-content-start align-items-center ${
                    NotificationMapper[notification.status]
                  }`}
                  onClick={() => {
                    if (
                      notification.status !== NOTIFICATION_STATUS_ACKNOWLEDGED
                    ) {
                      handleClickMessage(index);
                    }
                  }}
                >
                  <div className="position-relative ">
                    {notification.status === NOTIFICATION_STATUS_CREATED && (
                      <span className="unseen-notification" />
                    )}
                  </div>
                  <span className="ml-2">{notification.subject}</span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;
