import {
  Box,
  Button,
  CircularProgress,
  Step,
  StepButton,
  Stepper,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addListing,
  editSingleListing,
  getEditListingData,
  listingMediaUpload,
} from "../../../../helper/APIs/USER/Listing";
import { STEPPER_INITIAL_VALUES, STEPS } from "../../../Constants/constants";
import PagesHeader from "../../PagesHeader";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";

import * as loginLocationActions from "../../../../redux/LoginLocation/actions";

const TaskDetails = () => {
  const isLoggedIn = useSelector((state) => state?.loginReducer?.isLoggedIn);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const [isFormValid, setIsFormValid] = useState({
    stepOne: false,
    stepTwo: false,
    stepThree: false,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFromLogin, setIsFromLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const [tempMediaFiles, setTempMediaFiles] = useState([]);

  const [mediaImages, setMediaImages] = useState([]);
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);

  const [values, setValues] = useState({
    ...STEPPER_INITIAL_VALUES,
  });
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const title = searchParams.get("title");

    if (title) {
      setValues({
        ...values,
        title,
      });
    }
  }, [location.search, values]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = useCallback(async () => {
    if (isLoggedIn) {
      const {
        taskDueDateType,
        preferredTimeOfDay,
        locationType,
        preferredContactMethod,
        budgetType,
        listingMedias,
      } = values;
      setIsSubmitted(true);

      delete values.isFlexible;

      if (["AS_SOON_AS_POSSIBLE", "FLEXIBLE"].includes(taskDueDateType)) {
        delete values.taskDueDate;
      }

      if (isEmpty(preferredTimeOfDay)) {
        delete values.preferredTimeOfDay;
      }
      if (isEmpty(listingMedias)) {
        delete values.listingMedias;
      }

      if (locationType === "REMOTE") {
        delete values.listingLocation;
      }
      if (preferredContactMethod === "SAMAYLEKHA_CHAT") {
        delete values.phoneNumber;
      }
      if (budgetType === "OPEN_FOR_OFFERS") {
        delete values.budget;
      }

      if (params.id) {
        if (values.listingStatus === "COMPLETED") {
          delete values?.id;
          delete values?.listingLocation?.id;
          if (!isEmpty(values.listingMedias)) {
            values["listingMedias"] = values.listingMedias.map(
              ({ featuredImage, isFeaturedImage, mediaUrl }) => ({
                featuredImage,
                isFeaturedImage,
                mediaUrl,
              })
            );
          }
          addTaskDetails({ ...values, listingStatus: "OPEN" });
        } else {
          editTaskDetails(params.id, values);
        }
      } else {
        addTaskDetails(values);
      }
    } else {
      setIsFromLogin(true);
      dispatch(loginLocationActions.updateLoginPopup(true));
    }
  }, [dispatch, isLoggedIn, params.id, values]);

  const handleFileToS3 = async () => {
    let allFilesWithinLimit = true;

    const newFiles = tempMediaFiles.filter((file) => {
      if (file.size > 5 * 1024 * 1024) {
        toast.error(`${file.name} size exceeds the 5MB limit`);
        allFilesWithinLimit = false;
        return false;
      }
      return true;
    });
    if (allFilesWithinLimit) {
      const uploadPromises = newFiles.map((file) => {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        return listingMediaUpload(fileFormData)
          .then((response) => ({
            featuredImage: false,
            mediaUrl: response.data.mediaUrl,
            isFeaturedImage: false,
          }))
          .catch((error) => {
            console.error("Error uploading file:", file.name, error);
            toast.error(`Error uploading ${file.name}`);
            return null;
          });
      });

      try {
        const responses = await Promise.all(uploadPromises);
        const validResponses = responses.filter(
          (response) => response !== null
        );

        await Promise.resolve(
          setMediaImages((prevMediaImages) => [
            ...prevMediaImages,
            ...validResponses,
          ])
        );

        await Promise.resolve(
          handleChange({
            target: {
              name: "listingMedias",
              value: [...mediaImages, ...validResponses],
            },
          })
        );

        setShouldSubmit(true);
      } catch (err) {
        console.error("Error uploading files", err);
        setMediaImages([]);
        setMediaImages([]);
        setImages([]);
        setPreviews([]);
      }
    }
  };

  const editTaskDetails = async (id, values) => {
    await editSingleListing(id, {
      ...values,
      ...(id && { listingStatus: "OPEN" }),
    })
      .then(() => {
        setIsSubmitted(false);
        toast.success("Your task has been updated successfully.");
        setActiveStep(0);
        setValues({
          ...STEPPER_INITIAL_VALUES,
        });
        setInitialValues({
          ...STEPPER_INITIAL_VALUES,
        });
        setIsFromLogin(false);
        navigate("/ads/category/all");
      })
      .catch((err) => {
        setIsSubmitted(false);
        toast.error(
          err?.response?.data?.error || err?.response?.data?.errors[0]
        );
      });
  };

  const addTaskDetails = async (values) => {
    await addListing(values)
      .then(() => {
        setIsSubmitted(false);
        toast.success("Your task has been submitted successfully.");
        setActiveStep(0);
        setValues({
          ...STEPPER_INITIAL_VALUES,
        });
        setIsFromLogin(false);
        navigate("/ads/category/all");
      })
      .catch((err) => {
        setIsSubmitted(false);
        toast.error(
          err?.response?.data?.error || err?.response?.data?.errors[0]
        );
      });
  };

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit();
      setShouldSubmit(false);
      setMediaImages([]);
      setImages([]);
      setPreviews([]);
    }
  }, [handleSubmit, shouldSubmit]);

  useEffect(() => {
    if (isFromLogin && isLoggedIn) {
      if (!isEmpty(previews)) {
        handleFileToS3();
      } else {
        handleSubmit();
      }
    }
  }, [isFromLogin, isLoggedIn]);

  useEffect(() => {
    if (params.id) {
      if (isLoggedIn) {
        setIsLoading(true);
        getEditListingData(params.id)
          .then((res) => {
            if (res.success) {
              setIsLoading(false);
              const keysToDelete = [
                "listingBusinessHours",
                "listingFeatures",
                "listingFormFields",
                "listingTags",
                "ownerUserProfile",
              ];

              keysToDelete.forEach((key) => {
                delete res.data[key];
              });

              if (res.data.listingStatus === "COMPLETED") {
                setValues({
                  ...values,
                  ...res.data,
                  isFlexible: false,
                  preferredTimeOfDay: [],
                  taskDueDate: "",
                  taskDueDateType: "",
                });
                setInitialValues({
                  ...values,
                  ...(!isEmpty(res.data.preferredTimeOfDay) && {
                    isFlexible: true,
                  }),
                  ...res.data,
                });
              } else {
                setValues({
                  ...values,
                  ...(!isEmpty(res.data.preferredTimeOfDay) && {
                    isFlexible: true,
                  }),
                  ...res.data,
                });
              }
              setInitialValues({
                ...values,
                ...(!isEmpty(res.data.preferredTimeOfDay) && {
                  isFlexible: true,
                }),
                ...res.data,
              });
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err", err);
          });
      }
    } else {
      setActiveStep(0);
      setValues({
        ...STEPPER_INITIAL_VALUES,
      });
    }
  }, [isLoggedIn, params.id]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const publicMediaUpload = (acceptedFiles) => {
    const files = Array.from(acceptedFiles);

    if (files.length + images.length > 5) {
      alert("You can only upload a maximum of 5 images.");
      return;
    }

    setTempMediaFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    setImages((prevImages) => [...prevImages, ...files]);

    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const removePublicImage = (val) => {
    const updatedImages = previews.filter((item) => item !== val);
    setPreviews(updatedImages);
  };
  const getStepContent = (stepIndex, handleChange, values) => {
    switch (stepIndex) {
      case 0:
        return (
          <FirstStep
            handleChange={handleChange}
            values={values}
            setIsFormValid={setIsFormValid}
          />
        );
      case 1:
        return (
          <SecondStep
            handleChange={handleChange}
            values={values}
            setIsFormValid={setIsFormValid}
            publicMediaUpload={publicMediaUpload}
            previews={previews}
            removePublicImage={removePublicImage}
          />
        );
      case 2:
        return (
          <ThirdStep
            handleChange={handleChange}
            values={values}
            setIsFormValid={setIsFormValid}
            initialValues={initialValues}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const handleChange = (e, resetValues = {}) => {
    const { name, value, type, checked, files } = e.target;
    setValues({
      ...values,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
      ...resetValues,
    });
  };

  return (
    <>
      <div>
        <PagesHeader />
        <div className="sub_header_in sticky_header">
          <div className="container p-2">
            <h1>
              {!isLoading &&
                (params.id
                  ? values?.listingStatus === "COMPLETED"
                    ? "Repost your task"
                    : "Update your task"
                  : "Post your Task")}
            </h1>
          </div>
        </div>
        {isLoading && (
          <div style={{ margin: "15rem 0rem" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </div>
        )}
        {!isLoading && (
          <div>
            <div className=" margin_80_55 container">
              <Box className="stepper-wrapper">
                <Box className="stepper-container">
                  <Stepper
                    activeStep={activeStep}
                    sx={{
                      "& .MuiStepLabel-label": {
                        fontFamily: "Poppins",
                      },
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: "#283350",
                      },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: "#283350",
                      },
                    }}
                  >
                    {STEPS.map((label) => (
                      <Step key={label}>
                        <StepButton
                          color="inherit"
                          style={{ cursor: "default", fontFamily: "Poppins" }}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                <Box className="p--10">
                  <Box>
                    {getStepContent(activeStep, handleChange, values)}
                    <div className="stepper--footer d-flex justify-content-between align-items-center">
                      <Button
                        disabled={activeStep === 0 || isSubmitted}
                        onClick={handleBack}
                        className={activeStep !== 0 ? `helper-btn fw-bold` : ""}
                      >
                        {activeStep !== 0 ? "Back" : ""}
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: "#283350",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#283350",
                          },
                        }}
                        variant="contained"
                        color="primary"
                        disabled={
                          (activeStep === 0 && !isFormValid.stepOne) ||
                          (activeStep === 1 && !isFormValid.stepTwo) ||
                          (activeStep === 2 && !isFormValid.stepThree) ||
                          isSubmitted
                        }
                        onClick={
                          activeStep === STEPS.length - 1
                            ? handleSubmit
                            : handleNext
                        }
                      >
                        {activeStep === STEPS.length - 1 ? (
                          <>
                            {isSubmitted && (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {params.id ? "Save" : "Finish"}
                          </>
                        ) : (
                          "Next"
                        )}
                      </Button>
                    </div>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default TaskDetails;
