import { Avatar, Box, Typography } from "@mui/material";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import "./blogs.css";

import {
  BlogContentList,
  BlogsSidebarList,
} from "../../../shared/Constants/constants";
import Footer from "../../../shared/Footer";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const navigate = useNavigate();
  return (
    <div id="page" className="theia-exception">
      <PagesHeader />

      <main>
        <Box
          className="container"
          sx={{ marginTop: "60px", marginBottom: "10px" }}
        >
          <Box
            className="d-flex blogs-wrapper justify-content-between align-items-start"
            gap={2}
          >
            <Box className="blog-content">
              <Typography
                component={"h1"}
                className="fw-bold"
                sx={{
                  fontSize: { xs: "25px", sm: "28px", md: "30px" },
                  textTransform: "uppercase",
                }}
              >
                Discover our latest blog get knowledge about us
              </Typography>
              <Box className="blog-content-item-list">
                {BlogContentList.map((item, index) => {
                  return (
                    <Box
                      className="d-flex justify-content-start align-items-start blog-content-item"
                      gap={1}
                      key={index}
                      mt={1}
                      mb={1}
                      onClick={() => navigate(`/blogs/${item.id}`)}
                    >
                      <Avatar
                        src={item.imagePath}
                        variant="square"
                        sx={{
                          width: { xs: "100%", sm: "200px", md: "200px" },
                          height: { xs: "100%", sm: "200px", md: "200px" },
                          borderRadius: "8px",
                        }}
                      />

                      <Box
                        className="d-flex flex-column justify-content-between "
                        sx={{
                          width: { xs: "100%", sm: "60%", md: "60%" },
                          overflow: "hidden",
                          padding: "10px",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "20px" }}
                          className="fw-bold truncate"
                        >
                          {item.title}
                        </Typography>
                        <Typography className="long-truncate mt-3 mb-3">
                          {item.description}
                        </Typography>
                        <Box className="d-flex align-items-center " gap={1}>
                          <Avatar src={item.blogOwnerImage} alt="" />
                          <Typography sx={{ fontSize: "13px" }}>
                            {item.blogOwnerName}
                          </Typography>{" "}
                          |
                          <Typography sx={{ fontSize: "13px" }}>
                            {item.blogDate}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box className="blog-sidebar">
              <Typography
                component={"h1"}
                className="fw-bold truncate"
                sx={{
                  fontSize: "20px",
                }}
              >
                Top Blogs
              </Typography>
              <Box className="blog-list">
                {BlogsSidebarList.map((item, index) => {
                  return (
                    <Box
                      className="d-flex justify-content-start align-items-center blog-item"
                      gap={1}
                      key={index}
                      mt={1}
                      mb={1}
                      onClick={() => navigate(`/blogs/${item.id}`)}
                    >
                      <Avatar
                        src={item.imagePath}
                        variant="square"
                        sx={{
                          borderRadius: "8px",
                        }}
                      />
                      <Typography
                        sx={{ fontSize: "14px" }}
                        className="fw-bold blog-item-title"
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box className="follow-us-section">
                <Typography
                  component={"h1"}
                  className="fw-bold truncate"
                  sx={{
                    fontSize: "20px",
                  }}
                >
                  Follow Us
                </Typography>
                <Box className="sidebar-contact-wrapper" mt={2}>
                  <ul className="d-flex align-items-center gap-3 contact-items-list">
                    <li className="contact-item">
                      <a
                        href="https://www.facebook.com/100xHelper"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-brands fa-facebook-f"></i>
                      </a>
                    </li>

                    <li className="contact-item">
                      <a
                        href="https://www.instagram.com/100xHelper/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-brands fa-instagram"></i>
                      </a>
                    </li>

                    <li className="contact-item">
                      <a
                        href="https://www.linkedin.com/company/100xhelper/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-brands fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </main>
      <Footer />
    </div>
  );
};

export default Blogs;
