import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import { Link, useNavigate } from "react-router-dom";

import InputFields from "../../../shared/InputFieldsWithLabels";
import PasswordFields from "../../../shared/InputFieldsWithLabels/PasswordFields";
import * as registerAction from "../../../redux/Auth/action";
import { GOOGLE_CLIENT_ID } from "../../../config";
import { googleAuth } from "../../../helper/APIs/Auth";

const Register = () => {
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirm_password: "",
    fullName: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const handleChange = (e) => {
    setError("");
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formValues.fullName) {
      setError("Please enter first name.");
      setLoading(false);
    } else if (!formValues.email) {
      setError("Please enter email.");
      setLoading(false);
    } else if (!formValues.password) {
      setError("Please enter password.");
      setLoading(false);
    } else if (!formValues.confirm_password) {
      setError("Please enter confirm password.");
      setLoading(false);
    } else if (formValues.password !== formValues.confirm_password) {
      setError("Password and confirm password don't match.");
      setLoading(false);
    } else {
      const submitData = {
        loginType: "EMAIL_PASS",
        loginInfoDto: {
          userProfile: {
            fullname: formValues.fullName,
            email: formValues.email,
            addressVisibility: "REGISTERED_USERS",
            phoneVisibility: "REGISTERED_USERS",
            emailVisibility: "REGISTERED_USERS",
            userConsents: [
              {
                consent: "GIVEN",
                consentRecordTimestamp: Math.floor(Date.now() / 1000),
              },
            ],
          },
          password: formValues.password,
        },
      };
      dispatch(registerAction.registerUser(submitData))
        .then((res) => {
          if (res.status === 200) {
            navigate("/verify-otp", {
              state: {
                email: formValues.email,
                pwd: formValues.password,
                isLogin: false,
              },
            });
            toast.success("User registered successfully.");
            setLoading(false);
            setError("");
          }
        })
        .catch((err) => {
          setLoading(false);

          if (err.response.status === 403) {
            setError("Enter required parameters.");
          } else {
            setError(err?.response?.data.errors.toString());
          }
        });
    }
  };
  const responseGoogle = (response) => {
    if (response?.tokenObj?.id_token) {
      const submitData = {
        idToken: response.tokenObj.id_token,
      };
      googleAuth(submitData).then((res) => {
        if (res.data.jwtToken) {
          dispatch(registerAction.setLoginUser(res, "user"));
          navigate("/");
          toast.success("Login Successfully.");
        }
      });
    } else {
      toast.error("User not exists.");
    }
  };

  return (
    <div id="register_bg">
      <div id="register">
        <aside>
          <figure>
            <Link to="/">
              <img
                src="assets/img/LOGO/100xhelper/100xhelper.svg"
                width="100"
                height="40"
                alt=""
                className="logo_sticky object-fit-cover"
              />
            </Link>
          </figure>
          <form className="" autocomplete="off" onSubmit={handleSubmit}>
            <div className="access_social">
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                icon={true}
                theme="dark"
                onSuccess={responseGoogle}
                onFailure={() =>
                  toast.error("User not exists.", { toastId: "userNotExists" })
                }
                cookiePolicy={"single_host_origin"}
                className="social_bt"
              />
            </div>
            <InputFields
              label="Your Name"
              type="text"
              id="name"
              iconClass="fa fa-user"
              name="fullName"
              onChange={handleChange}
              placeholder="Enter Full Name"
              required={true}
              value={formValues.fullName}
            />
            <InputFields
              label="Your Email"
              type="email"
              id="email"
              iconClass="fa fa-envelope"
              name="email"
              onChange={handleChange}
              placeholder="Enter Email"
              required={true}
              value={formValues.email}
            />

            <PasswordFields
              label="Your Password"
              type="password"
              id="password1"
              iconClass="fa fa-lock"
              name="password"
              onChange={handleChange}
              placeholder="Enter Password"
              required={false}
              defaultValue={formValues.password}
            />
            <PasswordFields
              label="Confirm Password"
              type="password"
              id="password2"
              iconClass="fa fa-lock"
              name="confirm_password"
              onChange={handleChange}
              placeholder="Enter Password Again"
              required={false}
              defaultValue={formValues.confirm_password}
            />

            <em>
              By clicking Register Now!, you agree to our{" "}
              <Link to="/terms">Terms & Conditions</Link>. Learn how we collect,
              use and share your data in our{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>. You will receive
              transactional email notifications from us such as OTP and contact
              inquiries for your ads.
            </em>

            <div
              id="pass-info"
              className="clearfix"
              style={{ color: "red", fontSize: "1rem", fontWeight: 600 }}
            >
              {error}
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn_1 rounded full-width add_top_30"
            >
              Register Now!
              {loading && (
                <span
                  className="spinner-border spinner-border-sm mx-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
            {/* </Link> */}

            <div className="text-center add_top_10">
              Already have an acccount?{" "}
              <strong>
                <Link to="/login">Sign In</Link>
              </strong>
            </div>
            <div className="text-center add_top_10">
              Continue without register?{" "}
              <strong>
                <Link to="/">Home</Link>
              </strong>
            </div>
            <div className="copy">
              Copyright © {new Date().getFullYear()}&nbsp; 100xHelper
              <br /> All Rights Reserved
            </div>
          </form>
        </aside>
      </div>
    </div>
  );
};

export default Register;
