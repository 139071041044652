import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { initChat, sendMessage } from "../../../helper/APIs/USER/Chat";

const InquiryPopup = ({ open, handleClose, profileInfo, listingDetails }) => {
  const [formData, setFormData] = useState({
    fullName: profileInfo.fullname,
    moveInDate: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormValidation = () => {
    let errors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }
    if (!formData.moveInDate) {
      errors.moveInDate = "Move In Date is required";
    }
    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!handleFormValidation()) {
      let message = `Full Name: ${formData.fullName} <br/><br/>Preferred Move In Date: ${formData.moveInDate}<br/><br/>Message: ${formData.message}.`;

      let submitData = { listingInfo: { listingId: listingDetails.id } };
      initChat(submitData)
        .then(async (res) => {
          if (res) {
            await handleSendInquiry(message, res.data.id);
          }
        })
        .catch((err) => console.error("err", err));
    }
  };

  const handleSendInquiry = async (message, chatId) => {
    const submitData = {
      chatId,
      chatMessageType: "TEXT",
      message,
      sender: {
        userProfileId: profileInfo?.id,
      },
    };

    await sendMessage(submitData)
      .then((res) => {
        toast.success("Your message has been sent successfully.");
        handleClose();
      })
      .catch((err) => {
        handleClose();
        toast.error(
          err?.response?.data?.error || err?.response?.data?.errors[0]
        );
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: "left", fontSize: { xs: "18px", md: "22px" } }}
      >
        {"Send Your Inquiry Now!"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 10,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Full Name*"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            error={!!errors.fullName}
            helperText={errors.fullName}
            InputLabelProps={{
              sx: {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                  top: "-5px",
                },
              },
            }}
            InputProps={{
              sx: {
                "@media (max-width: 600px)": {
                  input: {
                    fontSize: "14px",
                    padding: "10px",
                  },
                },
              },
            }}
          />

          <TextField
            fullWidth
            label="Move In Date*"
            name="moveInDate"
            type="date"
            value={formData.moveInDate}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              sx: {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
            }}
            error={!!errors.moveInDate}
            helperText={errors.moveInDate}
            inputProps={{
              min: new Date().toISOString().split("T")[0],
              sx: {
                textAlign: "left",
                "@media (max-width: 600px)": {
                  input: {
                    fontSize: "14px",
                    padding: "10px",
                  },
                },
              },
            }}
          />

          <TextField
            fullWidth
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            multiline
            rows={3}
            focused
            error={!!errors.message}
            helperText={errors.message}
            inputProps={{ maxLength: 1000 }}
            placeholder="Landlord prefers candidates who provide proper introduction. Tell them who you are and why they should consider you for this accommodation."
            sx={{
              mb: 2,
              "& .MuiInputLabel-root": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiOutlinedInput-input": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiFormLabel-root": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiInputBase-root": {
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              },
              "& .MuiInputBase-input::placeholder": {
                "@media (max-width: 600px)": {
                  fontSize: "0.9rem",
                },
              },
            }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default InquiryPopup;
