import React from "react";
import Error from "../../components/404";

const ErrorPage = () => {
  return (
    <div>
      <Error />
    </div>
  );
};

export default ErrorPage;
