import React from "react";
import CardWrapper from "../../Listing/AddListing/CardWrapper.js";
import InputFields from "../../../../shared/InputFieldsWithLabels/index.js";
import { Button } from "@mui/material";

const EventDescription = ({ setDescrription }) => {
  return (
    <CardWrapper>
      <div className="row mb-5">
        <label className="fw-bold">Event Description</label>
        <div className="col-12">
          <textarea
            type="date"
            id="rent"
            name={"rent"}
            className="form-control"
            placeholder="Event description"
            onChange={(e) => setDescrription(e.target.value)}
          />
        </div>
        {/* <div className="col-12 position-fixed bottom-0">
          <Button>cancel</Button>
          <Button>Submit</Button>
        </div> */}
      </div>
    </CardWrapper>
  );
};

export default EventDescription;
