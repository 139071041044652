import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyEmailOtp } from "../../../helper/APIs/Auth";
import * as loginAction from "../../../redux/Auth/action";
import InputFields from "../../../shared/InputFieldsWithLabels";

const OtpVerification = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const dispatch = useDispatch();

  const registrationData = useSelector((state) => state.loginReducer.data);

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(null);

  const handleSubmitStepOne = (e) => {
    e.preventDefault();

    if (otp !== null) {
      setLoading(true);
      let submitData = { email: params?.state?.email, otp };
      verifyEmailOtp(submitData)
        .then((res) => {
          if (res.success) {
            setLoading(false);
            if (params?.state?.isLogin) {
              dispatch(loginAction.loginUser(params?.state, "user"))
                .then((res) => {
                  if (res.status === 200) {
                    setLoading(false);
                    navigate("/");
                  }
                })
                .catch((err) => {
                  setLoading(false);

                  if (err?.response?.status === 403 && err?.response?.data) {
                    toast.error(err.response.data.errors.toString());
                  } else {
                    toast.error("Enter required parameters.");
                  }
                });
            } else {
              dispatch(loginAction.setRegisterUser(registrationData));
              navigate("/");
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      toast.error("Please enter the otp", { toastId: "nullOtp" });
    }
  };

  return (
    <>
      <div id="login_bg">
        <div id="login">
          <aside>
            <figure>
              <Link to="/">
                <img
                  src="assets/img/LOGO/100xhelper/100xhelper.svg"
                  width="100"
                  height="40"
                  alt=""
                  className="logo_sticky object-fit-cover"
                />
              </Link>
            </figure>
            <form className="was-validated" onSubmit={handleSubmitStepOne}>
              <InputFields
                label="OTP"
                type="text"
                id="otp"
                name="otp"
                iconClass="fa fa-keyboard-o"
                onChange={({ target: { value } }) => setOtp(value)}
                required
              />

              <button
                type="submit"
                className="btn_1 rounded full-width"
                disabled={loading}
                onClick={handleSubmitStepOne}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm mx-3"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Submit
              </button>

              <div className="copy">
                Copyright © {new Date().getFullYear()}&nbsp; 100xHelper <br />
                All Rights Reserved
              </div>
            </form>
          </aside>
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
