import { Button } from "@mui/material";
import React from "react";

const SubmitEventBtn = ({ handleSubmit }) => {
  return (
    <div className="row  gx-0">
      <div
        className="col-12 py-3  position-fixed bottom-0 d-flex justify-content-center "
        style={{ background: "#ffffff" }}
      >
        <Button variant="contained" color="primary" sx={{ margin: "0 1rem" }}>
          cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="success"
          sx={{ margin: "0 1rem" }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SubmitEventBtn;
