export const CategoryEnum = {
  apartment: "Apartment",
  sharedApartment: "Shared Apartment",
};

export const InquiryPopupRadioOptions = {
  YES: "yes",
  NO: "no",
};

export const FilterKey = {
  KEY: "Rental Type",
  VALUE: "Long Term",
};

export const BECOME_TASKER_STATUS = {
  PENDING: "PENDING",
  NEVER_APPLIED: "NEVER_APPLIED",
  CONCLUDED: "CONCLUDED",
  REJECTED: "REJECTED",
};
