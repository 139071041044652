import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ListingCarousal from "../ListingDetails/ListingCarousal";

const TaskImagePreview = ({
  open,
  handleClose,
  taskContent,
  startPosition = 0,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="position-absolute"
        sx={{ right: 8, top: 10 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="mt-5">
        <ListingCarousal
          listingDetails={taskContent}
          startPosition={startPosition}
        />
      </DialogContent>
    </Dialog>
  );
};
export default TaskImagePreview;
