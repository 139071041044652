import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  BUDGET_OPTIONS,
  PREFERRED_CONTACTS,
} from "../../../Constants/constants";
import { useParams } from "react-router-dom";
import { isEqual } from "lodash";
const ThirdStep = ({ handleChange, values, setIsFormValid, initialValues }) => {
  const params = useParams();
  useEffect(() => {
    const isBudgetValid =
      values.budgetType === "OPEN_FOR_OFFERS" || !!values.budget;
    const isContactValid =
      (values.preferredContactMethod === "WHATS_APP" &&
        values.phoneNumber !== "") ||
      values.preferredContactMethod === "SAMAYLEKHA_CHAT";

    const isEditValid = params.id ? !isEqual(values, initialValues) : true;

    const isValid = isBudgetValid && isContactValid && isEditValid;

    setIsFormValid((prevState) => ({
      ...prevState,
      stepThree: isValid,
    }));
  }, [values, params.id, initialValues]);

  return (
    <>
      <Box>
        <label className="fw-bold">Set a budget for your task *</label>
        <Box
          className="d-flex mt--20"
          gap={{
            xs: "10px",
            sm: "10px",
            md: "20px",
            lg: "20px",
          }}
        >
          {BUDGET_OPTIONS.map((item, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  handleChange(
                    {
                      target: {
                        name: "budgetType",
                        value: item.value,
                      },
                    },
                    { budget: 0 }
                  );
                }}
                title={item.title}
                className={`cursor-pointer ${
                  values.budgetType === item.value
                    ? "active-flexible"
                    : "tasker-items"
                } flexible-wrapper`}
              >
                <Typography
                  className="item-title p--0"
                  sx={{
                    fontSize: "15px",
                    fontFamily: "Poppins",
                  }}
                >
                  {item.key}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Box className="mt--20">
          {values.budgetType === "TOTAL_BUDGET" && (
            <TextField
              type="number"
              name="budget"
              value={values.budget || 0}
              onChange={({ target: { value } }) => {
                handleChange({
                  target: {
                    name: "budget",
                    value: Number(value),
                  },
                });
              }}
              label="Estimated Budget"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "25ch" }}
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          )}
          {values.budgetType === "PER_HOUR" && (
            <>
              <Box className="mt--10 d-flex justify-content-start align-items-center ">
                <TextField
                  value={values.budget || 0}
                  type="number"
                  label="Per Hour Rate"
                  id="outlined-start-adornment"
                  sx={{
                    m: 1,
                    width: "25ch",
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins", // Apply Poppins font to the label
                    },
                  }}
                  onChange={({ target: { value } }) => {
                    handleChange({
                      target: {
                        name: "budget",
                        value: Number(value),
                      },
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                />
                {/* <TextField
                  defaultValue={0}
                  value={pricePerHour.hours}
                  type="number"
                  label="Estimated Time in hours"
                  id="outlined-start-adornment"
                  sx={{
                    m: 1,
                    width: "20ch",
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins", // Apply Poppins font to the label
                    },
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  onChange={({ target: { value } }) => {
                    let finalValue = pricePerHour.hourRate * value;
                    handleChange({
                      target: {
                        name: "budget",
                        value: finalValue,
                      },
                    });
                    setCalculateBudget(finalValue);
                    setPricePerHour((prevState) => ({
                      ...prevState,
                      hours: value,
                    }));
                  }}
                /> */}
                {/* <Typography>=</Typography> */}
                {/* <TextField
                  value={calculateBudget}
                  type="number"
                  label="Estimated Budget"
                  id="outlined-start-adornment"
                  sx={{
                    m: 1,
                    width: "20ch",
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins", // Apply Poppins font to the label
                    },
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  disabled
                /> */}
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Box className="mt--20">
        <label className="fw-bold">How you want to connect *</label>

        <Box
          className="d-flex mt--10"
          gap={{
            xs: "10px",
            sm: "10px",
            md: "20px",
            lg: "20px",
          }}
        >
          {PREFERRED_CONTACTS.map((item, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  handleChange(
                    {
                      target: {
                        name: "preferredContactMethod",
                        value: item.value,
                      },
                    },
                    { phoneNumber: "" }
                  );
                }}
                className={`cursor-pointer ${
                  values.preferredContactMethod === item.value
                    ? "active-flexible"
                    : "tasker-items"
                } flexible-wrapper`}
              >
                <Typography
                  className="item-title p--0"
                  sx={{
                    fontSize: "15px",
                    fontFamily: "Poppins",
                  }}
                >
                  {item.key}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      {values.preferredContactMethod === "WHATS_APP" && (
        <Box className="mt--20">
          <TextField
            fullWidth
            label="Phone number *"
            name="phoneNumber"
            size="small"
            type="number" // Use "text" to prevent numeric keyboard arrows
            value={values.phoneNumber}
            onChange={(e) => {
              if (e.target.value.length <= 15) {
                handleChange(e);
              }
            }}
            margin="normal"
            variant="outlined"
            inputProps={{
              min: 0,
              maxLength: 15, // Set maximum length to 15
              inputMode: "numeric", // Use numeric mode for mobile keyboards
              pattern: "\\d*", // Ensure only digits can be entered
              style: {
                // Hide the arrows for number input in Webkit browsers
                WebkitAppearance: "none",
                MozAppearance: "textfield",
                appearance: "textfield",
              },
            }}
            InputLabelProps={{
              sx: {
                fontFamily: "Poppins",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                  top: "-5px",
                },
              },
            }}
            InputProps={{
              sx: {
                minHeight: "40px",

                "& input": {
                  "@media (max-width: 600px)": {
                    fontSize: "14px",
                    padding: "10px",
                  },
                  // Hide the arrows for number input in Webkit browsers
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  // Hide the arrows for number input in Firefox
                  "&[type=number]": {
                    MozAppearance: "textfield",
                  },
                },
              },
            }}
          />
        </Box>
      )}
    </>
  );
};
export default ThirdStep;
