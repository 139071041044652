export const GET_LISTING_NOTIFICATIONS_PROCESSING =
  "GET_LISTING_NOTIFICATIONS_PROCESSING";
export const GET_LISTING_NOTIFICATIONS_SUCCESSFUL =
  "GET_LISTING_NOTIFICATIONS_SUCCESSFUL";
export const GET_LISTING_NOTIFICATIONS_STOP_PROCESSING =
  "GET_LISTING_NOTIFICATIONS_STOP_PROCESSING";
export const UPDATE_NOTIFICATION_STATUS = "UPDATE_NOTIFICATION_STATUS";

export const NOTIFICATION_STATUS_CREATED = "CREATED";
export const NOTIFICATION_STATUS_SEEN = "SEEN";
export const NOTIFICATION_STATUS_ACKNOWLEDGED = "ACKNOWLEDGED";
