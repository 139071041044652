import Avatar from "@mui/material/Avatar";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./chat.css";

const UsersList = ({
  selectedUser,
  userList,
  handleUserClick,
  setShowProfile,
}) => {
  const loginReducer = useSelector(
    (state) => state.loginReducer.user.data.userProfile
  );
  const loggedInUser = loginReducer.id;

  return (
    <div>
      <div className="chat-left-sidebar me-lg-1 ms-lg-0">
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="pills-chat"
            role="tabpanel"
            aria-labelledby="pills-chat-tab"
          >
            <div>
              <div className="">
                {/* <h5 className="mb-3 pt-1 font-size-16">Recent</h5> */}

                <div className="chat-message-list px-2 pt-3" data-simplebar>
                  {/* {userList.length <= 0 && (
                    <div className="d-flex justify-content-center align-items-center full-width">
                      <h5 className="font-size-16"> No New Messages</h5>
                    </div>
                  )} */}
                  {userList.length > 0 && (
                    <ul className="list-unstyled chat-list chat-user-list full-width">
                      {userList?.map((user, index) => (
                        <li
                          key={`${user?.chatInitiator?.userProfileId}-${index}`}
                          className={`${
                            selectedUser?.id === user?.id ? "active" : ""
                          } full-width`}
                        >
                          <Link
                            to={`/chat/${user?.id}`}
                            onClick={() => handleUserClick(user)}
                          >
                            <div className="d-flex align-items-center">
                              <div className="chat-user-img online align-self-center me-3 ms-0">
                                <Avatar
                                  src={
                                    loggedInUser ===
                                    user.chatInitiator.userProfileId
                                      ? user?.listingOwner?.userProfileImageUrl
                                      : user?.chatInitiator?.userProfileImageUrl
                                  }
                                  className="rounded-circle avatar-xs cursor-pointer"
                                  onClick={() => setShowProfile(true)}
                                />
                                <span className="user-status"></span>
                              </div>
                              <div className="flex-grow-1 overflow-hidden d-flex justify-content-between align-items-start ">
                                <div className="d-flex justify-content-start align-items-start flex-column">
                                  <h5 className="text-truncate font-size-15 mb-0">
                                    {loggedInUser ===
                                    user.chatInitiator.userProfileId
                                      ? user.listingOwner.userDisplayName
                                      : user?.chatInitiator?.userDisplayName}
                                  </h5>

                                  <h5
                                    className="text-truncate font-size-14 mb-0"
                                    style={{
                                      width: "calc(100% - 20px)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {user?.listingInfo?.title || ""}
                                  </h5>
                                </div>
                                {user.unreadMessageCount !== 0 && (
                                  <span className="rounded-circle p-1 text-white unread-messages-circle d-flex justify-content-center align-items-center text-align-center ">
                                    {user.unreadMessageCount}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              {/* <!-- End chat-message-list --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
