import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import "./faq.css";
import { FAQAccordion } from "../../../../shared/Constants/constants";

const FAQSection = () => {
  const [expanded, setExpanded] = useState(1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Box className="call_section ">
        <Box className="wrapper">
          <Box className="container margin_80_55">
            <Box className="main_title_2" mb={8}>
              {/* <span>
                <em></em>
              </span> */}
              <h2 className="faq-title">Frequently Asked Questions</h2>
              <p className="faq-description text-light mt-1">
              Your Questions Answered: Find quick solutions and understand how 100xHelper helps you connect with verified helpers for your tasks.
              </p>
              <Box mb={3} mt={3}>
                <a href="mailTo:support@100xHelper.de" className="get-in-touch">
                  Get in touch
                </a>
              </Box>
            </Box>
            <Box mt={2}>
              {FAQAccordion.map((item, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                  className="faq-wrapper"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls={`${index}-content"`}
                    id={`${index}-header`}
                    sx={{
                      backgroundColor: "transparent",
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "17px",
                        lg: "18px",
                      },
                      fontWeight: "700",
                    }}
                    className="faq-question-wrapper"
                  >
                    {item.question}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                          lg: "15px",
                        },
                      }}
                      className="faq-answer-wrapper"
                    >
                      {item.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default FAQSection;
