import axios from "axios";
import { AfterAuthApi, ApiCall } from "..";
import {
  CHANGE_PASSWORD,
  EDIT_USER_PROFILE,
  FORGOT_PASSWORD,
  GET_USER_PROFILE,
  PROFILE_MEDIA,
  VERIFY_FORGOT_PASSWORD,
  VERIFY_EMAIL_OTP,
  LOGOUT_USER,
  GOOGLE_AUTH,
} from "../url";

export const forgotPassword = (submitData) => {
  return new Promise((resolve, reject) => {
    ApiCall(FORGOT_PASSWORD, "post", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject({ err, success: false });
      });
  });
};
export const googleAuth = (submitData) => {
  return new Promise((resolve, reject) => {
    ApiCall(GOOGLE_AUTH, "post", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject({ err, success: false });
      });
  });
};

export const verifyEmailOtp = (submitData) => {
  return new Promise((resolve, reject) => {
    ApiCall(VERIFY_EMAIL_OTP, "patch", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject({ err, success: false });
      });
  });
};

export const verifyForgotPassword = (submitData) => {
  return new Promise((resolve, reject) => {
    ApiCall(VERIFY_FORGOT_PASSWORD, "patch", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject({ err, success: false });
      });
  });
};

export const changePassword = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(CHANGE_PASSWORD, "patch", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject({ err, success: false });
      });
  });
};

export const getUserProfile = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_USER_PROFILE, "get")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject({ err, success: false });
      });
  });
};

export const editUserProfile = (id, submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(`${EDIT_USER_PROFILE}/${id}`, "put", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject({ err, success: false });
      });
  });
};

export const profileMediaUpload = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(PROFILE_MEDIA, "post", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const getLocation = (url, submitData) => {
//   return new Promise((resolve, reject) => {
//     ApiCall(url, "get")
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const getLocation = (url, data = null, headers = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${url}`,
      data,
      headers: {
        ...headers,
      },
    })
      .then(resolve)
      .catch(reject);
  });
};

export const logoutUser = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(LOGOUT_USER, "post")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject({ err, success: false });
      });
  });
};
