import ListAltIcon from '@mui/icons-material/ListAlt';
import ChatIcon from "@mui/icons-material/Chat";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import InfoIcon from '@mui/icons-material/Info';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { Avatar, Box, ListItem, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "../Navbar/NewHeader/updated-header.css";
import { CONTACT_US_MENU } from "../Constants/constants";
import { isNull } from "lodash";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import { BECOME_TASKER_STATUS } from "../Constants/enum";
import { VerifiedIcon } from "../Constants/icons";

const SidebarList = ({
  closeSidebar,
  unReadNotification,
  setLogoutOpen,
  handleNotificationClick,
}) => {
  const getAllCategories = useSelector(
    (state) => state?.categoryReducer?.categories
  );
  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );

  const isLoggedIn = useSelector((state) => state?.loginReducer?.isLoggedIn);

  const [home, setHome] = useState(false);
  const [contactUs, setContactUs] = useState(false);
  const [viewCategory, setViewCategory] = useState(false);
  const [extra, setExtra] = useState(false);

  const [categories, setCategories] = useState(getAllCategories);

  const navigate = useNavigate();

  useEffect(() => {
    setCategories(getAllCategories);
  }, [getAllCategories]);

  const handleClick = (val) => {
    if (val === "home") {
      setHome(!home);
      closeSidebar();
      navigate("/");
    } else if (val === "Contact Us") {
      setContactUs(!contactUs);
    } else if (val === "view category") {
      setViewCategory(!viewCategory);
    } else {
      setExtra(!extra);
    }
  };
  const handleCategory = (id) => {
    navigate(`/ads/category/${id}`);
    closeSidebar();
    localStorage.setItem("categoryId", JSON.stringify(id));
  };

  // const trimmedMenu = CONTACT_US_MENU.slice(0, -1);

  return (
    <Box className="position-relative">
      <List
        sx={{
          paddingBottom: isLoggedIn ? "140px" : "0",
        }}
      >
        <ListItem className="sidebar-top-logo">
          <img
            src="/assets/img/LOGO/100xhelper/100xhelper.svg"
            alt="logo"
            className="logo_sticky"
          />
        </ListItem>
        <ListItem>
          <button
            type="button"
            className="post-btn"
            onClick={() => navigate("/add-task")}
          >
            Post Your Task
          </button>
        </ListItem>
        <ListItemButton onClick={() => handleClick("home")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>
            <Link to="/">Home</Link>
          </ListItemText>
        </ListItemButton>

        <ListItemButton onClick={() => handleClick("view category")}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Browse Tasks" />
          {viewCategory ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={viewCategory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => handleCategory("all")}
            >
              <ListItemText>
                <Link
                  to={`/ads/category/all`}
                  onClick={() =>
                    localStorage.setItem("categoryId", JSON.stringify(""))
                  }
                >
                  Browse all tasks
                </Link>
              </ListItemText>
            </ListItemButton>
            {categories?.length
              ? categories?.map((obj, index) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    key={index}
                    onClick={() => handleCategory(obj.id)}
                  >
                    <ListItemText>
                      <Link
                        to={`/ads/category/${obj.id}`}
                        onClick={() =>
                          localStorage.setItem(
                            "categoryId",
                            JSON.stringify(obj.id)
                          )
                        }
                      >
                        {obj.categoryName}
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                ))
              : ""}
          </List>
        </Collapse>

        {/* <ListItemButton onClick={() => handleClick("Contact Us")}>
          <ListItemIcon>
            <PermContactCalendarIcon />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
          {contactUs ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton> */}

        {/* <Collapse in={contactUs} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {trimmedMenu.map((item, index) => (
              <ListItemButton
                sx={{ pl: 4 }}
                key={index}
                onClick={() => {
                  navigate(item.route);
                  closeSidebar();
                }}
              >
                <ListItemText>
                  <Link to={item.route}>{item.key}</Link>
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Collapse> */}
        <ListItemButton onClick={() => navigate("/blogs")}>
          <ListItemIcon>
            <IntegrationInstructionsIcon />
          </ListItemIcon>
          <ListItemText>
            <Link to="/">Blog</Link>
          </ListItemText>
        </ListItemButton>
        {isLoggedIn && (
          <ListItemButton onClick={() => navigate("/chat")}>
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/">Chat</Link>
            </ListItemText>
          </ListItemButton>
        )}
        {isLoggedIn && (
          <ListItemButton onClick={handleNotificationClick}>
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText>
              <Box className="d-flex justify-content-between align-items-center">
                Notifications
                {!isNull(unReadNotification) && unReadNotification !== 0 && (
                  <span className="rounded-circle p-1 text-white  notification-tooltip d-flex justify-content-center align-items-center text-align-center">
                    {unReadNotification || 5}
                  </span>
                )}
              </Box>
            </ListItemText>
          </ListItemButton>
        )}
        <ListItemButton onClick={() => navigate("/contact-us")}>
          <ListItemIcon>
            <PermContactCalendarIcon />
          </ListItemIcon>
          <ListItemText>
            <Link to="/">Contact Us</Link>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/about-us")}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>
            <Link to="/">About Us</Link>
          </ListItemText>
        </ListItemButton>
      </List>
      {isLoggedIn && profileInfo && (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <List
            className=" position-fixed bg-white"
            sx={{
              width: "350px",
              bottom: "0",
            }}
          >
            <ListItem>
              <div>
                <Avatar src={profileInfo?.mediaUrl} />
              </div>
              <div className="d-flex justify-content-center flex-column ml-2">
                <span className="overflow-container">
                  {profileInfo?.fullname}
                  {profileInfo?.helperApplicationStatus ===
                    BECOME_TASKER_STATUS.CONCLUDED && (
                    <span className="ml--5">
                      <VerifiedIcon size="20px" />
                    </span>
                  )}
                </span>
                <Typography variant="caption">{profileInfo?.email}</Typography>
              </div>
            </ListItem>
            <ListItem>
              <button
                type="button"
                className="post-btn"
                onClick={() => {
                  setLogoutOpen(true);
                  closeSidebar();
                }}
              >
                Logout
              </button>
            </ListItem>
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SidebarList;
