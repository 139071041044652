import React, { useEffect, useState } from "react";
import { Avatar, Box, Button } from "@mui/material";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editUserProfile, profileMediaUpload } from "../../../helper/APIs/Auth";
import * as loginAction from "../../../redux/Auth/action";
import Footer from "../../../shared/Footer";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import CardWrapper from "../Listing/AddListing/CardWrapper.js";
import { VerifiedIcon } from "../../../shared/Constants/icons.js";
import MoreInfoInputIcon from "../../../shared/InputFieldsWithLabels/MoreInfoInputIcon.js";
import { isEmpty } from "lodash";
import "../Profile/profile.css";

const Profile = ({ profileData }) => {
  const updateLoginData = useSelector((state) => state.loginReducer);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState(profileData);
  const [formValues, setFormValues] = useState({
    fullName: profileInfo?.fullname,
  });

  useEffect(() => {
    setProfileInfo(profileData);
  }, [profileData]);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const [imagesUrl, setImagesUrl] = useState(null);

  const handleImageDrop = (acceptedFiles) => {
    setLoading(true);
    if (acceptedFiles.length > 1) {
      toast.error("Please select only one file.", {
        toastId: "inputFileError",
      });
      setLoading(true);
    } else {
      let allFilesWithinLimit = true;
      if (allFilesWithinLimit) {
        const updatedFiles = acceptedFiles.filter((file) => {
          if (file.size <= 5 * 1024 * 1024) {
            return true;
          } else {
            toast.error("Media URL length cannot be higher than 5MB");
            setLoading(false);
            allFilesWithinLimit = false;
            return false;
          }
        }); // Limit to 2048 KB (2 MB)

        const formData = new FormData();
        formData.append("file", updatedFiles[0]);
        profileMediaUpload(formData)
          .then((res) => {
            if (res.success) {
              setImagesUrl({
                mediaUrl: res.data.mediaUrl,
              });
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            setImagesUrl([]);
          });
      }
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (formValues.fullName.trim() !== "") {
      const submitData = {
        id: profileInfo.id,
        fullname: formValues.fullName,
        email: profileInfo.email,
        emailVerified: false,
        phoneVerified: false,
        addressVisibility: "REGISTERED_USERS",
        phoneVisibility: "REGISTERED_USERS",
        emailVisibility: "REGISTERED_USERS",
        userConsents: profileInfo.userConsents,
        mediaUrl: imagesUrl
          ? imagesUrl.mediaUrl
          : profileInfo?.mediaUrl
          ? profileInfo?.mediaUrl
          : "",
      };
      setSubmitLoading(true);
      editUserProfile(profileInfo.id, submitData)
        .then((res) => {
          if (res.success) {
            const data = {
              ...updateLoginData,
              ...updateLoginData.user,
              data: {
                ...updateLoginData.user.data,
                userProfile: res.data,
              },
            };
            setIsDirty(true);
            setSubmitLoading(false);
            dispatch(loginAction.setLoginUser(data, "user"));
            toast.success("Profile updated successfully.");
          }
        })
        .catch((err) => {
          setSubmitLoading(false);
          if (err?.err?.response?.status === 400) {
            toast.error(err?.err?.response?.data?.errors?.toString());
          }
        });
    } else {
      toast.error("Enter your full name!");
    }
  };
  return (
    <div id="page" className="theia-exception">
      <PagesHeader />
      <main>
        {profileData && (
          <div className="margin_60_35">
            <div className="row">
              <div className="col-lg-12">
                {/* <CardWrapper> */}
                  <div className="container d-flex justify-content-between align-items-center profile-wrapper row">
                    <div className="col-12 col-md-6 pt-1 d-flex align-items-center profile-avatar-wrapper">
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <Avatar
                          alt="profile"
                          src={
                            imagesUrl
                              ? imagesUrl.mediaUrl
                              : profileInfo?.mediaUrl
                          }
                          sx={{ width: "5rem", height: "5rem" }}
                          className="profile-avatar"
                        />
                      )}
                      <Dropzone
                        onDrop={(e) => {
                          handleImageDrop(e);
                          setIsDirty(false);
                        }}
                        accept="image/png"
                        disabled={loading || profileInfo?.isUserActiveHelper}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="d-flex align-items-center justify-content-between mx-4"
                            >
                              <input
                                {...getInputProps({
                                  accept: "image/jpeg, image/png, image/gif",
                                  multiple: false,
                                  id: "fileInput", // Add an id to the input element
                                })}
                              />

                              <Box
                                className="d-flex align-items-center"
                                gap={1}
                              >
                                <Button
                                  variant="outlined"
                                  component="label"
                                  disabled={
                                    loading || profileInfo.isUserActiveHelper
                                  }
                                  className="profile-update-btn"
                                  // Call the function to trigger file input click
                                >
                                  Update Profile Picture
                                  {loading && (
                                    <span
                                      className="spinner-border spinner-border-sm ms-1"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  )}
                                </Button>
                                {/* <MoreInfoInputIcon
                                  label={
                                    profileInfo.isUserActiveHelper
                                      ? "You became a helper."
                                      : "You are not a helper."
                                  }
                                /> */}
                              </Box>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div className="col-12 col-md-6">
                      <p>
                        <b>Tip</b> : Your Profile, Your First Impression. Keep it up-to-date to get the best offers from our helpers.
                      </p>
                    </div>
                    <div className="col-12 c0l-sm-12 col-md-6 col-lg-6 pt-1">
                      <form
                        className={`form-group ${
                          profileInfo?.fullname ? "was-validated" : ""
                        }`}
                        onSubmit={handleSave}
                      >
                        <label
                          className={"fw-bold"}
                          htmlFor={profileInfo?.fullname ? "fullname" : ""}
                        >
                          {"Fullname"}
                        </label>

                        <input
                          className="form-control"
                          label="Fullname"
                          type="text"
                          id="fullname"
                          name={"fullName"}
                          labelClass="fw-bold"
                          placeholder="fullname"
                          defaultValue={profileInfo?.fullname}
                          onChange={(e) => {
                            handleChange(e);
                            setIsDirty(false);
                          }}
                          disabled={profileInfo?.isUserActiveHelper}
                        />
                      </form>
                    </div>

                    <div className="col-12 c0l-sm-12 col-md-6 col-lg-6 pt-1">
                      <form
                        className={`form-group ${
                          profileInfo?.email ? "was-validated" : ""
                        }`}
                      >
                        <label
                          className={"fw-bold"}
                          htmlFor={profileInfo?.email ? "email" : ""}
                        >
                          E-mail
                        </label>

                        <input
                          className="form-control"
                          label="E-mail"
                          type="text"
                          id="email"
                          name={"email"}
                          labelClass="fw-bold"
                          placeholder="email"
                          disabled={true}
                          value={profileInfo?.email}
                        />
                        {/* <i className={iconClass}></i> */}
                      </form>
                    </div>

                    <Box mt={1}>
                      <label className="fw-bold m-0">
                        Email Verified : &nbsp;
                      </label>
                      {profileInfo?.emailVerified ? (
                        <VerifiedIcon size="18" />
                      ) : (
                        "No"
                      )}
                    </Box>

                    <Box mt={1}>
                      <label className="fw-bold m-0">
                        Active Helper : &nbsp;
                      </label>
                      {profileInfo?.helperApplicationStatus === "CONCLUDED"
                        ? "YES"
                        : profileInfo?.helperApplicationStatus}
                    </Box>

                    {profileInfo?.aboutMe && (
                      <Box mt={1}>
                        <label className="fw-bold m-0">About Me : &nbsp;</label>
                        {profileInfo?.aboutMe}
                      </Box>
                    )}

                    {profileInfo?.dateOfBirth && (
                      <Box mt={1}>
                        <label className="fw-bold m-0">
                          Date of Birth : &nbsp;
                        </label>
                        {profileInfo?.dateOfBirth}
                      </Box>
                    )}

                    {profileInfo?.address && (
                      <Box mt={1}>
                        <label className="fw-bold m-0">Address : &nbsp;</label>
                        {profileInfo?.address}
                      </Box>
                    )}

                    {profileInfo?.phone && (
                      <Box mt={1}>
                        <label className="fw-bold m-0">
                          Phone number : &nbsp;
                        </label>
                        {profileInfo?.phone}
                      </Box>
                    )}

                    {profileInfo?.phone && (
                      <Box mt={1}>
                        <label className="fw-bold m-0">
                          Phone Number Verified : &nbsp;
                        </label>
                        {profileInfo?.phoneVerified ? (
                          <VerifiedIcon size="18" />
                        ) : (
                          "No"
                        )}
                      </Box>
                    )}

                    <Box mt={1}>
                      <label className="fw-bold m-0">
                        ID Verified : &nbsp;
                      </label>
                      {profileInfo?.idVerified ? (
                        <VerifiedIcon size="18" />
                      ) : (
                        "No"
                      )}
                    </Box>

                    {!isEmpty(profileInfo.languages) && (
                      <Box mt={1}>
                        <label className="fw-bold m-0">
                          Languages : &nbsp;
                        </label>
                        {profileInfo.languages.toString()}
                      </Box>
                    )}
                    {!isEmpty(profileInfo.skills) && (
                      <Box mt={1}>
                        <label className="fw-bold m-0">Skills : &nbsp;</label>
                        {profileInfo.skills.toString()}
                      </Box>
                    )}
                    <div className="col-12 d-flex justify-content-center mt-3">
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={handleSave}
                        disabled={submitLoading || isDirty}
                        sx={{
                          backgroundColor: "#283350",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#283350",
                          },
                        }}
                      >
                        Save
                        {submitLoading && (
                          <span
                            className="spinner-border spinner-border-sm ms-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </Button>
                    </div>
                  </div>
                {/* </CardWrapper> */}
              </div>
            </div>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Profile;
