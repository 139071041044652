import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

const BecomeHelperNotifyPopup = ({ isOpen, handleClose, onSubmit }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        className="text--left fw-bold"
        sx={{ fontSize: { xs: "18px", md: "22px" } }}
      >
        {"Become a Helper now!"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="position-absolute"
        sx={{ right: 8, top: 10 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box>
          <Typography>
            Unlock the Benefits of Being Your Own Boss:
          </Typography>
        </Box>
        <Box className="d-flex mt--20">
          <CheckIcon />
          <Typography>
            Earn on Your Terms
          </Typography>
        </Box>
        <Box className="d-flex">
          <CheckIcon />
          <Typography>
            Flexibility at Its Best
          </Typography>
        </Box>
        <Box className="d-flex">
          <CheckIcon />
          <Typography>
            Sharpen Your Skills
          </Typography>
        </Box>
        <Box className="d-flex">
          <CheckIcon />
          <Typography>
            Build Trust and Reputation
          </Typography>
        </Box>
        <Box className="d-flex">
          <CheckIcon />
          <Typography>
            Connect Directly
          </Typography>
        </Box>
        <Box className="mt--20">
          <Button
            onClick={onSubmit}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: "#283350",
              "&:hover": {
                backgroundColor: "#ffc107",
                color: "#283350",
              },
            }}
          >
            Become a Helper
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default BecomeHelperNotifyPopup;
