import React, { useEffect, useRef } from "react";

const ContactMap = () => {
  const mapContainerRef = useRef(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const markerCoordinates = {
    lat: 52.5102738,
    lng: 13.2230528,
  };

  useEffect(() => {
    // Initialize Google Maps
    if (window.google) {
      const map = new window.google.maps.Map(mapContainerRef.current, {
        center: markerCoordinates,
        zoom: 11,
      });

      // Add a marker
      new window.google.maps.Marker({
        position: markerCoordinates,
        map: map,
        title: "Angerburger Allee 53",
      });
    }
  }, [markerCoordinates]);

  return (
    <div id="row mt-5 map_contact">
      <div className="col-12">
        <div
          ref={mapContainerRef}
          style={{ height: "50vh", width: "100%" }}
        ></div>
      </div>
    </div>
  );
};

export default ContactMap;
