import React from "react";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import Footer from "../../../shared/Footer";
import { Link } from "react-router-dom";

const Policy = () => {
  return (
    <div id="page">
      <PagesHeader />
      <div className="sub_header_in sticky_header">
        <div className="container p-2">
          <h1 className="wow bounceIn">Privacy Policy</h1>
        </div>
      </div>
      <main className="container">
        <h6 className="mb-5">
          <strong>
            Please also refer to our{" "}
            <Link to="/terms">Terms and Conditions</Link> and{" "}
            <Link to="/copyright">Copyright Statement</Link>
          </strong>
        </h6>
        <div className="">
          <p>
            This Privacy Policy describes how <strong>www.100xHelper.de</strong>{" "}
            (“Website”) collects, uses, and shares information about you
            (“User”) that we obtain through your use of the Website. By using
            the Website, you consent to the processing of your personal data in
            accordance with this Privacy Policy.
          </p>
          <h6 className="">
            <strong>Data We Collect:</strong>
          </h6>
          <p>
            We collect data from Users who submit information through
            the contact form on our Website, including name, email address, and
            any other information provided by the User. We also collect usage
            data automatically when Users visit our Website, such as IP
            addresses, browser type, referring/exit pages, and other similar
            information.
          </p>
          <h6 className="">
            <strong>Cookies:</strong>
          </h6>
          <p>
            We use cookies and similar technologies to collect usage data and
            enhance your experience on our Website. You can manage your cookie
            preferences in your browser settings.
          </p>
          <h6 className="">
            <strong>How We Use Your Data:</strong>
          </h6>
          <p>
            We use the personal data we collect to respond to User inquiries,
            improve our Website and services, and comply with legal obligations.
            We will not share your personal data with third parties for
            marketing purposes.
          </p>
          <h6 className="">
            <strong>Handling of Google User Data:</strong>
          </h6>
          <p>
          Regarding data received from Google services, our application strictly adheres to the storage and privacy limitations imposed by Google. We only use this data to enhance app functionality, and do not use it for independent data mining or advertising purposes. Our use of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements.
          </p>
          <h6 className="">
            <strong>Data Retention:</strong>
          </h6>
          <p>
            We will retain your personal data for as long as necessary to
            fulfill the purposes for which it was collected, or as required by
            law.
          </p>
          <h6 className="">
            <strong>User Rights:</strong>
          </h6>
          <p>
            Users have the right to request access to and correction or deletion
            of their personal data. Users also have the right to restrict or
            object to processing of their personal data, and the right to data
            portability. To exercise these rights, please contact us using the
            contact information provided below.
          </p>
          <h6 className="">
            <strong>Contact Us:</strong>
          </h6>
          <p>
            If you have any questions or concerns about this Privacy Policy, or
            if you wish to exercise your rights regarding your personal data,
            please contact us at{" "}
            <a href="mailto:support@100xHelper.de">support@100xHelper.de</a>.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Policy;
