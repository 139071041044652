import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { GOOGLE_CLIENT_ID } from "../../../config";
import { forgotPassword, googleAuth } from "../../../helper/APIs/Auth";
import * as loginAction from "../../../redux/Auth/action";
import InputFields from "../../../shared/InputFieldsWithLabels";
import PasswordFields from "../../../shared/InputFieldsWithLabels/PasswordFields";

const Login = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formValues, setFormValues] = useState({
    email: "",
    pwd: "",
  });

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const handleChange = (e) => {
    setError("");
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formValues.email) {
      setError("Please enter email.");
      setLoading(false);
      return;
    }

    if (!formValues.pwd) {
      setError("Please enter password.");
      setLoading(false);
      return;
    }

    try {
      const res = await dispatch(loginAction.loginUser(formValues, "user"));
      if (res.status === 200) {
        navigate("/");
        setLoading(false);
        return;
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setError(err.response.data.errors.toString());
      } else if (err?.response?.status === 403) {
        try {
          const submitData = {
            email: formValues.email,
            otpType: "EMAIL_VERIFICATION_OTP",
          };
          const res = await forgotPassword(submitData);
          if (res.success) {
            setLoading(false);
            navigate("/verify-otp", {
              state: {
                email: formValues.email,
                pwd: formValues.pwd,
                isLogin: true,
              },
            });
            return;
          }
        } catch (err) {
          if (err?.response?.status === 403) {
            setError("Please check entered email.");
          }
        }
      } else {
        setError("Enter required parameters.");
      }
    }

    setLoading(false);
  };

  const responseGoogle = (response) => {
    if (response?.tokenObj?.id_token) {
      const submitData = {
        idToken: response.tokenObj.id_token,
      };
      googleAuth(submitData).then((res) => {
        if (res.data.jwtToken) {
          dispatch(loginAction.setLoginUser(res, "user"));
          navigate("/");
          toast.success("Login Successfully.");
        }
      });
    }
  };

  return (
    <div id="login_bg">
      <div id="login">
        <aside>
          <figure>
            <Link to="/">
              <img
                src="assets/img/LOGO/100xhelper/100xhelper.svg"
                width="100"
                height="40"
                alt=""
                className="logo_sticky object-fit-cover"
              />
            </Link>
          </figure>

          <form className="" onSubmit={handleSubmit}>
            <div className="access_social">
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                icon={true}
                theme="dark"
                onSuccess={responseGoogle}
                onFailure={() => toast.error("User not exists.")}
                cookiePolicy={"single_host_origin"}
                className="social_bt"
              />
            </div>

            <div className="divider">
              <span>Or</span>
            </div>
            <InputFields
              label="Email"
              type="email"
              id="email"
              iconClass="fa fa-envelope"
              name="email"
              placeholder="Enter Email"
              onChange={handleChange}
              required={true}
              defaultValue={formValues.email}
            />
            <PasswordFields
              label="Password"
              type="password"
              id="password"
              iconClass="fa fa-lock"
              name="pwd"
              placeholder="Enter Password"
              onChange={handleChange}
              defaultValue={formValues.pwd}
              required={false}
            />

            <div className="clearfix add_bottom_30">
              <div className="float-end mt-1">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </div>
            <div
              id="pass-info"
              className="clearfix"
              style={{ color: "red", fontSize: "1rem", fontWeight: 600 }}
            >
              {error}
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn_1 rounded full-width"
            >
              Login Now
              {loading && (
                <span
                  className="spinner-border spinner-border-sm mx-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
            <div className="text-center add_top_10">
              New to 100xHelper?{" "}
              <strong>
                <Link to="/register">Register Now!</Link>
              </strong>
            </div>

            <div className="text-center add_top_10">
              Continue without login?{" "}
              <strong>
                <Link to="/">Home</Link>
              </strong>
            </div>
          </form>
          <div className="copy">
            Copyright © {new Date().getFullYear()}&nbsp; 100xHelper <br />
            All Rights Reserved
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Login;
