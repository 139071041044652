import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateNotificationStatus } from "../../helper/APIs/USER/Notification";
import * as listingLocationAction from "../../redux/ListingLocation/action";
import * as loginLocationActions from "../../redux/LoginLocation/actions";
import * as notificationActions from "../../redux/Notifications/actions";
import {
  NOTIFICATION_STATUS_CREATED,
  NOTIFICATION_STATUS_SEEN,
} from "../../redux/Notifications/types";
import UpdatedHeader from "./NewHeader/updatedHeader";

const PagesHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state?.loginReducer?.isLoggedIn);
  const getAllCategories = useSelector(
    (state) => state?.categoryReducer?.categories
  );
  const getAllNotifications = useSelector(
    (state) => state?.notificationReducer?.notifications
  );

  const [logoutOpen, setLogoutOpen] = React.useState(false);
  const [unReadNotification, setUnReadNotification] = useState(0);
  const [categories, setCategories] = useState(getAllCategories);
  const [state, setState] = React.useState({
    left: false,
    right: false,
  });

  useEffect(() => {
    setCategories(getAllCategories);
  }, [getAllCategories]);

  const toggleDrawer = (anchor, open) => async (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    if (!open) {
      if (getAllNotifications && getAllNotifications.length <= 0) return;

      const unReadNotifications = getAllNotifications.filter(
        (e) => e.status === NOTIFICATION_STATUS_CREATED
      );

      const unReadNotificationsIds = unReadNotifications.map((e) => e.id);
      if (unReadNotifications.length > 0 || state.right) {
        const res = await updateNotificationStatus(
          NOTIFICATION_STATUS_SEEN,
          unReadNotificationsIds
        );

        if (res.success) {
          const tempData = [...getAllNotifications];
          tempData.map((e) => {
            if (e.status === NOTIFICATION_STATUS_CREATED) {
              return {
                ...e,
                status: NOTIFICATION_STATUS_SEEN,
              };
            }
            return e;
          });
          dispatch(notificationActions.updateNotifications([...tempData]));
        }
      }
    }
  };

  const handleNotificationClick = () => {
    setState({ left: false, right: true });
  };
  const handleCategory = (id) => {
    navigate(`/ads/category/${id}`);
    localStorage.setItem("categoryId", JSON.stringify(id));
    dispatch(listingLocationAction.setListingValues(null, id, null, null));
  };

  const handleLoginRegister = () => {
    dispatch(loginLocationActions.updateLoginPopup(true));

    dispatch(loginLocationActions.updatePreviousRoute("/"));
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(notificationActions.getNotificationsList());
      const intervalId = setInterval(() => {
        dispatch(notificationActions.getNotificationsList());
      }, 20000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      const unReadNotifications = getAllNotifications.filter(
        (e) => e.status === NOTIFICATION_STATUS_CREATED
      );
      setUnReadNotification(unReadNotifications.length);
    }
  }, [getAllNotifications, isLoggedIn]);

  return (
    <UpdatedHeader
      categories={categories}
      openNotificationDrawer={toggleDrawer("right", true)}
      closeNotificationDrawer={toggleDrawer("right", false)}
      handleNotificationClick={handleNotificationClick}
      openSidebar={toggleDrawer("left", true)}
      closeSidebar={toggleDrawer("left", false)}
      unReadNotification={unReadNotification}
      state={state}
      isLoggedIn={isLoggedIn}
      setLogoutOpen={setLogoutOpen}
      handleCategory={handleCategory}
      logoutOpen={logoutOpen}
      handleLoginRegister={handleLoginRegister}
    />
  );
};

export default PagesHeader;
