import { AfterAuthApi } from "../..";

export const submitTakserForm = (id, submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(`users/${id}/helper`, "put", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
