import KeyIcon from "@mui/icons-material/Key";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AssignmentIcon from '@mui/icons-material/Assignment';
import Logout from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { VerifiedIcon } from "../Constants/icons";
import { BECOME_TASKER_STATUS } from "../Constants/enum";

const ProfileBtn = ({ setLogoutOpen }) => {
  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ position: "relative" }}>
      {profileInfo?.mediaUrl ? (
        <Box
          sx={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            onClick={handleClick}
            onMouseOver={handleClick}
            src={profileInfo.mediaUrl}
            alt="profile"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
      ) : (
        <PersonOutlineIcon
          onClick={handleClick}
          onMouseOver={handleClick}
          sx={{ fontSize: "35px", cursor: "pointer" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        />
      )}
      <>
        {profileInfo?.helperApplicationStatus ===
          BECOME_TASKER_STATUS.CONCLUDED && (
          <Box
            title="You became a helper"
            sx={{
              position: "absolute",
              top: "-9px",
              right: "-10px",
            }}
          >
            <VerifiedIcon size="20px" />
          </Box>
        )}
      </>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={() => null}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            // width: "20rem",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 45,
              height: 45,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 25,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="menu-content" onMouseLeave={handleClose}>
          <Link to="/my-profile" className="d-flex text-dark">
            <MenuItem
              onClick={handleClose}
              title={
                profileInfo?.helperApplicationStatus ===
                BECOME_TASKER_STATUS.CONCLUDED
                  ? "You became a helper"
                  : ""
              }
            >
              <div>
                <Avatar src={profileInfo?.mediaUrl} />
              </div>
              <div className="d-flex justify-content-center flex-column ">
                <span className="overflow-container fw-bold">
                  {profileInfo?.fullname}
                  {profileInfo?.helperApplicationStatus ===
                    BECOME_TASKER_STATUS.CONCLUDED && (
                    <span className="ml--5">
                      <VerifiedIcon size="20px" />
                    </span>
                  )}
                </span>
                <Typography variant="caption">{profileInfo?.email}</Typography>
              </div>
            </MenuItem>
          </Link>

          <Divider />

          <Link to="/change-password" className="text-dark">
            <MenuItem>
              <ListItemIcon>
                <KeyIcon fontSize="small" />
              </ListItemIcon>
              Change Password
            </MenuItem>
          </Link>
          <Link to="/my-ads" className="text-dark">
            <MenuItem>
              <ListItemIcon>
                <AssignmentIcon fontSize="small" />
              </ListItemIcon>
              My Posts
            </MenuItem>
          </Link>
          {/* <Link to="/chat" className="text-dark">
            <MenuItem>
              <ListItemIcon>
                <ChatIcon fontSize="small" />
              </ListItemIcon>
              Messages
            </MenuItem>
          </Link> */}
          <MenuItem onClick={() => setLogoutOpen(true)}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </div>
      </Menu>
    </Box>
  );
};

export default ProfileBtn;
