import React from "react";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import Footer from "../../../shared/Footer";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <div id="page">
      <PagesHeader />
      <div className="sub_header_in sticky_header">
        <div className="container p-2">
          <h1 className="wow bounceIn">100xHelper Terms and Conditions</h1>
        </div>
      </div>
      <main className="container">
        <h6 className="mb-5">
          <strong>
            Please also refer to our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
            <Link to="/copyright">Copyright Statement</Link>
          </strong>
        </h6>
        <div className="">
          <h6 className=" ">
            <strong>1. Introduction:</strong>
          </h6>
          <p>
            Welcome to 100xHelper! By using our platform, you agree to these Terms and Conditions. These rules govern your access to and use of our services, connecting users and helpers for various tasks.
          </p>
          <h6 className=" ">
            <strong>2. Definitions:</strong>
          </h6>
          <p>
              2.1 <b>"User":</b> Any person who registers on the 100xHelper platform, whether posting a task (as a "Client") or offering services (as a "Helper").
          </p>
          <p>
              2.2 <b>"Task":</b> The work or service a Client needs assistance with, posted on the 100xHelper platform.
          </p>
          <p>
              2.3 <b>"Agreement":</b> The binding contract between the Client and Helper formed upon mutual acceptance of a task offer, not involving 100xHelper as a contractual party.
          </p>
          <h6 className=" ">
            <strong>3. Registration and Account Security:</strong>
          </h6>
          <p>
              3.1 <b>Eligibility:</b> Users must be of legal age and legally capable of entering into contracts.
          </p>
          <p>
              3.2 <b>Account Creation:</b> Users must provide accurate and complete information during registration. Verification via video call is required to ensure the helper's authenticity (only for helpers).
          </p>
          <p>
              3.3 <b>Account Security":</b>  Users are responsible for maintaining the confidentiality of their account details and must notify 100xHelper immediately of any unauthorized use.
          </p>
          <h6 className=" ">
            <strong>4. Role of 100xHelper:</strong>
          </h6>
          <p>
              4.1 <b>Platform Provider:</b> 100xHelper provides a digital space where Clients and Helpers can connect directly. We do not partake in the contractual agreements or transactions between users.
          </p>
          <p>
              4.2 <b>No Mediation or Warranty:</b> 100xHelper does not mediate, oversee, or guarantee the quality, legality, or completion of tasks posted or accepted on the platform.
          </p>
          <h6 className=" ">
            <strong>5. User Responsibilities:</strong>
          </h6>
          <p>
              5.1 <b>Client Responsibilities:</b> Clients must clearly describe tasks, including any special requirements, deadlines, and compensation details.
          </p>
          <p>
              5.2 <b>Helper Responsibilities:</b> Helpers must ensure they possess the necessary skills and qualifications for the tasks they accept and perform them professionally.
          </p>
          <p>
              5.2 <b>Compliance with Laws:</b> All users must comply with applicable laws, including tax, labor, and contractual obligations.
          </p>
          <h6 className=" ">
            <strong>6. Payments and Fees:</strong>
          </h6>
          <p>
              6.1 <b>Direct Payments:</b> Clients and Helpers negotiate and manage payments directly. 100xHelper does not handle funds or charge brokerage fees.
          </p>
          <p>
              6.2 <b>Refunds and Cancellations:</b> All refund and cancellation policies must be directly negotiated and agreed upon by the Client and Helper.
          </p>
          <h6 className=" ">
            <strong>7. Content and Conduct:</strong>
          </h6>
          <p>
              7.1 <b>User Content:</b> Users are responsible for the accuracy and legality of the content they post. 100xHelper reserves the right to remove any content that violates these T&C or is deemed inappropriate.
          </p>
          <p>
              7.2 <b>Prohibited Conduct:</b> Users may not post harmful, unlawful, or offensive content, solicit illegal activities, or engage in fraud.
          </p>
          <h6 className=" ">
            <strong>8. Liability</strong>
          </h6>
          <p>
              8.1 <b>User Responsibility:</b> 100xHelper is not liable for any disputes, damages, or losses arising from the interactions or agreements between Clients and Helpers.
          </p>
          <p>
              8.2 <b>Limitation of Liability:</b> 100xHelper's liability is limited to the fullest extent permitted by law, excluding cases of gross negligence or intentional misconduct.
          </p>
          <h6 className=" ">
            <strong>9. Changes to Terms and Conditions:</strong>
          </h6>
          <p>
              9.1 <b>Amendments:</b> 100xHelper reserves the right to modify these T&C at any time. Users will be notified of changes via email or through the platform.
          </p>
          <p>
              9.2 <b>Continued Use:</b> Continued use of the platform following any changes constitutes acceptance of the new terms.
          </p>
          <h6 className=" ">
            <strong>10. Termination:</strong>
          </h6>
          <p>
              10.1 <b>User Termination:</b> 100xHelper may terminate or suspend access to the platform for users violating these T&C or applicable laws.
          </p>
          <p>
              10.2 <b>Platform Termination:</b> 100xHelper may terminate or suspend access to the platform for users violating these T&C or applicable laws.
          </p>
          <h6 className=" ">
            <strong>11. Governing Law and Dispute Resolution:</strong>
          </h6>
          <p>
              11.1 <b>Governing Law:</b> These T&C are governed by the laws of the country where 100xHelper is registered - Germany (Deutschland).
          </p>
          <p>
              11.2 <b>Dispute Resolution:</b> Any disputes arising under these T&C shall be resolved through amicable negotiation or, if necessary, through the courts of the appropriate jurisdiction.
          </p>  
          <h6 className=" ">
            <strong>12. Final Provisions:</strong>
          </h6>
          <p>
              12.1 <b>Severability:</b> If any provision of these T&C is found invalid, the remaining provisions will remain in full force and effect.
          </p>
          <p>
              12.2 <b>Entire Agreement::</b> These T&C constitute the entire agreement between 100xHelper and its users regarding the use of the platform.
          </p>  
          <h6 className=" ">
            <strong>CONTACT US:</strong>
          </h6>
          <p>
          For any questions or concerns regarding these T&C, please contact our support team at{" "}
            <a href="mailto:support@100xhelper.de">support@100xhelper.de</a>.
          </p>
          <h6 className=" ">
            <strong>Thank you for choosing 100xHelper!</strong>
          </h6>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Terms;
