import React, { useState } from "react";

const PasswordFields = ({
  label,
  type,
  id,
  iconClass,
  placeholder,
  name,
  onChange,
  required,
  defaultValue,
}) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={`form-group position-relative ${
        defaultValue ? "was-validated" : ""
      }`}
    >
      <label htmlFor={defaultValue ? id : ""}>{label}</label>

      <input
        className="form-control hideShowPassword-field"
        type={show ? "text" : type}
        id={id}
        // id={"validationTextarea"}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
      <button
        type="button"
        role="button"
        aria-label="Show Password"
        title="Show Password"
        tabIndex="0"
        className="my-toggle hideShowPassword-toggle-show"
        style={{
          position: "absolute",
          right: "0px",
          top: "50%",
          margiTop: "-15px",
        }}
        onClick={() => setShow(!show)}
      >
        {show ? "Hide" : "Show"}
      </button>
      <i className={iconClass}></i>
    </div>
  );
};

export default PasswordFields;
