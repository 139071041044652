import { AfterAuthApi } from "../..";
import { GET_NOTIFICATIONS, UPDATE_NOTIFICATION_STATUS } from "../../url";

export const getNotifications = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_NOTIFICATIONS, "get")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch(() => {});
  });
};

export const updateNotificationStatus = (status, params) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(
      `${UPDATE_NOTIFICATION_STATUS}?newStatus=${status}`,
      "patch",
      params
    )
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
