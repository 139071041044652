import {
  SINGLE_LISTING_DATA,
  EDIT_LISTING_DATA,
  REMOVE_EDIT_LISTING,
  LISTING_LIST_DATA,
} from "./type";

export const setListingDetail = (payload) => {
  return {
    type: SINGLE_LISTING_DATA,
    payload,
  };
};

export const setEditListing = (payload) => {
  return {
    type: EDIT_LISTING_DATA,
    payload,
  };
};

export const setRemoveEditListing = (payload) => {
  return {
    type: REMOVE_EDIT_LISTING,
    payload,
  };
};
export const setListingListData = (payload, pageAble) => {
  return {
    type: LISTING_LIST_DATA,
    payload: { payload, pageAble: pageAble },
  };
};
