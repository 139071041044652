import React from "react";
import MoreInfoInputIcon from "../../../../shared/InputFieldsWithLabels/MoreInfoInputIcon.js";
import CardWrapper from "./CardWrapper.js";
import ListingTypeRadio from "./ListingType/ListingTypeRadio.js";

const CountryMultiSelect = ({
  setListingType,
  listingType,
  setTimeSlots,
  params,
}) => {
  return (
    <div style={{ paddingTop: "30px" }}>
      <CardWrapper>
        <label className="fw-bold">Ad Type *</label>
        <MoreInfoInputIcon label={"Ad Type *"} />
        <hr />
        <ListingTypeRadio
          setListingType={setListingType}
          listingType={listingType}
          setTimeSlots={setTimeSlots}
          params={params}
        />
      </CardWrapper>
    </div>
  );
};

export default CountryMultiSelect;
