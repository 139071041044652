import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./reducers";
import loginReducer from "./Auth";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const persistConfig = {
  key: "root",
  storage,
};

const loginReducerPersistConfig = {
  key: "loginReducer",
  storage: storage,
};

const persistedLoginReducer = persistReducer(
  loginReducerPersistConfig,
  loginReducer
);

// Merge all the reducers from rootReducer
const allReducers = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state = undefined; // Clear the state on logout
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, allReducers);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };
