import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TableComponent from "./TableComponent";
import { experimentalStyled as styled } from "@mui/material/styles";

const EventTable = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectVal, setSelectVal] = React.useState("clientes");
  const open = Boolean(anchorEl);
  const clientsRows = [
    createData(
      "Cricket Tournament",
      "ICCB",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "Event in Amsterdam",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "Let's have one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "ICCB",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "ACTIVE"
    ),
    createData(
      "one more event",
      "Test Tushar Diff User EM",
      "April 25, 2023",
      "12:00 AM",
      "INACTIVE"
    ),
  ];

  const [tableRows, setTableRows] = React.useState(clientsRows);

  function createData(events, listing, startDate, startTime, status) {
    return {
      events,
      listing,
      startDate,
      startTime,
      status,
    };
  }

  return (
    <div>
      <div>
        <TableComponent rows={tableRows} />
      </div>
    </div>
  );
};

export default EventTable;
