import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { store } from "../redux/store";

const PrivateRoutes = ({ Component }) => {
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);

  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn);
  const token = store.getState()?.loginReducer?.user?.data?.jwtToken;

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    }
  }, [token]);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return <Component />;
};

export default PrivateRoutes;
