import React, { useEffect } from "react";
import TableComponent from "./TableComponent";

const AdsTable = ({ tableData, handleDeleteListing, handleTaskCompleted }) => {
  const [tableRows, setTableRows] = React.useState(tableData);

  useEffect(() => {
    setTableRows(tableData);
  }, [tableData]);

  return (
    <div>
      <div>
        <TableComponent
          tableRows={tableRows}
          handleDeleteListing={handleDeleteListing}
          handleTaskCompleted={handleTaskCompleted}
        />
      </div>
    </div>
  );
};

export default AdsTable;
