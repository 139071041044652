import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

const LocalAddressInput = ({
  localAddress,
  setLocalAddress,
  newAddress,
  address,
  setTempStreetAddress,
  warning,
  setStreetWarning,
  listingType,
}) => {
  const [oldAddress, setOldAddress] = useState(newAddress);

  useEffect(() => {
    setOldAddress(newAddress);
  }, [newAddress]);

  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const params = useParams();
  const options = useMemo(
    () => ({
      fields: [
        "formatted_address",
        "address_components",
        "geometry",
        "icon",
        "name",
        "place_id",
      ],
      types: ["geocode", "establishment"],
    }),
    []
  );

  useEffect(() => {
    if (window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();

        if (place) {
          if (place) {
            const {
              formatted_address,
              geometry,
              place_id,
              address_components,
              name,
            } = place;
            setTempStreetAddress(`${name}, ${formatted_address}`);
            let postalCode = null;

            for (const component of address_components) {
              if (component.types.includes("postal_code")) {
                postalCode = component.long_name;
                break;
              }
            }
            if (params.id) {
              const { ...addressWithoutCity } = oldAddress;
              setLocalAddress({
                ...addressWithoutCity,
                addressLine1:
                  listingType === "OFFERING"
                    ? `${name}, ${formatted_address}`
                    : "",
                postalCode: postalCode !== null ? postalCode : "",
                latitude: geometry.location.lat(),
                longitude: geometry.location.lng(),
                locationPlaceId: place_id,
              });
            } else {
              setLocalAddress({
                addressLine1:
                  listingType === "OFFERING"
                    ? `${name}, ${formatted_address}`
                    : "",
                postalCode: postalCode !== null ? postalCode : "",
                latitude: geometry.location.lat(),
                longitude: geometry.location.lng(),
                locationPlaceId: place_id,
              });
            }
          }
        } else {
        }
      });
    }
  }, [
    listingType,
    options,
    setTempStreetAddress,
    params.id,
    oldAddress,
    setLocalAddress,
  ]);

  const onBlurInput = () => {
    if (address === null) {
      setLocalAddress({
        addressLine1: null,
        postalCode: null,
        latitude: null,
        longitude: null,
        locationPlaceId: null,
      });
    }
  };
  const handleChange = (e) => {
    setStreetWarning("");
    if (e.target.value !== "") {
      setTempStreetAddress(e.target.value);
    } else {
      setLocalAddress({
        addressLine1: null,
        postalCode: null,
        latitude: null,
        longitude: null,
        locationPlaceId: null,
      });
    }
  };

  return (
    <div>
      <div>
        <div className="form-group mt-1">
          <label className={"fw-bold"}>{"Address Line 1"}</label>
          <i
            className=" icon-info-circled cursor-pointer fs-6 ms-2 "
            data-toggle="tooltip"
            data-placement="right"
            title="Address Line 1"
            style={{
              fontSize: "1.1rem",
            }}
          ></i>
          <input
            className="form-control"
            ref={inputRef}
            type="text"
            name={"address1"}
            placeholder="Start typing and find your place in google "
            defaultValue={localAddress?.addressLine1}
            onBlur={onBlurInput}
            onChange={handleChange}
          />

          {warning && <em style={{ color: "red" }}>{warning}</em>}
        </div>
      </div>
    </div>
  );
};

export default LocalAddressInput;
