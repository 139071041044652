import { AfterAuthApi, ApiCall } from "../..";
import { store } from "../../../redux/store";
import {
  GET_CATEGORIES,
  ADD_LISTING,
  LISTING_MEDIA,
  GET_MY_LISTINGS,
  GET_EDIT_LISTINGS,
  EDIT_LISTING,
  DELETE_LISTING,
  COMPLETE_LISTING,
} from "../../url";

export const getListingCategories = () => {
  return new Promise((resolve, reject) => {
    ApiCall(GET_CATEGORIES, "get")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject({ err, success: false });
      });
  });
};

export const addListing = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_LISTING, "post", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const listingMediaUpload = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(LISTING_MEDIA, "post", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getListing = (placeId, categoryId, countryId, page) => {
  return new Promise((resolve, reject) => {
    const token = store.getState()?.loginReducer;
    ApiCall(
      `listings?cityPlaceId=${placeId}&countryPlaceId=${countryId}&categoryId=${categoryId}&page=${page}&size=${"200"}`,
      "get",
      null,
      token.isLoggedIn && {
        Authorization: "Bearer " + token?.user?.data?.jwtToken,
      }
    )
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMyListing = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_MY_LISTINGS, "get")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEditListingData = (id) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(`${GET_EDIT_LISTINGS}/id/${id}`, "get")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getEditListingDataWithoutToken = (id) => {
  return new Promise((resolve, reject) => {
    ApiCall(`${GET_EDIT_LISTINGS}/id/${id}`, "get")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const editSingleListing = (id, submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(`${EDIT_LISTING}/${id}`, "put", submitData)
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteListing = (id) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(`${DELETE_LISTING}/${id}`, "delete")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const completeListing = (id) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(`${COMPLETE_LISTING}/${id}/complete`, "patch")
      .then((res) => {
        resolve({ data: res.data, status: res.status, success: true });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
