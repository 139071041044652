import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BlogContentList,
  BlogsSidebarList,
} from "../../../shared/Constants/constants";
import { isEmpty } from "lodash";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import { Avatar, Box, Typography } from "@mui/material";
import Footer from "../../../shared/Footer";
import "./blogs.css";

const FullBlog = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [fullBlogContent, setFullBlogContent] = useState();

  useEffect(() => {
    if (params.id) {
      const blogId = params.id;
      const data =
        blogId.split("-")[0] === "topBlog"
          ? BlogsSidebarList
          : BlogContentList || [];

      if (!isEmpty(data)) {
        const blogData = data.find((item) => item.id === blogId);
        setFullBlogContent(blogData);
      }
    }
  }, [params.id]);
  return (
    <>
      <div id="page" className="theia-exception">
        <PagesHeader />

        <main className="bg-light-blue main-blog-wrapper">
          <Box className="container r" sx={{ marginTop: "60px" }}>
            {!isEmpty(fullBlogContent) && (
              <Box
                sx={{ width: "100%" }}
                className="d-flex align-items-start justify-content-start flex-column"
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    width: "fit-content",
                    padding: "10px 10px 10px 0",
                  }}
                  className="fw-bold"
                  onClick={() => navigate(-1)}
                >
                  &lt; Back to Blog
                </Box>
                <Typography
                  component={"h1"}
                  className="fw-bold"
                  mb={2}
                  sx={{
                    fontSize: { xs: "25px", sm: "28px", md: "30px" },
                    textTransform: "uppercase",
                  }}
                >
                  {fullBlogContent.title}
                </Typography>
                <Box className="d-flex align-items-center " gap={1} mb={2}>
                  <Avatar src={fullBlogContent.blogOwnerImage} alt="" />
                  <Typography sx={{ fontSize: "13px" }}>
                    {fullBlogContent.blogOwnerName}
                  </Typography>{" "}
                  |
                  <Typography sx={{ fontSize: "13px" }}>
                    {fullBlogContent.blogDate}
                  </Typography>
                </Box>
                <Box className="full-blog-image-container">
                  <Avatar
                    src={fullBlogContent.imagePath}
                    variant="square"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </main>
        <Box className="container" sx={{ marginBottom: "10px" }}>
          <Box className={"blog-content-wrapper"}>
            {!isEmpty(fullBlogContent) && (
              <div
                dangerouslySetInnerHTML={{
                  __html: fullBlogContent.blogContent,
                }}
              />
            )}
          </Box>
        </Box>
        <Footer />
      </div>
    </>
  );
};

export default FullBlog;
