import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getListing } from "../../../../helper/APIs/USER/Listing";
import * as listingAction from "../../../../redux/Listing/action";
import Footer from "../../../../shared/Footer";
import PagesHeader from "../../../../shared/Navbar/PagesHeader";
import LocationPopUp from "../../Modals/LocationPopUp";
import SubHeader from "./SubHeader";
import TaskListing from "./taskListing";

const ListingList = () => {
  const params = useLocation();
  const Listings = useSelector((state) => state.listingReducer.listingListData);
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);

  const dispatch = useDispatch();

  const [listingData, setListingData] = useState(Listings);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [placeId, setPlaceId] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [address, setAddress] = useState(null);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [selectedCategories, setSelectedCategories] = useState(null);

  const storedAddress = JSON.parse(localStorage.getItem("address"));
  const storedPlaceId = JSON.parse(localStorage.getItem("placeId"));
  const storedCategoryId = JSON.parse(localStorage.getItem("categoryId"));
  const storedCountryId = JSON.parse(localStorage.getItem("countryId"));

  useEffect(() => {
    setSelectedCategories(storedCategoryId === "all" ? " " : storedCategoryId);
  }, [storedCategoryId]);

  useEffect(() => {
    setAddress(storedAddress);
  }, [storedAddress]);

  useEffect(() => {
    setPlaceId(storedPlaceId);
  }, [storedPlaceId]);

  useEffect(() => {
    setCountryId(storedCountryId);
    if (storedCountryId === null) {
      const parts = params?.pathname?.split("/");
      const id = parts[parts.length - 1];
      setSelectedCategories(id);
      setOpen(true);
    }
  }, [params?.pathname, storedCountryId]);

  // useEffect(() => {
  //   if (countryId !== null && placeId !== null) {
  //     setLoading(true);
  //     getListing(placeId, selectedCategories, countryId, page)
  //       .then((res) => {
  //         if (res.success) {
  //           dispatch(
  //             listingAction.setListingListData(res.data.content, res.data)
  //           );
  //           setLoading(false);
  //           setOpen(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   }
  //   setPage(0);
  // }, [countryId, dispatch, page, placeId, selectedCategories]);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     if (countryId !== null && placeId !== null) {
  //       setLoading(true);
  //       getListing(placeId, selectedCategories, countryId, page)
  //         .then((res) => {
  //           if (res.success) {
  //             dispatch(
  //               listingAction.setListingListData(res.data.content, res.data)
  //             );
  //             setLoading(false);
  //             setOpen(false);
  //           }
  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //         });
  //     }
  //   }
  // }, [countryId, isLoggedIn, page, placeId, selectedCategories]);

  useEffect(() => {
    if (isLoggedIn) {
      if (countryId !== null && placeId !== null) {
        setLoading(true);
        getListing(placeId, selectedCategories, countryId, page)
          .then((res) => {
            if (res.success) {
              dispatch(
                listingAction.setListingListData(res.data.content, res.data)
              );
              setLoading(false);
              setOpen(false);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  }, [countryId, isLoggedIn, page, placeId, selectedCategories]);

  useEffect(() => {
    setListingData(Listings);
  }, [Listings]);

  useEffect(() => {
    if (placeId && selectedCategories) {
      setLoading(true);
      getListing(
        placeId,
        selectedCategories === "all" ? " " : selectedCategories,
        countryId,
        page
      )
        .then((res) => {
          if (res.success) {
            localStorage.setItem("address", JSON.stringify(address));
            localStorage.setItem(
              "categoryId",
              JSON.stringify(selectedCategories)
            );
            localStorage.setItem("placeId", JSON.stringify(placeId));
            localStorage.setItem("countryId", JSON.stringify(countryId));
            setAddress(address);
            setPlaceId(placeId);
            setCountryId(countryId);
            setSelectedCategories(selectedCategories);
            setOpen(false);

            dispatch(
              listingAction.setListingListData(res.data.content, res.data)
            );
            setLoading(false);
          }
        })
        .catch((err) => setLoading(false));
    }
  }, [
    selectedCategories,
    placeId,
    // selectedListingType,
    countryId,
    page,
    address,
    dispatch,
  ]);

  const options = useMemo(
    () => ({
      fields: [
        "formatted_address",
        "address_components",
        "geometry",
        "icon",
        "name",
        "place_id",
      ],
      types: ["(cities)"],
    }),
    []
  );

  useEffect(() => {
    if (window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();

        if (place) {
          const { formatted_address, place_id, address_components } = place;
          setPlaceId(place_id);
          setAddress(formatted_address);

          const countryComponent = address_components.find((component) =>
            component.types.includes("country")
          );

          setCountryId(countryComponent.short_name);
        }
      });
    }
  }, [open, options]);

  const handleSubmit = (placeId, selectedCategories, countryId) => {
    if (placeId && selectedCategories) {
      setLoading(true);
      getListing(placeId, selectedCategories, countryId, page)
        .then((res) => {
          if (res.success) {
            localStorage.setItem("address", JSON.stringify(address));
            localStorage.setItem(
              "categoryId",
              JSON.stringify(selectedCategories)
            );
            localStorage.setItem("placeId", JSON.stringify(placeId));
            localStorage.setItem("countryId", JSON.stringify(countryId));
            setAddress(address);
            setPlaceId(placeId);
            setCountryId(countryId);
            setSelectedCategories(selectedCategories);
            setOpen(false);
            dispatch(
              listingAction.setListingListData(res.data.content, res.data)
            );
            setLoading(false);
          }
        })
        .catch((err) => setLoading(false));
    } else {
      toast.error("Please provide filter location and category.", {
        toastId: "filterError",
      });
    }
  };

  // const onLoadMore = () => {
  //   setPage(page + 1);
  //   if (placeId && selectedCategories) {
  //     setLoading(true);
  //     getListing(placeId, selectedCategories, countryId, page + 1)
  //       .then((res) => {
  //         if (res.success) {
  //           localStorage.setItem("address", JSON.stringify(address));
  //           localStorage.setItem(
  //             "categoryId",
  //             JSON.stringify(selectedCategories)
  //           );
  //           localStorage.setItem("placeId", JSON.stringify(placeId));
  //           localStorage.setItem("countryId", JSON.stringify(countryId));
  //           setAddress(address);
  //           setPlaceId(placeId);
  //           setCountryId(countryId);
  //           setSelectedCategories(selectedCategories);
  //           setOpen(false);
  //           dispatch(
  //             listingAction.setListingListData(
  //               res.data.content,
  //               placeId,
  //               address,
  //               countryId
  //             )
  //           );
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => setLoading(false));
  //   } else {
  //     toast.error("Please provide filter location and category.", {
  //       toastId: "filterError",
  //     });
  //   }
  // };

  return (
    <div id="page">
      <PagesHeader />
      <main className="">
        <SubHeader
          handleSubmit={handleSubmit}
          listingData={listingData}
          placeId={placeId}
          setPlaceId={setPlaceId}
          address={address}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          setAddress={setAddress}
          // selectedListingType={selectedListingType}
          // setSelectedListingType={setSelectedListingType}
        />

        {/* CONTENT AREA */}
        {/* <div className=" margin_60_35">
          <div className="container">
            <div className="row"> */}
        {/* <ListingSidebar /> */}
        {/* <!-- /aside --> */}

        {/* <ListingContent loading={loading} onLoadMore={onLoadMore} /> */}
        <TaskListing loading={loading} />
        {/* </div> */}

        {/* <!-- /col --> */}
        {/* </div> */}
        {/* </div> */}
      </main>
      <Footer />
      {open && (
        <LocationPopUp
          open={open}
          handleSubmit={handleSubmit}
          placeId={placeId}
          countryId={countryId}
          inputRef={inputRef}
          loading={loading}
          selectedCategories={selectedCategories}
        />
      )}
    </div>
  );
};

export default ListingList;
