import React, { useState } from "react";
import CardWrapper from "../../Listing/AddListing/CardWrapper.js";
import InputFields from "../../../../shared/InputFieldsWithLabels/index.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment/moment.js";
import { toast } from "react-toastify";

const CustomInput = ({ value, onClick }) => (
  <div className="date-input" style={{ position: "relative" }}>
    <CalendarMonthIcon
      fontSize="small"
      onClick={onClick}
      sx={{ position: "absolute", top: "24.5%", left: "1%" }}
    />
    <input
      type="text"
      className="form-control"
      placeholder="Select a date"
      value={value}
      onClick={onClick}
      readOnly
    />
  </div>
);

const TimeAndDateCard = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
}) => {
  const currentDate = new Date();

  const handleEndTimeChange = (event) => {
    const selectedTimeValue = event.target.value;

    const currentTime = moment().format("HH:mm");

    if (selectedTimeValue >= currentTime && selectedTimeValue >= startTime) {
      setEndTime(selectedTimeValue);
      // Time is valid, perform additional actions if needed
    } else {
      toast.error("select greter time than start time.");
      // Time is not valid, display an error message or add a CSS className to indicate an error
    }
  };
  const handleStartTimeChange = (event) => {
    const selectedTimeValue = event.target.value;

    const currentTime = moment().format("HH:mm");

    if (selectedTimeValue >= currentTime) {
      setStartTime(selectedTimeValue);
      // Time is valid, perform additional actions if needed
    } else {
      toast.error("select future time.");
      // Time is not valid, display an error message or add a CSS className to indicate an error
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <CardWrapper>
      <div className="row">
        <div className="col-6 form-group">
          <label className="fw-bold">Event Starts</label>
          <br />
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat={["MM/dd/yyyy", "dd/MM/yyyy", "dd.MM.yyyy"]}
            customInput={<CustomInput />}
            minDate={currentDate}
          />
        </div>
        <div className="col-6 pt-1">
          <InputFields
            // label="Event Location"
            type="time"
            id="rent"
            name={"startTime"}
            onChange={handleStartTimeChange}
            labelClass="fw-bold"
            placeholder="Staple And Fancy Hotels"
          />
        </div>
        <div className="col-6">
          <label className="fw-bold">Event Ends</label>
          <br />
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            dateFormat={["MM/dd/yyyy", "dd/MM/yyyy", "dd.MM.yyyy"]}
            customInput={<CustomInput />}
            minDate={currentDate}
          />
        </div>
        <div className="col-6 pt-1">
          <InputFields
            // label="Event Location"
            type="time"
            id="endTime"
            name={"endTime"}
            onChange={handleEndTimeChange}
            labelClass="fw-bold"
            placeholder="Staple And Fancy Hotels"
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default TimeAndDateCard;
