import { ADD_MESSAGE } from "./type";

const defaultReducer = {
  messages: [],
};

const messageReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        ...state,
        messages: action.payload,
      };

    default:
      return state;
  }
};

export default messageReducer;
