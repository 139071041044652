import React from "react";
import CardWrapper from "../../Listing/AddListing/CardWrapper.js";
import { usePlacesWidget } from "react-google-autocomplete";

const EventLocation = ({ setLocation }) => {
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCPzdHQ0ITIXKke3UlbDwmn32eUooRtE5U",
    onPlaceSelected: (place) => {
      // Access the selected place details using place object
      setLocation(place.formatted_address);
    },
  });

  return (
    <CardWrapper>
      <label className={"fw-bold"} htmlFor={"rent"}>
        {"Event Location"}
      </label>
      <input
        className="form-control"
        id="rent"
        ref={ref}
        type="text"
        name={"eventLocation"}
        placeholder="Search for a location"
      />
    </CardWrapper>
  );
};

export default EventLocation;
