import {
  GET_LISTING_CATEGORY_SUCCESSFULL,
  GET_LISTING_CATEGORY_PROCESSING,
  GET_LISTING_CATEGORY_STOP_PROCESSING,
} from "./type";

const defaultReducer = {
  categories: [],
  processing: false,
};

const categoryReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_LISTING_CATEGORY_SUCCESSFULL:
      return {
        ...state,
        categories: action.payload,
        processing: false,
      };
    case GET_LISTING_CATEGORY_PROCESSING:
      return {
        ...state,
        processing: true,
      };
    case GET_LISTING_CATEGORY_STOP_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default categoryReducer;
