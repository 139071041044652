import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MoreInfoInputIcon from "../../../../../shared/InputFieldsWithLabels/MoreInfoInputIcon";

const EditDynamicFormFields = ({
  newFields,
  setFormFieldValue,
  formFieldValue,
  oldVal,
}) => {
  const params = useParams();

  const [formFields, setFormFields] = useState(newFields);
  const [radioBtn, setRadioBtn] = useState("");

  useEffect(() => {
    setFormFields(newFields);
    return () => setFormFields();
  }, [newFields]);

  useEffect(() => {
    if (params.id) {
      setFormFieldValue(oldVal);
    }
  }, [oldVal, params.id, setFormFieldValue]);

  const handleInputChange = (fieldName, value) => {
    const updatedFields = formFieldValue.map((field) => {
      if (field.categoryFormField?.formField?.ffName === fieldName) {
        const fieldType = field.categoryFormField.formField.ffType;

        if (fieldType === "MULTI_CHECK") {
          const currentValues = field.chosenValue || [];
          let updatedValues = [...currentValues]; // Create a copy of current values

          if (updatedValues.includes(value)) {
            updatedValues = updatedValues.filter((v) => v !== value); // Remove the value if it already exists
          } else {
            updatedValues.push(value); // Add the value if it doesn't exist
          }

          return {
            ...field,
            chosenValue: updatedValues,
          };
        } else {
          const updatedValue = Array.isArray(value) ? value : [value]; // Store as an array of strings
          return {
            ...field,
            chosenValue: updatedValue,
          };
        }
      }
      return field;
    });
    // Check if the field already exists in updatedFields
    const fieldIndex = updatedFields.findIndex(
      (field) => field.categoryFormField?.formField?.ffName === fieldName
    );

    if (fieldIndex === -1) {
      const field = formFields.find(
        (field) => field.categoryFormField.formField?.ffName === fieldName
      );

      if (field) {
        const updatedValue = Array.isArray(value) ? value : [value]; // Store as an array of strings
        updatedFields.push({
          id: "",
          listingId: "",
          categoryFormField: {
            id: field.categoryFormField.id,
            categoryId: field.categoryFormField.categoryId || "",
            formField: {
              id: field.categoryFormField.formField?.id || "",
              ffName: fieldName,
              ffDisplayName:
                field.categoryFormField.formField?.ffDisplayName || "",
              ffType: field.categoryFormField.formField?.ffType || "",
              ffValue: field.categoryFormField.formField?.ffValue || "",
              forAllCategory:
                field.categoryFormField.formField?.forAllCategory || "",
              status: field.categoryFormField.formField?.status || "",
            },
            required: require || "",
            tooltip: field.categoryFormField.tooltip || "",
          },
          chosenValue: updatedValue,
        });
      }
    }

    const flattenedFields = updatedFields
      .map((field) => ({
        ...field,
        categoryFormField: {
          ...field.categoryFormField,
          formField: {
            ...field.categoryFormField.formField,
          },
        },
        chosenValue: Array.isArray(field.chosenValue)
          ? field.chosenValue
          : [field.chosenValue],
      }))
      .filter((field) => {
        const isEmptyArray =
          Array.isArray(field.chosenValue) && field.chosenValue.length === 0;
        const isEmptyArrayOfStrings =
          Array.isArray(field.chosenValue) &&
          field.chosenValue.every(
            (value) => typeof value === "string" && value.trim() === ""
          );

        return !isEmptyArray && !isEmptyArrayOfStrings;
      });
    setFormFieldValue(flattenedFields);
  };

  const renderFormField = (field) => {
    let ab = params.id ? field?.categoryFormField?.formField : field?.formField;
    const { ffType, ffName, ffDisplayName, ffValue } = ab;
    const { tooltip } = field;
    const require = params.id
      ? field.categoryFormField.required
      : field.required;

    switch (ffType) {
      case "MULTI_CHECK":
        const handleMultiCheckChange = (value) => {
          handleInputChange(ffName, value);
        };

        return (
          <div key={ffName} className="form-group">
            <label className="fw-bold">{ffDisplayName}</label>
            {tooltip !== null && <MoreInfoInputIcon label={ffDisplayName} />}
            <br />
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                {ffValue.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={
                      <Checkbox
                        defaultChecked={
                          params.id && field?.chosenValue?.includes(option)
                            ? field?.chosenValue?.includes(option)
                            : ""
                        }
                      />
                    }
                    label={option}
                    labelPlacement="end"
                    onFocus={() => handleMultiCheckChange(option)}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        );

      case "DROPDOWN":
      case "TEXT":
      case "RADIO":
        const handleFieldChange = (e) => {
          e.preventDefault();
          const value = e.target.value;

          handleInputChange(ffName, value);
          setRadioBtn(value);
        };

        return (
          <>
            {ffType === "DROPDOWN" ? (
              <div className="col-md-6 mt-1 ">
                <div className={require ? "was-validated" : ""}>
                  <div className="d-flex align-items-center">
                    <label
                      className="fw-bold clip-text"
                      htmlFor={require ? "ffDisplayName" : ""}
                    >
                      {ffDisplayName}
                    </label>
                    {tooltip !== null && <MoreInfoInputIcon label={tooltip} />}
                  </div>
                  <select
                    id="ffDisplayName"
                    className="form-control"
                    style={{ appearance: "auto" }}
                    onChange={handleFieldChange}
                    required
                  >
                    <option disabled value="">
                      Select an option
                    </option>
                    {ffValue.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : ffType === "RADIO" ? (
              <div className="col-md-12">
                <div className={`form-group ${require ? "was-validated" : ""}`}>
                  <label className="fw-bold">{ffDisplayName}</label>
                  {tooltip !== null && (
                    <MoreInfoInputIcon label={ffDisplayName} />
                  )}
                  <RadioGroup
                    row
                    aria-label={ffDisplayName}
                    name={ffDisplayName}
                    value={radioBtn ? radioBtn : field?.chosenValue?.toString()}
                    onChange={handleFieldChange}
                  >
                    {ffValue.map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={option}
                      />
                    ))}
                  </RadioGroup>
                </div>
              </div>
            ) : (
              <div className="col-md-6 mt-1">
                <div className={`form-group ${require ? "was-validated" : ""}`}>
                  <div className="d-flex">
                    <label
                      className="fw-bold clip-text"
                      htmlFor={require ? ffDisplayName : ""}
                    >
                      {ffDisplayName}
                    </label>
                    {tooltip !== null && (
                      <MoreInfoInputIcon label={ffDisplayName} />
                    )}
                  </div>
                  <input
                    type="text"
                    id={ffDisplayName}
                    className="form-control"
                    defaultValue={
                      params.id && field?.chosenValue?.toString()
                        ? field.chosenValue.toString()
                        : ""
                    }
                    onBlur={handleFieldChange}
                    onChange={handleFieldChange}
                    required={require}
                  />
                </div>
              </div>
            )}
          </>
        );

      case "DATE":
      case "DATE_AND_TIME":
        const handleDateChange = (e) => {
          const value = e.target.value;
          handleInputChange(ffName, value);
        };

        return (
          <div className="col-md-6 mt-1">
            <div className={`form-group ${require ? "was-validated" : ""}`}>
              <label className="fw-bold">{ffDisplayName}</label>
              {tooltip !== null && <MoreInfoInputIcon label={ffDisplayName} />}
              <input
                type={ffType === "DATE_AND_TIME" ? "datetime-local" : "date"}
                className="form-control"
                onChange={handleDateChange}
                defaultValue={
                  params.id && field?.chosenValue?.toString()
                    ? field?.chosenValue?.toString()
                    : ""
                }
                required={require}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="row form-group">
      {formFields?.map((field) => renderFormField(field))}
    </div>
  );
};

export default EditDynamicFormFields;
