import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircleIcon from "@mui/icons-material/Circle";
import { Link } from "react-router-dom";
import StatusCofirmationDialog from "../StatusCofirmationDialog";

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "Events",
    numeric: false,
    disablePadding: false,
    label: "Events",
  },
  {
    id: "Listing",
    numeric: true,
    disablePadding: false,
    label: "Listing",
  },
  {
    id: "Start Date",
    numeric: true,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "Start Time",
    numeric: true,
    disablePadding: false,
    label: "Start Time",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];
const useStyles = makeStyles({
  tableBody: {
    "& > :not(:last-child)": {
      borderBottom: "25px solid red",
    },
  },
  root: {
    "& .MuiTable-root, .MuiTableBody-root, .MuiPaper-root": {
      backgroundColor: "transparent !important",
      boxShadow: "unset !important",
    },
    "& .MuiTableRow-root": {
      backgroundColor: "#ffff !important",
      //   marginBottom: "10px !important",
      // borderBottom: "5px solid #F8F5FA",
    },
    "& .MuiTableCell-root MuiTableCell-head": {
      fontSize: "21px",
      fontFamily: "Poppins",
      color: "#464A53 !important",
    },
    "& .MuiTableCell-root MuiTableCell-body": {
      fontSize: "16px",
      fontFamily: "Poppins",
    },
  },
});
function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            align={"left"}
            sx={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "#809FB8 !important",
            }}
            key={index}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({ rows }) {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const totalPage = Math.ceil(rows.length / rowsPerPage);
  const mapPage = Array.from(Array(totalPage).keys());

  return (
    <Box sx={{ width: "100%", padding: 0 }} className={classes.root}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody py={2}>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  {
                    /* const isItemSelected = isSelected(row.name); */
                  }
                  return (
                    <TableRow
                      style={{
                        marginBottom: "10px !important",
                      }}
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell
                        align="left"
                        style={{
                          //   color: "#3D4655",

                          fontSize: "0.9rem",
                        }}
                      >
                        <div>{row.events}</div>
                        <Link style={{ fontSize: "0.85rem" }}>
                          View Attendees
                        </Link>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          //   color: "#3D4655",

                          fontSize: "0.9rem",
                        }}
                      >
                        <Link>{row.listing}</Link>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          //   color: "#3D4655",

                          fontSize: "0.9rem",
                        }}
                      >
                        {row.startDate}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          //   color: "#3D4655",

                          fontSize: "0.9rem",
                        }}
                      >
                        {row.startTime}
                      </TableCell>{" "}
                      <TableCell
                        align="left"
                        style={{
                          //   color: "#3D4655",

                          fontSize: "0.9rem",
                        }}
                      >
                        <div className="d-flex align-items-center justify-centent-around">
                          <span>
                            <CircleIcon
                              sx={{
                                fontSize: "small",
                                color:
                                  row.status === "INACTIVE" ? "red" : "green",
                                margin: "0 0.6rem",
                              }}
                            />
                          </span>
                          <span
                            className="me-5"
                            style={{ width: "3rem", cursor: "pointer" }}
                            onClick={() => setOpen(true)}
                          >
                            {row.status}
                          </span>
                          <span>
                            <MoreVertIcon />
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <>
          <TablePagination
            rowsPerPageOptions={[10]}
            labelRowsPerPage={"pages:"}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {mapPage.map((obj, i) => {
              const activePageColor = i === page ? "#7F63F4" : "#fff";
              const activePageFontColor = i === page ? "#fff" : "#ABAFB3";
              const pageNumber = i + 1;

              if (
                pageNumber === page ||
                pageNumber === page + 1 ||
                pageNumber === page + 2 ||
                pageNumber === totalPage
              ) {
                return (
                  <Box
                    sx={{
                      textAlign: "right",
                      background: activePageColor,
                      padding: "14px",
                      marginRight: "4px",
                      borderRadius: "50%",
                      fontWeight: 700,
                      color: activePageFontColor,
                    }}
                    onClick={(e) => handleChangePage(e, i)}
                    key={i}
                  >
                    {pageNumber < 10 ? "0" : ""}
                    {pageNumber}
                  </Box>
                );
              } else if (
                pageNumber === page + 3 &&
                pageNumber !== totalPage - 1
              ) {
                return (
                  <Box
                    sx={{
                      textAlign: "right",
                      padding: "14px",
                      marginRight: "4px",
                      fontWeight: 700,
                      color: "#ABAFB3",
                    }}
                  >
                    ...
                  </Box>
                );
              } else if (
                pageNumber === totalPage - 1 &&
                pageNumber !== page + 3
              ) {
                return (
                  <Box
                    sx={{
                      textAlign: "right",
                      background: "#fff",
                      padding: "14px",
                      marginRight: "4px",
                      borderRadius: "50%",
                      fontWeight: 700,
                      color: "#ABAFB3",
                    }}
                  >
                    {pageNumber < 10 ? "0" : ""}
                    {pageNumber}
                  </Box>
                );
              }
              return null;
            })}
          </Box>
        </>
        {open && (
          <StatusCofirmationDialog
            open={open}
            handleClose={() => setOpen(false)}
          />
        )}
      </Paper>
    </Box>
  );
}
