import React, { useState } from "react";
import InputFields from "../../../shared/InputFieldsWithLabels";
import PasswordFields from "../../../shared/InputFieldsWithLabels/PasswordFields";
import { Link, useNavigate } from "react-router-dom";
import { changePassword } from "../../../helper/APIs/Auth";
import { toast } from "react-toastify";
import { connect } from "react-redux";

const ChangePassword = ({ profileId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    userProfileId: profileId,
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let submitData = { ...formValues };
    if (formValues.newPassword !== formValues.confirmNewPassword) {
      toast.error("Password don't match");
      setLoading(false);
    } else {
      changePassword(submitData)
        .then((res) => {
          if (res.success) {
            setLoading(false);
            toast.success("Password changed successfully.");
            navigate("/");
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err?.err?.response?.status === 403) {
            toast.error("Please login to change password or forgot password.");
          }
        });
    }
  };

  return (
    <div>
      <div id="login_bg">
        <div id="login">
          <aside>
            <figure>
              <Link to="/">
                <img
                  src="assets/img/LOGO/100xhelper/100xhelper.svg"
                  width="100"
                  height="40"
                  alt=""
                  className="logo_sticky object-fit-cover"
                />
              </Link>
            </figure>
            <form className="" onSubmit={handleSubmit}>
              <PasswordFields
                label="Your password"
                type="password"
                id="password2"
                name="newPassword"
                iconClass="fa fa-lock"
                onChange={handleChange}
                defaultValue={formValues.newPassword}
              />
              <PasswordFields
                label="Confirm password"
                type="password"
                id="password3"
                name="confirmNewPassword"
                iconClass="fa fa-lock"
                onChange={handleChange}
                defaultValue={formValues.confirmNewPassword}
                disabled={loading}
              />
              <button className="btn_1 rounded full-width">
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm mx-3"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Change Password
              </button>

              <div className="copy">
                Copyright © {new Date().getFullYear()}&nbsp; 100xHelper <br />
                All Rights Reserved
              </div>
            </form>
          </aside>
        </div>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => {
  return {
    profileId: state?.loginReducer?.user?.data?.userProfile?.id,
  };
};

export default connect(mapStateToProp)(ChangePassword);
