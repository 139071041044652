import React from "react";
import { Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Footer from "../../../../shared/Footer";
import PagesHeader from "../../../../shared/Navbar/PagesHeader";
import HeaderSelect from "./HeaderSelect";
import TabsPanel from "./TabsPanel";

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginRight: 2,
  },
  button: {
    marginLeft: 2,
  },
  dropdown: {
    marginLeft: 2,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EventDetails = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="page" className="theia-exception">
      <PagesHeader />
      <main>
        <div className=" margin_60_35">
          <div className="row ">
            <div className="col-lg-12">
              <div className={classes.root}>
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <Tabs
                      className={classes.tabs}
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="Event Tabs"
                    >
                      <Tab label="ALL EVENTS" {...a11yProps(0)} />
                      <Tab label="UPCOMING" {...a11yProps(1)} />
                      <Tab label="ACTIVE" {...a11yProps(2)} />
                      <Tab label="INACTIVE" {...a11yProps(3)} />
                    </Tabs>
                  </div>
                  <HeaderSelect classes={classes} />
                  <TabsPanel value={value} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default EventDetails;
