import {
  LOGIN_USER_SUCCESSFUL,
  LOGIN_PROCESSING,
  STOP_LOGIN_PROCESSING,
  LOGOUT_SUCCESSFUL,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_PROCESSING,
  STOP_REGISTER_PROCESSING,
  TEMP_REGISTER_DATA,
} from "./type";

const defaultReducer = {
  isLoggedIn: false,
  processing: false,
  adminLoggedIn: false,
  user: null,
  data: null,
};

const loginReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESSFUL:
      return {
        ...state,
        processing: false,
        isLoggedIn: action.payload.userType === "user" ? true : false,
        adminLoggedIn: action.payload.userType === "admin" ? true : false,
        user: action.payload.loginUser,
      };
    case TEMP_REGISTER_DATA:
      return {
        ...state,
        processing: false,
        isLoggedIn: false,
        adminLoggedIn: false,
        user: null,
        data: action.payload,
      };

    case LOGIN_PROCESSING:
      return {
        ...state,
        processing: true,
        isLoggedIn: false,
        adminLoggedIn: false,
      };

    case STOP_LOGIN_PROCESSING:
      return {
        ...state,
        processing: false,
        isLoggedIn: false,
        adminLoggedIn: false,
      };

    case REGISTER_USER_SUCCESSFUL:
      return {
        ...state,
        processing: false,
        isLoggedIn: true,
        user: action.payload,
      };

    case REGISTER_PROCESSING:
      return {
        ...state,
        processing: true,
        isLoggedIn: false,
      };

    case STOP_REGISTER_PROCESSING:
      return {
        ...state,
        processing: false,
        isLoggedIn: false,
      };

    case LOGOUT_SUCCESSFUL: {
      return {
        ...defaultReducer,
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
