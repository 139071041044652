import { Box, Tab, Tabs, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getEditListingData,
  getEditListingDataWithoutToken,
} from "../../../../helper/APIs/USER/Listing";
import Footer from "../../../../shared/Footer";
import PagesHeader from "../../../../shared/Navbar/PagesHeader";
import ListingCarousal from "./ListingCarousal";
import ListingDescription from "./ListingDescription";
import Sidebar from "./Sidebar";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ListingDetails = () => {
  const params = useLocation();
  const navigate = useNavigate();

  const listing = useSelector((state) => state.listingReducer.listingDetail);
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );

  const [listingDetails, setListingDetails] = useState(listing);
  const [Loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  const parts = params?.pathname?.split("/"); // Split the URL by slashes
  const id = parts[parts.length - 1];

  useEffect(() => {
    if (id) {
      setLoading(true);
      if (isLoggedIn) {
        getEditListingData(id)
          .then((res) => {
            if (res.success) {
              setListingDetails(res.data);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        getEditListingDataWithoutToken(id)
          .then((res) => {
            if (res.success) {
              setListingDetails(res.data);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  }, [id, isLoggedIn, params]);

  return (
    <div id="page" className="theia-exception">
      <PagesHeader />
      <main>
        {Loading ? (
          <div style={{ margin: "15rem 0rem" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </div>
        ) : (
          <>
            {!isEmpty(listingDetails) &&
              Object.keys(listingDetails).length !== 0 && (
                <>
                  {listingDetails.ownerUserProfile.id === profileInfo?.id && (
                    <div className="sub_header_in sticky_header">
                      <div className="container">
                        <Box
                          sx={{
                            padding: "5px",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          {["PUBLISHED", "PENDING_REVIEW"].includes(
                            listingDetails?.listingStatus
                          ) && (
                            <button
                              className="btn_view mx-1"
                              onClick={() => navigate(`/add-listing/${id}`)}
                            >
                              Edit
                            </button>
                          )}
                          {listingDetails?.listingStatus === "DELETED" && (
                            <div>
                              <button
                                className="btn_reactivate mx-1"
                                onClick={() => navigate(`/add-listing/${id}`)}
                              >
                                Edit & Reactivate
                              </button>
                            </div>
                          )}
                        </Box>
                      </div>
                    </div>
                  )}
                  <div
                    className="margin_60_35"
                    style={{
                      paddingTop: `${
                        listingDetails.ownerUserProfile.id ===
                          profileInfo?.id && "0"
                      }`,
                    }}
                  >
                    <div className="container">
                      <Box className="row">
                        <Box
                          className="col-lg-8"
                          sx={{
                            marginTop: {
                              xs:
                                listingDetails.ownerUserProfile.id ===
                                profileInfo?.id
                                  ? "30px"
                                  : "unset",
                              sm:
                                listingDetails.ownerUserProfile.id ===
                                profileInfo?.id
                                  ? "20px"
                                  : "unset",
                              md:
                                listingDetails.ownerUserProfile.id ===
                                profileInfo?.id
                                  ? "10px"
                                  : "unset",
                            },
                          }}
                        >
                          <ListingCarousal listingDetails={listingDetails} />
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={value}
                              onChange={(e, newValue) => setValue(newValue)}
                              aria-label="basic tabs example"
                            >
                              <Tab label="Details" {...a11yProps(0)} />
                            </Tabs>
                          </Box>

                          <TabPanel value={value} index={0}>
                            <ListingDescription
                              listingDetails={listingDetails}
                            />
                          </TabPanel>
                          {/* <TabPanel value={value} index={1}>
                            <ListingReview listingDetails={listingDetails} />
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <Pricing listingDetails={listingDetails} />
                          </TabPanel> */}
                        </Box>
                        <Sidebar listingDetails={listingDetails} />
                      </Box>
                    </div>
                  </div>
                </>
              )}
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default ListingDetails;
