import Button from "@mui/material/Button";
import * as React from "react";
import "./privacy-policy.css";

const PrivacyPolicy = ({  handleClose }) => {
  const handleAcceptPolicy = () => {
    localStorage.setItem("policy", true);
    handleClose();
  };

  return (
    <div className="privacy-wrapper">
      <div className="container">
        <h1 className="privacy-title">
          Your Privacy, Our Priority: Learn About Our Privacy Policy
        </h1>
        <p>Welcome to 100xHelper!</p>

        <p>
          We use essential cookies to ensure our website functions properly and
          to provide you with the best user experience. These cookies do not
          collect personal information and are necessary for the website to
          work.
        </p>
        <p>
          We want to assure you that we do not share your data with any external
          parties. Your privacy and security are important to us. By using our
          website, you agree to our use of essential cookies. You can learn more
          about our privacy practices and cookie policy by visiting our Privacy
          Policy page.
        </p>

        <p>
          Click "Accept" to give your consent and continue using 100xHelper.
        </p>
        <div className="d-flex justify-content-end">
          <Button className="accept-btn" onClick={handleAcceptPolicy}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
