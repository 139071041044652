import React from "react";
import "./howItWorks.css";
import { How_It_Works } from "../../../../shared/Constants/constants";
import { Box, Divider, Typography } from "@mui/material";

const UpdatedHowIsWorks = () => {
  return (
    <Box className="call_section" id="how-it-works">
      <Box className="wrapper">
        <Box className="container margin_80_55">
          <Box className="main_title_2">
            {/* <span>
              <em></em>
            </span> */}
            {/* <Typography className="wow bounceIn" mb={2} sx={{ fontSize: "36px" }}>
              How it Works
            </Typography> */}
            <h2 className="how-it-works-title wow">How it Works</h2>
            <p className="how-it-works-description wow text-light">
              By following these simple steps, you can get your tasks done
              affordably and efficiently.
            </p>
          </Box>
          <Box
            className="d-flex justify-content-center align-items-center flex-wrap"
            gap={2}
          >
            {How_It_Works.map((item, index) => (
              <Box className="item-box-wrapper" key={index}>
                <Box className="bg-white p-3 item-box">
                  <Box>
                    <Typography className="step-id">{item.id}</Typography>
                  </Box>
                  <Box
                    className="d-flex justify-content-between"
                    gap={2}
                    mb={1}
                    alignItems={"baseline"}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          minHeight: "80px",
                          fontWeight: "600",
                          color: "#283350",
                          width: "70%",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                    <div className="how-it-works-icon">
                      <img
                        src={`/assets/img/how-it-works-icons/${item.icon}.svg`}
                        alt={item.icon}
                      />
                    </div>
                  </Box>
                  <Divider sx={{ background: "#283350" }} />

                  <Box mt={6}>
                    <Typography sx={{ fontSize: "14px", color: "#171717" }}>
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
            {/* <div className="col-md-3">
              <div className="box_how">
                <i className="pe-7s-info"></i>
                <h3>Find Accommodation</h3>
                <p className="text-light">
                  {`Explore our "${CategoryEnum.apartment}" or "${CategoryEnum.sharedApartment}" categories for
                    your ideal accommodation requirements.`}
                </p>
                <p className="text-light">
                  Don't see what you need? Share your requirements, and we'll
                  publish them on 100xHelper.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box_how">
                <i className="pe-7s-like2"></i>
                <h3>Stay In the Loop</h3>
                <p className="text-light">
                  Regularly check 100xHelper for the latest accommodation
                  offers.
                </p>
                <p className="text-light">
                  Act fast – great opportunities go quickly with high demand.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box_how">
                <i className="pe-7s-like2"></i>
                <h3>Stay In the Loop</h3>
                <p className="text-light">
                  Regularly check 100xHelper for the latest accommodation
                  offers.
                </p>
                <p className="text-light">
                  Act fast – great opportunities go quickly with high demand.
                </p>
              </div>
            </div> */}
            {/* <div className="col-12 mt-5">
              <div className="box_how">
                <div className="text-light">
                  Do you have accommodations available while you're away?
                  <ul className="text-center">
                    <li className="mt-2 ms-4">
                      Share the love with your fellow Indians by renting out
                      your space.{" "}
                    </li>
                    <li className="mt-2 ms-4">
                      Help others find a comfortable place to stay while
                      covering some of your travel costs.
                    </li>
                    <li className="mt-2 ms-4">
                      It's a win-win situation that brings our community closer
                      together.{" "}
                    </li>
                    <li className="mt-2 ms-4">
                      Join hands with 100xHelper and make your trips more
                      rewarding.
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </Box>
          {/* <p
            className="text-center add_top_30 wow bounceIn text-light"
            data-wow-delay="0.5"
          >
            Join 100xHelper today and simplify your search for housing in
            Europe.
          </p> */}
        </Box>
      </Box>
      {/* <!-- /wrapper --> */}
    </Box>
  );
};

export default UpdatedHowIsWorks;
