import axios from "axios";
import { BASE_URL } from "../config/index";
import { store } from "../redux/store";

// const navigate = useNavigate();

export const ApiCall = (url, method, data = null, headers = {}) => {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: `${BASE_URL}${url}`,
      data,
      headers: {
        ...headers,
      },
    })
      .then(resolve)
      .catch((err) => {
        if (err?.response?.status) {
          // toast.error("Try again after sometime.");
          reject(err);
        }
      });
  });
};
// const token = store.getState()?.loginReducer?.user?.data?.jwtToken;

export const AfterAuthApi = (url, method, data = null, headers = {}) => {
  return new Promise((resolve, reject) => {
    const token = store.getState()?.loginReducer?.user?.data?.jwtToken;

    axios({
      method,
      url: `${BASE_URL}${url}`,
      data,
      headers: {
        Authorization: "Bearer " + token,
        ...headers,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
          // store.dispatch(logout());
          // window.location.href = "/login";
        }
        // if (err.response.status) {
        reject(err);
        // }
      });
  });
};
