import { getNotifications } from "../../helper/APIs/USER/Notification";
import {
  GET_LISTING_NOTIFICATIONS_PROCESSING,
  GET_LISTING_NOTIFICATIONS_STOP_PROCESSING,
  GET_LISTING_NOTIFICATIONS_SUCCESSFUL,
  UPDATE_NOTIFICATION_STATUS,
} from "./types";

export const getNotificationsList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: GET_LISTING_NOTIFICATIONS_PROCESSING });
    getNotifications()
      .then((notifications) => {
        if (notifications.success) {
          dispatch({
            type: GET_LISTING_NOTIFICATIONS_SUCCESSFUL,
            payload: notifications.data.content,
          });
          resolve(notifications);
        }
      })
      .catch((err) => {
        reject(err);
        dispatch({ type: GET_LISTING_NOTIFICATIONS_STOP_PROCESSING });
      });
  });
};

export const updateNotifications = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_STATUS,
    payload,
  };
};
