import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const ListingTypeRadio = ({
  setListingType,
  setTimeSlots,
  listingType,

  params,
}) => {
  const editData = useSelector((state) => state?.listingReducer?.editListing);
  const handleRadioChange = (event) => {
    if (event.target.value === "BUSINESS_PAGE" && params) {
      setTimeSlots(editData?.listingBusinessHours);
    } else {
      setTimeSlots([]);
    }
    setListingType(event.target.value);
    // Add your logic here to handle the selected value
  };

  return (
    <div>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange}
          value={listingType}
        >
          <FormControlLabel
            value="OFFERING"
            control={<Radio />}
            label="I have an apartment/room to offer"
          />
          <FormControlLabel
            value="SEARCHING"
            control={<Radio />}
            label="I am looking for an accommodation"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default ListingTypeRadio;
