import React, { useEffect, useState } from "react";
import CardWrapper from "../CardWrapper.js";
import Amenities from "./Amenities";

const ListingType = ({
  setFormFieldValue,
  formFieldValue,
  getInputObject,
  setChosenValue,
  chosenValue,
  categoryChanges,
}) => {
  const [dynamicInputs, setDynamicInputs] = useState(getInputObject);
  useEffect(() => {
    setDynamicInputs(getInputObject);
  }, [getInputObject]);
  return (
    <CardWrapper>
      <Amenities
        getInputObject={dynamicInputs}
        setFormFieldValue={setFormFieldValue}
        formFieldValue={formFieldValue}
        setChosenValue={setChosenValue}
        chosenValue={chosenValue}
        categoryChanges={categoryChanges}
      />
    </CardWrapper>
  );
};

export default ListingType;
