import React from "react";
import {
  Avatar,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";

import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

const OwnerContactPopUp = ({ open, handleClose, listings }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {"Send Your Inquiry Now!"}
        </DialogTitle>
        {/* <hr /> */}
        <DialogContent>
          <em style={{ color: "#283350" }}>
            To protect everyone's privacy and ensure a secure experience, we do
            not display ad owner emails. Instead, kindly send your inquiries to
            info@100xHelper.de. Your privacy is important to us, and we're
            dedicated to safeguarding your information.
          </em>
          <hr />
          <DialogContentText id="alert-dialog-description">
            <span>Ad Owner's Name: {listings?.ownerUserProfile?.fullname}</span>
            <List sx={{ p: 0 }}>
              <ListItem disableGutters>
                <ListItemButton>
                  <a
                    href={`mailto:${listings?.ownerUserProfile?.email}`}
                    className="d-flex align-items-center"
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <EmailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={listings?.ownerUserProfile?.email}
                      sx={{ color: "#333" }}
                    />
                  </a>
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters>
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar>
                      <CallIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={listings?.phoneNumber} />
                </ListItemButton>
              </ListItem>
            </List>
            {listings?.ownerUserProfile?.mediaUrl && (
              <div className="d-flex align-items-center">
                <span className="me-3">Ad Owner's Profile Photo:</span>{" "}
                <img
                  alt=""
                  src={listings?.ownerUserProfile?.mediaUrl}
                  style={{ width: 56, height: 56 }}
                />
              </div>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OwnerContactPopUp;
