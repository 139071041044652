import React, { useEffect, useState } from "react";
import AdsTable from "./AdsTable";
import PagesHeader from "../../../../shared/Navbar/PagesHeader";
import Footer from "../../../../shared/Footer";
import {
  completeListing,
  deleteListing,
  getMyListing,
} from "../../../../helper/APIs/USER/Listing";
import TableLoader from "../../../../shared/Loader/TableLoader";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const MyAds = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMyListing()
      .then((res) => {
        if (res.success) {
          setTableData(res.data.content);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleDeleteListing = (id) => {
    setLoading(true);
    deleteListing(id)
      .then((res) => {
        getMyListing()
          .then((res) => {
            if (res.success) {
              setTableData(res.data.content);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
        toast.success("Post has been deactivated.");
      })
      .catch((err) => {});
  };

  const handleTaskCompleted = (id) => {
    setLoading(true);
    completeListing(id)
      .then((res) => {
        getMyListing()
          .then((res) => {
            if (res.success) {
              setTableData(res.data.content);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
        toast.success("Post has been successfully completed.");
      })
      .catch((err) => {});
  };

  return (
    <div id="page" className="theia-exception">
      <PagesHeader />
      <main>
        <div className="margin_60_35">
          <div className="container p-2">
            <div className="row">
              <div className="col-lg-12">
                {loading ? (
                  <TableLoader />
                ) : tableData?.length ? (
                  <AdsTable
                    tableData={tableData}
                    handleDeleteListing={handleDeleteListing}
                    handleTaskCompleted={handleTaskCompleted}
                  />
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <Link to="/add-task">
                      <Button variant="contained">Post Your Task</Button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default MyAds;
