import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getEditListingData } from "../../../helper/APIs/USER/Listing";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import { Avatar, Box, Chip, CircularProgress, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { getTimeString } from "../../../shared/Constants/constants";
import { CalenderIcon, LocationSVGIcon } from "../../../shared/Constants/icons";
import moment from "moment";
import TaskImagePreview from "../../../components/USER/Listing/ListingList/taskImagePreview";
import Footer from "../../../shared/Footer";

const ViewTaskPage = () => {
  const params = useLocation();
  const navigate = useNavigate();

  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );
  const [listingDetails, setListingDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const parts = params?.pathname?.split("/"); // Split the URL by slashes
  const id = parts[parts.length - 1];
  const [mediaPreview, setMediaPreview] = useState({
    index: null,
    action: false,
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      getEditListingData(id)
        .then((res) => {
          if (res.success) {
            setListingDetails(res.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      <div id="page" className="theia-exception">
        <PagesHeader />
        <main>
          {loading ? (
            <div style={{ margin: "15rem 0rem" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </div>
          ) : (
            <>
              {!isEmpty(listingDetails) &&
                Object.keys(listingDetails).length !== 0 && (
                  <>
                    <Box
                      className="container"
                      sx={{ marginTop: "60px", marginBottom: "10px" }}
                    >
                      <Box className="bg-white full-width">
                        {!isEmpty(listingDetails) && (
                          <>
                            {["OPEN", "EXPIRED"].includes(
                              listingDetails.listingStatus
                            ) &&
                              listingDetails?.ownerUserProfile
                                ?.userProfileId === profileInfo?.id && (
                                <Box
                                  className="d-flex justify-content-end"
                                  sx={{
                                    padding: "16px 20px 0 16px !important",
                                  }}
                                >
                                  <button
                                    className="post-btn"
                                    onClick={() =>
                                      navigate(
                                        `/edit-task/${listingDetails.id}`
                                      )
                                    }
                                  >
                                    Edit Task
                                  </button>
                                </Box>
                              )}
                            <Box
                              className="d-flex"
                              gap={1}
                              sx={{ padding: "16px 20px !important" }}
                            >
                              <Box className="task-details task-details-mobile">
                                <Box
                                  className="d-flex align-items-center flex-wrap"
                                  gap={1}
                                >
                                  {[
                                    "OPEN",
                                    "ASSIGNED",
                                    "EXPIRED",
                                    "COMPLETED",
                                    "DEACTIVATED",
                                  ].map((chip, index) => (
                                    <Chip
                                      key={index}
                                      label={chip}
                                      color={
                                        listingDetails.listingStatus === chip
                                          ? "success"
                                          : "default"
                                      }
                                    />
                                  ))}
                                </Box>
                                <Typography
                                  mt={2}
                                  fontSize={28}
                                  sx={{
                                    color: "#283350",
                                    fontWeight: "600",
                                  }}
                                >
                                  {listingDetails.title}
                                </Typography>

                                {listingDetails?.budget && (
                                  <Box className="view-task-budget-wrapper-mobile">
                                    <Box className="active-task budget-section d-flex justify-content-between align-items-center flex-column">
                                      <Typography
                                        fontSize={12}
                                        textAlign={"center"}
                                      >
                                        Task Budget{" "}
                                        {listingDetails?.budgetType ===
                                          "PER_HOUR" && "Per Hour"}
                                      </Typography>
                                      <Typography
                                        mt={1}
                                        fontSize={44}
                                        fontWeight={600}
                                        color={"#283350"}
                                      >
                                        € {listingDetails?.budget}
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                                <Box className="d-flex justify-content-between align-items-center mt--20">
                                  <Box
                                    className="d-flex align-items-center"
                                    gap={1}
                                  >
                                    <Box>
                                      <Avatar
                                        sx={{
                                          width: 40,
                                          height: 40,
                                          borderRadius: "50%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        variant="square"
                                        alt="Remy Sharp"
                                        src={
                                          listingDetails?.ownerUserProfile
                                            ?.userProfileImageUrl
                                        }
                                      />
                                    </Box>
                                    <Box>
                                      <Typography
                                        fontSize={12}
                                        fontWeight={"medium"}
                                      >
                                        POSTED BY
                                      </Typography>
                                      <Typography
                                        fontSize={12}
                                        sx={{
                                          color: "#283350",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {
                                          listingDetails?.ownerUserProfile
                                            ?.userDisplayName
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Typography fontSize={12}>
                                      {getTimeString(listingDetails.createTime)}
                                    </Typography>
                                  </Box>
                                </Box>
                                {listingDetails.listingLocation
                                  ?.locationPlaceId && (
                                  <>
                                    <Box className="mt--20 d-flex" gap={1}>
                                      <Box
                                        sx={{ minWidth: "40px" }}
                                        className="d-flex align-items-center justify-content-center"
                                      >
                                        <LocationSVGIcon
                                          size={"30"}
                                          color={"#283350"}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography
                                          fontSize={12}
                                          fontWeight={"medium"}
                                        >
                                          LOCATION
                                        </Typography>
                                        <Typography
                                          fontSize={12}
                                          sx={{
                                            color: "#283350",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {listingDetails.locationType ===
                                          "IN_PERSON"
                                            ? `${listingDetails?.listingLocation?.locationPlaceId}`
                                            : "Remote"}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </>
                                )}
                                <Box className="mt--20 d-flex" gap={1}>
                                  <Box
                                    sx={{ minWidth: "40px" }}
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    <CalenderIcon
                                      size={"30"}
                                      color={"#283350"}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography
                                      fontSize={12}
                                      fontWeight={"medium"}
                                    >
                                      TO BE DONE ON
                                    </Typography>
                                    <Typography
                                      fontSize={12}
                                      sx={{
                                        color: "#283350",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {["ON_DATE", "BEFORE_DATE"].includes(
                                        listingDetails.taskDueDateType
                                      )
                                        ? `On ${moment(
                                            listingDetails?.taskDueDate
                                          ).format("ddd, MMM DD")}`
                                        : "Flexible"}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="mt--20">
                                  <Typography
                                    sx={{ color: "#283350", fontWeight: "600" }}
                                  >
                                    Details
                                  </Typography>
                                  <Typography
                                    fontSize={14}
                                    mt={1}
                                    sx={{
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {listingDetails.description}
                                  </Typography>
                                </Box>
                                {!isEmpty(listingDetails?.listingMedias) && (
                                  <Box
                                    className=" d-flex align-items-center flex-wrap"
                                    sx={{
                                      marginTop: "30px",
                                      gap: "10px",
                                    }}
                                  >
                                    {listingDetails?.listingMedias.map(
                                      (item, index) => (
                                        <Avatar
                                          key={index}
                                          sx={{
                                            width: 80,
                                            height: 80,
                                            borderRadius: "8px",
                                            border: "1px solid black",
                                            display: "flex",
                                            cursor: "pointer",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => {
                                            setMediaPreview({
                                              index,
                                              action: true,
                                            });
                                          }}
                                          variant="square"
                                          alt="Image"
                                          src={item.mediaUrl}
                                        />
                                      )
                                    )}
                                  </Box>
                                )}
                              </Box>
                              {listingDetails?.budget && (
                                <Box className="task-budget-wrapper view-task-budget-wrapper d-flex justify-content-end align-items-start">
                                  <Box className="active-task budget-section d-flex justify-content-between align-items-center flex-column view-budget-section">
                                    <Typography
                                      fontSize={12}
                                      textAlign={"center"}
                                    >
                                      Task Budget{" "}
                                      {listingDetails?.budgetType ===
                                        "PER_HOUR" && "Per Hour"}
                                    </Typography>
                                    <Typography
                                      mt={1}
                                      fontSize={44}
                                      fontWeight={600}
                                      color={"#283350"}
                                    >
                                      € {listingDetails?.budget}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </>
                )}
            </>
          )}
        </main>
        <Footer />
      </div>
      {mediaPreview.action && (
        <TaskImagePreview
          open={mediaPreview.action}
          handleClose={() =>
            setMediaPreview({
              action: false,
              index: null,
            })
          }
          startPosition={mediaPreview.index}
          taskContent={listingDetails}
        />
      )}
    </>
  );
};

export default ViewTaskPage;
