import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InquiryPopup from "../../Modals/InquiryPopup";
import LoginPopUp from "../../Modals/LoginPopUp";
import OwnerContactPopUp from "../../Modals/OwnerContactPopUp";

const Sidebar = ({ listingDetails }) => {
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );

  const [listings, setListings] = useState(listingDetails);

  const [openContactPopUp, setOpenContactPopUp] = React.useState(false);
  const [openLoginPopUp, setOpenLoginPopUp] = React.useState(false);
  const [openInquiryPopup, setOpenInquiryPopup] = React.useState(false);

  useEffect(() => {
    setListings(listingDetails);
  }, [listingDetails]);

  const handleContact = () => {
    if (isLoggedIn) {
      setOpenInquiryPopup(true);
    } else {
      setOpenLoginPopUp(true);
    }
  };

  return (
    <aside className="col-lg-4 " id="sidebar">
      <div
        className=""
        style={{
          position: "sticky",
          top: `${
            listingDetails?.ownerUserProfile?.id === profileInfo?.id
              ? "16%"
              : "13.5%"
          }`,
        }}
      >
        <div className="box_detail booking">
          <div className="price">
            <button
              className="btn_Contact_Ad_Owner mx-1"
              style={{
                opacity:
                  listingDetails?.ownerUserProfile?.id === profileInfo?.id
                    ? 0.5
                    : 1,
                cursor:
                  listingDetails?.ownerUserProfile?.id === profileInfo?.id
                    ? "no-drop"
                    : "pointer",
              }}
              onClick={handleContact}
              disabled={
                listingDetails?.ownerUserProfile?.id === profileInfo?.id
              }
            >
              Send Your Inquiry
            </button>
          </div>

          <em>
            If you come across any suspicious or misleading ads, we encourage
            you to report them to us immediately at
            <a href="mailto:support@100xHelper.de"> support@100xHelper.de</a>.
            Your help in maintaining a safe and trustworthy environment is
            greatly appreciated.
          </em>
        </div>
      </div>
      {openContactPopUp && (
        <OwnerContactPopUp
          open={openContactPopUp}
          handleClose={() => setOpenContactPopUp(false)}
          listings={listings}
        />
      )}
      {openLoginPopUp && (
        <LoginPopUp
          open={openLoginPopUp}
          handleClose={() => {
            setOpenLoginPopUp(false);
          }}
        />
      )}
      {openInquiryPopup && (
        <InquiryPopup
          open={openInquiryPopup}
          handleClose={() => setOpenInquiryPopup(false)}
          profileInfo={profileInfo}
          listingDetails={listingDetails}
        />
      )}
    </aside>
  );
};

export default Sidebar;
