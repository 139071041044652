import React from "react";
import Footer from "../../../shared/Footer";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import StoryCard from "./StoryCard";

const About = () => {
  return (
    <div id="page">
      <PagesHeader />
      <div className="sub_header_in sticky_header">
        <div className="container p-2">
          <h1 className="wow bounceIn">About 100xHelper</h1>
        </div>
      </div>
      <main>
        <StoryCard />
      </main>
      <Footer />
    </div>
  );
};

export default About;
