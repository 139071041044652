import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const ListingDescription = ({ listingDetails }) => {
  const getAllCategories = useSelector(
    (state) => state.categoryReducer.categories
  );

  const [listings, setListings] = useState(listingDetails);
  const [categoryListingData, setCategoryListingData] =
    useState(listingDetails);

  const [category, setCategory] = useState(false);

  useEffect(() => {
    setListings(listingDetails);
  }, [listingDetails]);

  useEffect(() => {
    setCategory(getAllCategories);
  }, [getAllCategories]);

  useEffect(() => {
    if (category) {
      const listingCategory = category?.filter(
        (item) => item.id === listings?.categoryId
      );
      setCategoryListingData(listingCategory);
    }
  }, [category, listings?.categoryId]);

  const array1 = useMemo(
    () =>
      categoryListingData[0]?.categoryFormFields?.filter(
        (item) => item.formField.ffType !== "MULTI_CHECK"
      ),
    [categoryListingData]
  );

  const array2 = useMemo(
    () =>
      listings?.listingFormFields?.filter(
        (item) => item.categoryFormField.formField.ffType !== "MULTI_CHECK"
      ),
    [listings?.listingFormFields]
  );

  const extractedFormFields = array1?.filter(
    (field1) =>
      !array2?.some(
        (field2) =>
          field1.formField.id === field2.categoryFormField.formField.id
      )
  );

  const extractedFeatures = categoryListingData[0]?.categoryFeatures?.filter(
    (feature1) =>
      !listings?.listingFeatures?.some(
        (feature2) =>
          feature1.feature.id === feature2.categoryFeature.feature.id
      )
  );

  return (
    <section id="description">
      <div className="detail_title_1">
        <h2 className="my-2" style={{ color: "#777" }}>
          {listings?.title}
        </h2>
        <div className="d-flex flex-column">
          <div className="mt-1">{listings?.listingLocation?.addressLine1}</div>

          <div className="mt-2">{listings?.listingLocation?.addressLine2}</div>
        </div>

        <div className="mt-2">
          Posted By : {listings?.ownerUserProfile?.fullname}
        </div>
        {listings?.description !== "" && (
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-start mt-3 reviews-container">
              <div className="review-box clearfix">
                <div className="rev-content">
                  <h4>Description</h4>

                  <div className="rev-text">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: listings?.description,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {listings?.listingFormFields
        ?.filter(
          (item) => item.categoryFormField.formField.ffType === "MULTI_CHECK"
        )
        ?.map((val, index) => (
          <>
            <h5 className="add_bottom_15" key={index + 0}>
              {val?.categoryFormField?.formField?.ffName}
            </h5>
            <hr />
            <div className="row add_bottom_30" key={index + 1}>
              {val?.categoryFormField?.formField?.ffValue.map(
                (selectedItem, i) => (
                  <div className="col-lg-6" key={index + 2}>
                    <ul className="bullets">
                      <li key={i}>
                        {val.chosenValue.includes(selectedItem) ? (
                          <AssignmentTurnedInIcon
                            sx={{
                              fontSize: "18px",
                              marginRight: "0.5rem",
                              color: "#32a067",
                            }}
                          />
                        ) : (
                          <CancelIcon
                            sx={{
                              fontSize: "18px",
                              marginRight: "0.5rem",
                              color: "red",
                            }}
                          />
                        )}
                        {selectedItem}
                      </li>
                    </ul>
                  </div>
                )
              )}
            </div>
          </>
        ))}
      <h5 className="add_bottom_15">Listing Feature</h5>
      <hr />
      <div className="row add_bottom_30">
        {listings?.listingFeatures?.map((val, index) => (
          <>
            <div className="col-lg-6" key={index + 1}>
              <ul className="bullets">
                <li>
                  <AssignmentTurnedInIcon
                    sx={{
                      fontSize: "18px",
                      marginRight: "0.5rem",
                      color: "#32a067",
                    }}
                  />

                  {val?.categoryFeature?.feature?.featureName}
                </li>
              </ul>
            </div>
          </>
        ))}
        {extractedFeatures?.map((val, index) => (
          <div className="col-lg-6" key={index + 1}>
            <ul className="bullets">
              <li>
                <CancelIcon
                  sx={{
                    fontSize: "18px",
                    marginRight: "0.5rem",
                    color: "red",
                  }}
                />

                {val?.feature?.featureName}
              </li>
            </ul>
          </div>
        ))}
      </div>

      <div className="room_type first">
        <div className="row">
          <h5 className="add_bottom_15">Additional Details</h5>
          <hr />
          {listings?.listingFormFields
            ?.filter(
              (item) =>
                item.categoryFormField.formField.ffType !== "MULTI_CHECK"
            )
            ?.map((val, index) => (
              <>
                <div className="col-md-6" key={index}>
                  <p style={{ color: "#000", fontWeight: "800" }}>
                    {val?.categoryFormField?.formField?.ffName}&nbsp;:&nbsp;{" "}
                    {val?.chosenValue ? (
                      <span
                        className="text-muted"
                        style={{ fontWeight: "700" }}
                      >
                        {val?.chosenValue.toString()}
                      </span>
                    ) : (
                      <span
                        className="text-muted"
                        style={{ fontWeight: "500" }}
                      >
                        -
                      </span>
                    )}
                  </p>
                </div>
              </>
            ))}
          {extractedFormFields?.map((val, index) => (
            <div className="col-md-6" key={index}>
              <p style={{ color: "#000", fontWeight: "600" }}>
                {val?.formField?.ffName}&nbsp;:&nbsp;{" "}
                <span className="text-muted" style={{ fontWeight: "500" }}>
                  -
                </span>
              </p>
            </div>
          ))}
        </div>
      </div>

      {listings?.listingBusinessHours.length && (
        <>
          <h5 className="add_bottom_15">Business Hours</h5>
          <hr />
          <div className="row my-4">
            <div className="col-md-12">
              <div className="opening">
                <div className="ribbon">
                  <span className="open">Now Open</span>
                </div>
                <i className="fa fa-clock"></i>
                <h4>Opening Hours</h4>
                <div className="row">
                  <div className="col-md-6">
                    <ul>
                      {listings?.listingBusinessHours?.map((time, index) => (
                        <>
                          <li key={index}>
                            {time?.dayOfWeek?.charAt(0)?.toUpperCase() +
                              time?.dayOfWeek?.toLowerCase()?.slice(1)}{" "}
                            <span>
                              {time?.slot1StartTime} - {time?.slot1EndTime}
                            </span>
                            {time?.slot2StartTime && time?.slot2EndTime ? (
                              <>
                                <br />
                                <span>
                                  {time?.slot2StartTime} - {time?.slot2EndTime}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </li>
                          {time?.slot2StartTime && time?.slot2EndTime ? (
                            <br />
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="row mt-3">
        <div className="col-lg-12 d-flex justify-content-start mt-3 reviews-container">
          <div className="review-box clearfix">
            <div className="rev-content">
              <div className="rev-text">
                <em>
                  Safety Notice: Protect Yourself from Scammers Please exercise
                  caution and remain vigilant when engaging with ad owners on
                  our platform. Before making any financial transactions or
                  sharing personal documents, ensure that you have verified the
                  legitimacy of the ad owner and their offer. Be cautious of
                  potential scams and fraudulent activities. Only proceed when
                  you are absolutely certain about the genuineness of the
                  transaction. Your security and peace of mind are our top
                  priorities
                </em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListingDescription;
