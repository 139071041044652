import React, { useState } from "react";
import PagesHeader from "../../../../shared/Navbar/PagesHeader";
import OrganizerSelect from "./OrganizerSelect";
import TitleCard from "./TitleCard";
import EventLocation from "./EventLocation";
import TimeAndDateCard from "./TimeAndDateCard";
import EventDescription from "./EventDescription";
import SubmitEventBtn from "./SubmitEventBtn";
import { toast } from "react-toastify";

const AddEvent = () => {
  const [title, setTitle] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [description, setDescrription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!organizer) {
      toast.error("Please select organizer");
    } else if (!title) {
      toast.error("Please enter title");
    } else if (!location) {
      toast.error("Please select location.");
    } else if (!startDate) {
      toast.error("Please select start date");
    } else if (!endDate) {
      toast.error("Please select end date");
    } else if (!startTime) {
      toast.error("Please select start time");
    } else if (!endTime) {
      toast.error("Please select end time ");
    } else if (!description) {
      toast.error("Please enter description. ");
    } else {
    }
  };

  return (
    <div id="page">
      <PagesHeader />
      <main className="call_section ">
        <div className="sub_header_in sticky_header">
          <div className="container">
            <h1 className="wow bounceIn">Add Event</h1>
          </div>
        </div>
        <OrganizerSelect setOrganizer={setOrganizer} />
        <TitleCard setTitle={setTitle} />
        <EventLocation setLocation={setLocation} />
        <TimeAndDateCard
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
        />
        <EventDescription setDescrription={setDescrription} />
        <SubmitEventBtn handleSubmit={handleSubmit} />
      </main>
    </div>
  );
};

export default AddEvent;
