import React from "react";
import EventDetails from "../../../components/USER/Events/EventDetails";

const ManageEventsPage = () => {
  return (
    <div>
      <EventDetails />
    </div>
  );
};

export default ManageEventsPage;
