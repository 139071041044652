import React from "react";

const CardWrapper = ({ children }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-10 col-md-10">
          <div className="box_account">
            <div className="form_container form-group">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWrapper;
