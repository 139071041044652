import { getListingCategories } from "../../helper/APIs/USER/Listing";
import {
  GET_LISTING_CATEGORY_SUCCESSFULL,
  GET_LISTING_CATEGORY_PROCESSING,
  GET_LISTING_CATEGORY_STOP_PROCESSING,
} from "./type";

export const getCategory = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: GET_LISTING_CATEGORY_PROCESSING });
    getListingCategories()
      .then((category) => {
        if (category.success) {
          dispatch({
            type: GET_LISTING_CATEGORY_SUCCESSFULL,
            payload: category.data,
          });
          resolve(category);
        }
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: GET_LISTING_CATEGORY_STOP_PROCESSING });
      });
  });
};
