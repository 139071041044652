import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoginPopUp from "../../components/USER/Modals/LoginPopUp";
import RegisterPopUp from "../../components/USER/Modals/RegisterPopUp";
import { CategoryEnum } from "../Constants/enum";
import { CURRENT_YEAR } from "../Constants/util-functions";

const Footer = () => {
  const getAllCategories = useSelector(
    (state) => state?.categoryReducer?.categories
  );
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);

  const navigate = useNavigate();

  const [collapse, setCollapse] = useState(0);
  const [openRegisterPopUp, setOpenRegisterPopUp] = useState(false);
  const [openLoginPopUp, setOpenLoginPopUp] = React.useState(false);
  const [categories, setCategories] = useState(getAllCategories);

  useEffect(() => {
    setCategories(getAllCategories);
  }, [getAllCategories]);

  const handleCategory = (id) => {
    navigate(`/ads/category/${id}`);
    localStorage.setItem("categoryId", JSON.stringify(id));
  };
  const handleLinks = (category) => {
    const categoryArr = categories.filter(
      (item) => item.categoryName === category && item.id
    );
    navigate(`/ads/category/${categoryArr[0].id}`);
    localStorage.setItem("categoryId", JSON.stringify(categoryArr[0].id));
  };

  const handleRegister = () => {
    setOpenRegisterPopUp(true);
  };
  const handlePostRequirement = () => {
    if (isLoggedIn) {
      navigate("/add-listing");
    } else {
      setOpenLoginPopUp(true);
    }
  };

  return (
    <footer className="plus_border">
      <div
        className="container"
        style={{ paddingTop: "60px", paddingBottom: "35px" }}
      >
        <div className="row">
          <div className="col-lg-2 col-md-6 col-sm-6">
            <h3
              data-bs-target="#collapse_ft_1"
              onClick={() => setCollapse(collapse === 1 ? 0 : 1)}
            >
              100xHelper
            </h3>
            <div
              className={`not-collapse-sm ${collapse === 1 ? "" : "collapse"}`}
              id="1"
            >
              <ul className="links">
                {isLoggedIn ? (
                  <li>
                    <Link to="/my-profile">My Profile</Link>
                  </li>
                ) : (
                  <li>
                    <Link onClick={handleRegister}>Create Account</Link>
                  </li>
                )}
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/imprint">Imprint</Link>
                </li>
                 <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/copyright">Copyright Statement</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6">
            <h3
              data-bs-target="#2"
              onClick={() => setCollapse(collapse === 2 ? 0 : 2)}
            >
              Categories
            </h3>
            <div
              className={`not-collapse-sm ${collapse === 2 ? "" : "collapse"}`}
              id="2"
            >
              <ul className="links">
              {categories?.map((val, index) => {
                if (index < 6 || index === categories.length - 1) {
                  return (
                    <li
                      key={index}
                      onClick={() => handleCategory(val.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {val.categoryName}
                    </li>
                  );
                } else {
                  return null;
                }
              })}
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6">
            <h3
              data-bs-target="#3"
              onClick={() => setCollapse(collapse === 3 ? 0 : 3)}
            >
              We are here to help!
            </h3>
            <div
              className={`not-collapse-sm ${collapse === 3 ? "" : "collapse"}`}
              id="3"
            >
              <ul className="contacts">
                <li>
                  <i className="fa fa-solid fa-headset"></i>
                  <a
                    href="https://wa.me/4915167824246"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (+49)151-6782-4246
                  </a>
                </li>
                <li>
                  <i className="fa fa-regular fa-envelope"></i>
                  <a href="mailto:info@100xHelper.de">info@100xHelper.de</a>
                </li>
              </ul>
              <div className="follow_us">
                <h5>Follow Us</h5>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/100xHelper"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-brands fa-facebook-f"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/100xHelper/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-brands fa-instagram"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.linkedin.com/company/100xhelper/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6">
            <h3
              data-bs-target="#collapse_ft_1"
              onClick={() => setCollapse(collapse === 4 ? 0 : 4)}
            >
              How 100xHelper Works!
            </h3>
            <div
              className={`not-collapse-sm ${collapse === 4 ? "" : "collapse"}`}
              id="4"
            >
              <ul className="links">
                <li>
                  <a href="/#how-it-works">Post Your Task</a>
                </li>
                <li>
                  <a href="/#how-it-works">Connect with Helpers</a>
                </li>
                <li>
                  <a href="/#how-it-works">Discuss Payment Terms</a>
                </li>
                <li>
                  <a href="/#how-it-works">Complete the Task</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <h3
              data-bs-target="#2"
              onClick={() => setCollapse(collapse === 5 ? 0 : 5)}
            >
              How 100xHelper Can Help You!
            </h3>
            <div
              className={`not-collapse-sm ${collapse === 5 ? "" : "collapse"}`}
              id="4"
            >
              <div
                className={`not-collapse-sm ${
                  collapse === 5 ? "" : "collapse"
                }`}
                id="2"
              >
                <ul className="">
                  <li>
                    <Link to="/blogs/blog-1">Reliable Cleaning Help in Berlin via 100xHelper</Link>
                  </li>
                  <li>
                    <Link to="/blogs/blog-2">Simplify Laundry with 100xHelper Helpers</Link>
                  </li>
                  <li>
                    <Link to="/blogs/blog-3">Pet Care in Berlin: Find Help with 100xHelper</Link>
                  </li>
                  <li>
                    <Link to="/blogs/topBlog-1">Save Time with 100xHelper's Errand Services</Link>
                  </li>
                  <li>
                    <Link to="/blogs/topBlog-2">Translation Services: Overcome Language Barriers</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /row--> */}
        <hr />
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="additional_links">
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/copyright">
                  Copyright © {CURRENT_YEAR} 100xHelper. All Rights Reserved
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {openRegisterPopUp && (
        <RegisterPopUp
          open={openRegisterPopUp}
          handleClose={() => setOpenRegisterPopUp(false)}
          closeLogin={() => setOpenRegisterPopUp(false)}
        />
      )}
      {openLoginPopUp && (
        <LoginPopUp
          open={openLoginPopUp}
          handleClose={() => setOpenLoginPopUp(false)}
        />
      )}
    </footer>
  );
};

export default Footer;
