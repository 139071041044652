// AUTH APIs
export const LOGIN_USER = "auth/authenticate";
export const REGISTER_USER = "auth/register";
export const FORGOT_PASSWORD = "otp";
export const VERIFY_FORGOT_PASSWORD = "login-info/forgot-password";
export const VERIFY_EMAIL_OTP = "users/emailVerified";
export const CHANGE_PASSWORD = "login-info/password";
export const GET_USER_PROFILE = "users/my";
export const EDIT_USER_PROFILE = "users";
export const LOGOUT_USER = "auth/logout";
export const GOOGLE_AUTH = "auth/google";

export const GET_CATEGORIES = "categories";
export const ADD_LISTING = "listings";
export const LISTING_MEDIA = "multimedia?multimediaObjectType=LISTING_MEDIA";
export const PROFILE_MEDIA = "multimedia?multimediaObjectType=USER_PROFILE_PIC";
export const GET_MY_LISTINGS = "listings/my";
export const GET_EDIT_LISTINGS = "listings";
export const EDIT_LISTING = "listings";
export const DELETE_LISTING = "listings";
export const COMPLETE_LISTING = "listings";

export const GET_ALL_CHAT_USER = "chats";
export const INITIATE_NEW_CHAT = "chats";
export const SEND_MESSAGE = "messages";
export const GET_MESSAGE = "messages/chat";

export const GET_NOTIFICATIONS = "notifications";
export const UPDATE_NOTIFICATION_STATUS = "notifications/status";

export const GET_SENDER_PROFILE = "users/public-profile";
