import React from "react";

const ContactForm = () => {
  return (
    <div className=" margin_60_35">
      <div className="row equal-height-row">
        <div className="col-12 col-xl-6 col-lg-6 pl-xl-6 ">
          <div className="box_contacts">
            <i className="ti-support"></i>
            <h2>Need Help? Or Do You Have Feedback For Us?</h2>
            <p>
              Then do not hesitate to drop us a message on WhatsApp or write us
              an email.
            </p>
            <span>Mobile/WhatsApp : (+49)151-6782-4246</span>
            <br />
            <span>
              Email : <a href="mailto:info@100xHelper.de">info@100xHelper.de</a>
            </span>
          </div>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 pl-xl-6 ">
          <div className="box_contacts">
            <i className="ti-home"></i>
            <h2>Address</h2>
            Angerburger Allee 53, 14055, Berlin, Germany
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
