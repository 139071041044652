import ChatIcon from "@mui/icons-material/Chat";
import LoginIcon from "@mui/icons-material/Login";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton, Menu, MenuItem, SwipeableDrawer } from "@mui/material";
import { isEmpty, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoginPopUp from "../../../components/USER/Modals/LoginPopUp";
import Logout from "../../../components/USER/Modals/Logout";
import NotificationsPage from "../../../components/USER/Notifications/NotificationsPage";
import * as loginLocationActions from "../../../redux/LoginLocation/actions";
import { CONTACT_US_MENU } from "../../Constants/constants";
import ProfileBtn from "../ProfileBtn";
import SidebarList from "../SidebarList";

import { useState } from "react";
import "./updated-header.css";
import TaskDetails from "./TaskStepper/taskDetails";
import BecomeTasker from "./BecomeTasker/becomeTasker";
import { BECOME_TASKER_STATUS } from "../../Constants/enum";

const UpdatedHeader = ({
  categories,
  openNotificationDrawer,
  closeNotificationDrawer,
  unReadNotification,
  state,
  setLogoutOpen,
  openSidebar,
  closeSidebar,
  handleCategory,
  logoutOpen,
  handleNotificationClick,
  handleLoginRegister,
}) => {
  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state?.loginReducer?.isLoggedIn);
  const isLoginPopup = useSelector(
    (state) => state?.loginLocationReducer?.isLoginPopup
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTasker, setIsOpenTasker] = useState(false);

  const handleRedirect = (action) => {
    if (isLoggedIn) {
      if (action === "become_tasker") {
        setIsOpenTasker(true);
      }
    } else {
      dispatch(loginLocationActions.updateLoginPopup(true));
    }
    setAnchorEl(null);
  };

  return (
    <>
      <nav className="top-header">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div
              id="menu-icon"
              className="justify-content-center align-items-center"
            >
              <i
                className="fa-solid fa-bars menu-hamburger"
                onClick={openSidebar}
              ></i>
              <SwipeableDrawer
                anchor={"left"}
                open={state["left"]}
                onClose={closeSidebar}
                onOpen={openSidebar}
                PaperProps={{
                  style: {
                    width: 350,
                    height: "100%",
                  },
                }}
              >
                <SidebarList
                  closeSidebar={closeSidebar}
                  unReadNotification={unReadNotification}
                  handleNotificationClick={handleNotificationClick}
                  setLogoutOpen={setLogoutOpen}
                />
              </SwipeableDrawer>
            </div>

            <div
              className="top-logo"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            >
              <img
                src="/assets/img/LOGO/100xhelper/100xhelper.svg"
                alt="logo"
                className="logo_sticky"
              />
            </div>
            {profileInfo &&
              profileInfo?.helperApplicationStatus !==
                BECOME_TASKER_STATUS.CONCLUDED && (
                <button
                  type="button"
                  title={
                    profileInfo?.helperApplicationStatus ===
                    BECOME_TASKER_STATUS.PENDING
                      ? "Your Application in pending"
                      : ""
                  }
                  className={`become-tasker-button ${
                    profileInfo?.helperApplicationStatus ===
                    BECOME_TASKER_STATUS.PENDING
                      ? "opacity--50 cursor-disabled"
                      : ""
                  }`}
                  disabled={
                    profileInfo?.helperApplicationStatus ===
                    BECOME_TASKER_STATUS.PENDING
                  }
                  onClick={() => handleRedirect("become_tasker")}
                >
                  Become a Helper
                </button>
              )}
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <ul className="top-header-menu top-menu_desktop">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <div className="accommodation-dropdown">
                  <Link to="#" className="dropbtn">
                    Browse Tasks
                  </Link>
                  <div className="dropdown-content header-all-task-dropdown">
                    {!isEmpty(categories) && (
                      <span
                        onClick={() => handleCategory("all")}
                        style={{ cursor: "pointer" }}
                      >
                        Browse all tasks
                      </span>
                    )}
                    {categories.length &&
                      categories?.map((val, index) => (
                        <span
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleCategory(val.id)}
                        >
                          {val.categoryName}
                        </span>
                      ))}
                  </div>
                </div>
              </li>
              {/* <li>
                <Link to={"/blogs"}>Blogs</Link>
              </li> */}
              <li className="mr-3">
                <div className="accommodation-dropdown">
                  <Link to="#" className="dropbtn">
                    Contact Us
                  </Link>
                  <div className="dropdown-content">
                    {CONTACT_US_MENU.length
                      ? CONTACT_US_MENU?.map((val, index) => (
                          <Link to={val.route} key={index}>
                            {val.key}
                          </Link>
                        ))
                      : ""}
                  </div>
                </div>
              </li>
            </ul>
            <ul className="top-header-menu top-menu-with_icon">
              {isLoggedIn && (
                <>
                  <li>
                    <Link to="/chat" className="text-black-50">
                      <ChatIcon fontSize="medium" />
                    </Link>
                  </li>
                  <li className="position-relative">
                    <Link
                      className="text-black-50 cursor-pointer"
                      onClick={openNotificationDrawer}
                    >
                      <NotificationsIcon fontSize="medium" />
                      {!isNull(unReadNotification) &&
                        unReadNotification !== 0 && (
                          <span className="rounded-circle p-1 text-white position-absolute notification-tooltip d-flex justify-content-center align-items-center text-align-center">
                            {unReadNotification}
                          </span>
                        )}
                    </Link>
                    <SwipeableDrawer
                      anchor={"right"}
                      open={state["right"]}
                      onClose={closeNotificationDrawer}
                      onOpen={openNotificationDrawer}
                      PaperProps={{
                        style: {
                          width: 350,
                        },
                      }}
                    >
                      <NotificationsPage
                        isOpen={state["right"]}
                        handleClose={closeNotificationDrawer}
                      />
                    </SwipeableDrawer>
                  </li>
                </>
              )}
              {!isLoggedIn && (
                <>
                  <li className="post-your-add-btn margin-20">
                    <Link
                      to="#"
                      onClick={handleLoginRegister}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Register or Login &nbsp;
                      <LoginIcon sx={{ fontSize: "18px" }} />
                    </Link>
                  </li>
                  <li className="post-your-add-btn-mobile">
                    <Link
                      to="#"
                      onClick={handleLoginRegister}
                      style={{ cursor: "pointer" }}
                    >
                      <LoginIcon sx={{ fontSize: "26px" }} />
                    </Link>
                  </li>
                </>
              )}
              <li
                className={`${isLoggedIn ? "margin-20" : ""} post-your-add-btn`}
              >
                <button
                  type="button"
                  className="post-btn"
                  onClick={() => navigate("/add-task")}
                >
                  Post Your Task
                </button>
              </li>

              <li className="post-your-add-btn-mobile cursor-pointer">
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <i class="fa-regular fa-square-plus"></i>
                  {/* <i className="fa-solid fa-circle-plus logout-icon"></i> */}
                </IconButton>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => navigate("/add-task")}>
                    Post Your Task
                  </MenuItem>
                  {profileInfo &&
                    profileInfo?.helperApplicationStatus !==
                      BECOME_TASKER_STATUS.CONCLUDED && (
                      <MenuItem
                        className={
                          profileInfo?.helperApplicationStatus ===
                          BECOME_TASKER_STATUS.PENDING
                            ? "opacity--50 cursor-disabled"
                            : ""
                        }
                        onClick={() =>
                          profileInfo?.helperApplicationStatus !==
                            BECOME_TASKER_STATUS.PENDING &&
                          handleRedirect("become_tasker")
                        }
                        title={
                          profileInfo?.helperApplicationStatus ===
                          BECOME_TASKER_STATUS.PENDING
                            ? "Your Application in pending"
                            : ""
                        }
                      >
                        Become a Helper
                      </MenuItem>
                    )}
                </Menu>
              </li>
              {isLoggedIn && (
                <>
                  <li className="profile_icon">
                    <ProfileBtn
                      setLogoutOpen={setLogoutOpen}
                      className={"icon-user-1 chat-icon profile-btn"}
                    />
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      {logoutOpen && (
        <Logout open={logoutOpen} handleClose={() => setLogoutOpen(false)} />
      )}
      {isLoginPopup && (
        <LoginPopUp
          open={isLoginPopup}
          handleClose={() => {
            dispatch(loginLocationActions.updateLoginPopup(false));
          }}
        />
      )}
      {isOpen && (
        <TaskDetails handleClose={() => setIsOpen(false)} isOpen={isOpen} />
      )}
      {isOpenTasker && (
        <BecomeTasker
          handleClose={() => setIsOpenTasker(false)}
          isOpen={isOpenTasker}
        />
      )}
    </>
  );
};

export default UpdatedHeader;
