import {
  EDIT_LISTING_DATA,
  SINGLE_LISTING_DATA,
  REMOVE_EDIT_LISTING,
  LISTING_LIST_DATA,
} from "./type";

const defaultReducer = {
  listingDetail: null,
  editListing: null,
  isEdit: false,
  listingListData: [],
  pageAble: null,
};

const listingReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case SINGLE_LISTING_DATA:
      return {
        ...state,
        listingDetail: action.payload,
        editListing: null,
        isEdit: false,
      };
    case EDIT_LISTING_DATA:
      return {
        ...state,
        listingDetail: null,
        editListing: action.payload,
        isEdit: true,
      };
    case REMOVE_EDIT_LISTING:
      return {
        ...state,
        listingListData: [],
        placeId: null,
        address: null,
        countryId: null,
      };
    case LISTING_LIST_DATA:
      return {
        ...state,
        listingListData: action.payload.payload,
        pageAble: action.payload.pageAble,
      };

    default:
      return state;
  }
};

export default listingReducer;
