import { combineReducers } from "redux";
import loginReducer from "./Auth";
import listingReducer from "./Listing";
import categoryReducer from "./ListingCategory";
import listingApiReducer from "./ListingLocation";
import messageReducer from "./Message";
import notificationReducer from "./Notifications";
import loginLocationReducer from "./LoginLocation";

const rootReducer = combineReducers({
  // ALL THE REDUCERS
  loginReducer,
  listingReducer,
  categoryReducer,
  listingApiReducer,
  messageReducer,
  notificationReducer,
  loginLocationReducer,
});

export default rootReducer;
