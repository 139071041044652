import React from "react";

const StoryCard = () => {
  return (
    <div className="bg_color_1">
      <div className="container margin_80_55">
        {/* <div className="main_title_2">
          <span>
            <em></em>
          </span>
          <h2 className="wow zoomIn">Imprint</h2>
        </div> */}
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-12 wow bounceInUp">
            <p>Imprint</p>
            <p>
              Legal Disclosure <br /> Information in accordance with Section 5
              TMG: <br /> Shruti Gada Einzelunternehmen <br /> Angerburger Allee
              53, 14055 Berlin, Germany
            </p>
            <p>
              Contact Information: <br /> Telephone: +49 151 67824246 <br />{" "}
              Email: info@100xhelper.de
              <br /> Represented by: <br />
              Shruti Gada <br /> Tax ID: <br /> Steuernummer: 13/268/01942{" "}
              <br /> Responsible for content according to Section 55(2) RStV:{" "}
              <br /> Shruti Gada (address as above)
            </p>
            <p>
              German Version <br /> Impressum <br /> Angaben gemäß § 5 TMG:
              <br /> Shruti Gada Einzelunternehmen
              <br /> Angerburger Allee 53, 14055 Berlin, Deutschland
            </p>
            <p>
              Kontaktinformationen:
              <br />
              Telefon: +49 151 67824246
              <br />
              E-Mail: info@100xhelper.de
              <br />
              Vertreten durch:
              <br />
              Shruti Gada
              <br />
              Steuer-ID: <br />
              Steuernummer: 13/268/01942
              <br />
              Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
              <br />
              Shruti Gada (Anschrift wie oben)
            </p>
          </div>
        </div>
        {/* <!--/row--> */}
      </div>
      {/* <!--/container--> */}
    </div>
  );
};

export default StoryCard;
