import React from "react";
import ChatHeader from "./ChatHeader";
import Compose from "./Compose";
import Message from "./Message";

const ChatContent = ({ selectedUser, handleSendMessage, setShowProfile }) => {
  return (
    <>
      <div className="user-chat w-100 overflow-hidden user-chat-show">
        <div className="d-lg-flex">
          <div className="w-100 overflow-hidden position-relative">
            <ChatHeader
              selectedUser={selectedUser}
              setShowProfile={setShowProfile}
            />

            <Message setShowProfile={setShowProfile} />

            <Compose handleSendMessage={handleSendMessage} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatContent;
