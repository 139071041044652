import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { gapi } from "gapi-script";
import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GOOGLE_CLIENT_ID } from "../../../config";
import {
  forgotPassword,
  googleAuth,
  verifyEmailOtp,
} from "../../../helper/APIs/Auth";
import * as loginAction from "../../../redux/Auth/action";
import * as loginLocationActions from "../../../redux/LoginLocation/actions";
import InputFields from "../../../shared/InputFieldsWithLabels";
import PasswordFields from "../../../shared/InputFieldsWithLabels/PasswordFields";
import RegisterPopUp from "./RegisterPopUp";

const LoginPopUp = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [openRegisterPopUp, setOpenRegisterPopUp] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(0);
  const [isLogin, setIsLogin] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    pwd: "",
  });
  const [otp, setOtp] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const registrationData = useSelector((state) => state.loginReducer.data);
  const prevPath = useSelector(
    (state) => state?.loginLocationReducer?.prevPath
  );

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const handleChange = (e) => {
    setError("");
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!formValues.email) {
        throw new Error("Please enter email.");
      }
      if (!formValues.pwd) {
        throw new Error("Please enter password.");
      }

      const res = await dispatch(loginAction.loginUser(formValues, "user"));
      if (res.status === 200) {
        setLoading(false);
        dispatch(loginLocationActions.updatePreviousRoute("/"));
        navigate(`${prevPath}`);
        handleClose();
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.status === 403 && err?.response?.data) {
        setError(err.response.data.errors.toString());
        try {
          const submitData = {
            email: formValues.email,
            otpType: "EMAIL_VERIFICATION_OTP",
          };
          const res = await forgotPassword(submitData);
          if (res.success) {
            setLoading(false);
            setIsLogin(true);
            setStep(1);
          }
        } catch (err) {
          setLoading(false);
          if (err?.response?.status === 403) {
            setError("Please check entered email.");
          }
        }
      } else {
        setError("Please enter valid email and password.");
      }
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmitStepOne = (e) => {
    e.preventDefault();
    setLoading(true);
    let submitData = { email: formValues.email, otp };
    if (!otp) {
      toast.error("Please enter OTP");
      setLoading(false);
    } else {
      verifyEmailOtp(submitData)
        .then((res) => {
          if (res.success) {
            setLoading(false);
            if (isLogin) {
              const data = {
                email: formValues.email,
                pwd: formValues.pwd,
                isLogin,
              };

              dispatch(loginAction.loginUser(data, "user"))
                .then((res) => {
                  if (res.status === 200) {
                    navigate("/");
                    setLoading(false);
                    handleClose();
                  }
                })
                .catch((err) => {
                  setLoading(false);

                  if (err?.response?.status === 403 && err?.response?.data) {
                    toast.error(err.response.data.errors.toString());
                  } else {
                    toast.error("Enter required parameters.");
                  }
                });
            } else {
              navigate("/");
              dispatch(loginAction.setRegisterUser(registrationData));
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const responseGoogle = (response) => {
    if (response?.tokenObj?.id_token) {
      const submitData = {
        idToken: response.tokenObj.id_token,
      };
      googleAuth(submitData).then((res) => {
        if (res.success) {
          dispatch(loginAction.setLoginUser(res, "user"));
          handleClose();
          toast.success("Login Successfully.");
          dispatch(loginLocationActions.updatePreviousRoute("/"));
          navigate(`${prevPath}`);
        }
      });
    }
  };
  const onFailure = (err) => {
    toast.error("User not exists.");
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>
          <div className="small-dialog-header d-flex justify-content-between">
            <h3>Sign In</h3>
            <CancelIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div id="sign-in-dialog" className="zoom-anim-dialog ">
            {step === 0 && (
              <form className="" onSubmit={handleSubmit}>
                <div className="sign-in-wrapper">
                  <div className="access_social">
                    <GoogleLogin
                      clientId={GOOGLE_CLIENT_ID}
                      icon={true}
                      theme="dark"
                      // onClick={responseGoogle}
                      onSuccess={responseGoogle}
                      onFailure={onFailure}
                      cookiePolicy={"single_host_origin"}
                      className="social_bt"
                    />
                  </div>
                  <div className="divider">
                    <span>Or</span>
                  </div>
                  <InputFields
                    label="Email"
                    type="email"
                    id="email"
                    iconClass="fa fa-envelope"
                    name="email"
                    placeholder="Enter Email"
                    required={true}
                    onChange={handleChange}
                    defaultValue={formValues.email}
                  />
                  <PasswordFields
                    label="Password"
                    type="password"
                    id="password"
                    iconClass="fa fa-lock"
                    name="pwd"
                    placeholder="Enter Password"
                    onChange={handleChange}
                    defaultValue={formValues.pwd}
                    required={false}
                  />
                  <div className="clearfix add_bottom_30">
                    <div className="float-end mt-1">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                  </div>
                  <div
                    id="pass-info"
                    className="clearfix"
                    style={{ color: "red", fontSize: "1rem", fontWeight: 600 }}
                  >
                    {error}
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn_1 rounded full-width"
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Login Now
                  </button>
                  {/* </Link> */}
                  <div className="text-center add_top_10">
                    New to 100xHelper?{" "}
                    <strong>
                      <span
                        className="auth_footer_link"
                        onClick={() => {
                          setOpenRegisterPopUp(true);
                          // handleClose();
                        }}
                      >
                        Register Now!
                      </span>
                    </strong>
                  </div>
                </div>
                <div className="copy text-center add_top_10 text-muted">
                  Copyright © {new Date().getFullYear()}&nbsp; 100xHelper
                  <br /> All Rights Reserved
                </div>
              </form>
            )}
            {step === 1 && (
              <form className="was-validated" onSubmit={handleSubmitStepOne}>
                <div className="sign-in-wrapper">
                  <InputFields
                    label="OTP"
                    type="text"
                    id="otp"
                    name="otp"
                    iconClass="fa fa-keyboard-o"
                    onChange={handleOtpChange}
                    required={true}
                  />

                  <button
                    type="submit"
                    className="btn_1 rounded full-width"
                    disabled={loading}
                    onClick={handleSubmitStepOne}
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Submit
                  </button>
                  {/* </Link> */}
                  <div className="text-center add_top_10">
                    New to 100xHelper?{" "}
                    <strong>
                      <Link
                        to="#"
                        onClick={() => {
                          setOpenRegisterPopUp(true);
                          // handleClose();
                        }}
                      >
                        Register Now!
                      </Link>
                    </strong>
                  </div>
                </div>
                <div className="copy text-center add_top_10 text-muted">
                  Copyright © {new Date().getFullYear()}&nbsp; 100xHelper
                  <br /> All Rights Reserved
                </div>
              </form>
            )}
          </div>
        </DialogContent>
        {openRegisterPopUp && (
          <RegisterPopUp
            open={openRegisterPopUp}
            handleClose={() => setOpenRegisterPopUp(false)}
            closeLogin={handleClose}
          />
        )}
      </Dialog>
    </div>
  );
};

export default LoginPopUp;
