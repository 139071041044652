import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import EventTable from "./EventTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "1rem 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TabsPanel = ({ value }) => {
  return (
    <div className="col-12">
      <TabPanel value={value} index={0}>
        <EventTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EventTable />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <EventTable /> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EventTable />
      </TabPanel>
    </div>
  );
};

export default TabsPanel;
