import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAllChatUsers,
  getMessageById,
  getSenderProfileId,
  sendMessage,
} from "../../../helper/APIs/USER/Chat";
import * as messageAction from "../../../redux/Message/action";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import ChatContent from "./ChatContent";
import UsersList from "./Userslist";
import "./chat.css";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import { isEmpty } from "lodash";
import { Box } from "@mui/material";
import ShowProfilePopup from "./showProfilePopup";

const Chats = () => {
  const allMessages = useSelector((state) => state.messageReducer.messages);
  const loginReducer = useSelector(
    (state) => state.loginReducer.user.data.userProfile
  );
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserListLoading, setIsUserListLoading] = useState(true);
  const [isChatLoading, setIsChatLoading] = useState(true);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [senderProfileData, setSenderProfileData] = useState({});

  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();
  const loginUserId = loginReducer?.id || "";
  const { id } = useParams();
  const intervalIdForUser = useRef();
  const intervalIdForMessage = useRef();

  const fetchProfileData = async (id) => {
    setIsProfileLoading(true);
    const res = await getSenderProfileId(id);
    setIsProfileLoading(false);
    setSenderProfileData(res.data);
  };

  const fetchData = async (selectedUser = null) => {
    try {
      const res = await getAllChatUsers();
      const userList = res.data.content;
      setUserList(userList);
      setIsUserListLoading(false);

      const newUser = id
        ? userList.find((item) => item.id === id)
        : userList[0];
      if (window.innerWidth > 991.98) {
        if (newUser && !selectedUser) {
          setSelectedUser(newUser);
          const id =
            loginUserId === newUser?.chatInitiator?.userProfileId
              ? newUser?.listingOwner?.userProfileId
              : newUser?.chatInitiator?.userProfileId;
          fetchProfileData(id);
          navigate(`/chat/${newUser?.id}`);
        }
      }
      clearInterval(intervalIdForUser.current);
      intervalIdForUser.current = null;
      intervalIdForUser.current = setInterval(() => fetchData(newUser), 16000);
    } catch (error) {
      setIsUserListLoading(false);
      console.error("Error fetching chat users:", error);
    }
  };

  const getMessages = async (selectedUserId) => {
    try {
      const res = await getMessageById({
        id: selectedUserId,
      });
      dispatch(messageAction.addMessage([...res.data.content]));
      clearInterval(intervalIdForMessage.current);
      intervalIdForMessage.current = null;
      intervalIdForMessage.current = setInterval(
        () => getMessages(selectedUserId),
        8000
      );
      setIsChatLoading(false);
    } catch (error) {
      setIsChatLoading(false);
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUser?.id) {
      getMessages(selectedUser?.id);
    } else {
      setIsChatLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  useEffect(() => {
    return () => {
      clearInterval(intervalIdForMessage.current);
      clearInterval(intervalIdForUser.current);
      intervalIdForUser.current = null;
      intervalIdForMessage.current = null;
    };
  }, [intervalIdForMessage, intervalIdForUser]);

  const handleUserClick = (user) => {
    clearInterval(intervalIdForMessage.current);
    intervalIdForMessage.current = null;
    setUserList((prevUserList) => {
      const updatedUserList = prevUserList.map((e) => {
        if (e.id === user.id) {
          return { ...e, unreadMessageCount: 0 };
        }
        return e;
      });
      return updatedUserList;
    });
    const id =
      loginUserId === user?.chatInitiator?.userProfileId
        ? user?.listingOwner?.userProfileId
        : user?.chatInitiator?.userProfileId;
    fetchProfileData(id);
    setSelectedUser(user);
  };

  const handleSendMessage = (message, type) => {
    const submitData = {
      chatId: selectedUser?.id,
      chatMessageType: type,
      message,
      sender: {
        userProfileId: loginUserId,
      },
    };

    sendMessage(submitData)
      .then((res) => {
        dispatch(messageAction.addMessage([...allMessages, submitData]));
      })

      .catch((err) => {
        toast.error(err?.response?.data?.errors[0]);
      });
  };

  return (
    <div>
      <PagesHeader />
      <div className="sub_header_in sticky_header">
        <div className="container p-2">
          <h1 className="wow bounceIn">Messages</h1>
        </div>
      </div>
      <main className="layout-wrapper d-lg-flex container">
        {/* {isUserListLoading && (
          <div className="d-flex justify-content-center align-items-center full-width">
            <p> Loading ... </p>
          </div>
        )} */}
        {userList.length > 0 && !isUserListLoading && (
          <UsersList
            userList={userList}
            handleUserClick={handleUserClick}
            selectedUser={selectedUser}
            setShowProfile={setShowProfile}
          />
        )}
        {window.innerWidth > 991.98 && isChatLoading && (
          <div className="d-flex justify-content-center align-items-center full-width">
            <p> Loading ... </p>
          </div>
        )}

        {!isChatLoading && userList.length > 0 && id && (
          <ChatContent
            userId={id}
            handleSendMessage={handleSendMessage}
            selectedUser={selectedUser}
            setShowProfile={setShowProfile}
          />
        )}
        {!isChatLoading && isEmpty(userList) && (
          <Box
            className="d-flex justify-content-center align-items-center full-width flex-column"
            sx={{
              height: "100vh",
            }}
          >
            <SpeakerNotesOffIcon />
            <p> No Chats Available </p>
          </Box>
        )}
      </main>
      {showProfile && !isProfileLoading && (
        <ShowProfilePopup
          isOpen={showProfile}
          handleClose={() => setShowProfile(false)}
          profileData={senderProfileData}
        />
      )}
    </div>
  );
};

export default Chats;
