import Avatar from "@mui/material/Avatar";
import orderBy from "lodash/orderBy";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Message = ({ setShowProfile }) => {
  const loginReducer = useSelector(
    (state) => state.loginReducer.user.data.userProfile
  );
  const allMessages = useSelector((state) => state.messageReducer.messages);

  const messageContainerRef = useRef(null);
  const { id } = useParams();
  const loginUserId = loginReducer.id;
  const sortedMessages = orderBy(allMessages, "sendTime", "asc");
  useEffect(() => {
    // Scroll to the bottom when allMessages prop changes
    scrollToBottom();
  }, [allMessages, id]);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      setTimeout(() => {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }, 0);
      window.setTimeout(() => {
        messageContainerRef?.current &&
          messageContainerRef?.current.scrollTo({
            top: messageContainerRef?.current?.scrollHeight,
            behavior: "smooth",
          });
      }, 400);

      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  return (
    <div className="chat-conversation" ref={messageContainerRef}>
      <ul className="list-unstyled">
        {sortedMessages?.length > 0 &&
          sortedMessages?.map((msg, i) => (
            <li
              key={i}
              className={`${
                loginUserId === msg.sender.userProfileId ? "right" : "left"
              }`}
            >
              <div className="conversation-list">
                <div className="chat-avatar">
                  <Avatar
                    className={
                      loginUserId !== msg.sender?.userProfileId
                        ? "cursor-pointer"
                        : ""
                    }
                    src={msg.sender?.userProfileImageUrl}
                    onClick={() =>
                      setShowProfile(loginUserId !== msg.sender?.userProfileId)
                    }
                  />
                </div>
                <div className="user-chat-content">
                  <div className="ctext-wrap">
                    <div className="ctext-wrap-content">
                      <div dangerouslySetInnerHTML={{ __html: msg.message }} />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Message;
