import { SET_LISTING_API_VALUE } from "./type";

const defaultReducer = {
  placeId: null,
  countryId: null,
  address: null,
  categoryId: null,
};

const listingApiReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case SET_LISTING_API_VALUE:
      return {
        ...state,
        placeId: action.payload.placeId,
        countryId: action.payload.countryId,
        address: action.payload.address,
        categoryId: action.payload.categoryId,
      };

    default:
      return state;
  }
};

export default listingApiReducer;
