import {
  GET_LISTING_NOTIFICATIONS_PROCESSING,
  GET_LISTING_NOTIFICATIONS_STOP_PROCESSING,
  GET_LISTING_NOTIFICATIONS_SUCCESSFUL,
  UPDATE_NOTIFICATION_STATUS,
} from "./types";

const defaultReducer = {
  processing: false,
  notifications: [],
};

const notificationReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_LISTING_NOTIFICATIONS_SUCCESSFUL:
      return {
        ...state,
        notifications: action.payload,
        processing: false,
      };
    case GET_LISTING_NOTIFICATIONS_PROCESSING:
      return {
        ...state,
        processing: true,
      };
    case UPDATE_NOTIFICATION_STATUS:
      return {
        ...state,
        notifications: action.payload,
        processing: false,
      };
    case GET_LISTING_NOTIFICATIONS_STOP_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default notificationReducer;
