import React from "react";

import HomeComponent from "../../components/USER/Home";
import Footer from "../../shared/Footer";
import PagesHeader from "../../shared/Navbar/PagesHeader";

const Home = () => {
  return (
    <div id="page">
      <PagesHeader />
      <main>
        <HomeComponent />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
