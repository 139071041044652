import React from "react";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import Footer from "../../../shared/Footer";
import ContactMap from "./ContactMap";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <div id="page">
      <PagesHeader />
      <div className="sub_header_in sticky_header">
        <div className="container p-2">
          <h1 className="wow bounceIn">Contact Us</h1>
        </div>
      </div>
      <main className="container">
        <div style={{ paddingTop: "60px" }}>
          <ContactMap />
          <ContactForm />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
