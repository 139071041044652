import React from "react";
import MoreInfoInputIcon from "./MoreInfoInputIcon";

const InputFields = ({
  label,
  type,
  name,
  id,
  iconClass,
  labelClass,
  placeholder,
  value,
  onChange,
  onBlur,
  disabled,
  required,
}) => {
  return (
    <div className={`form-group ${value ? "was-validated" : ""}`}>
      <label className={labelClass} htmlFor={value ? id : ""}>
        {label}
      </label>
      {iconClass !== "fa fa-envelope" &&
      iconClass !== "fa fa-user" &&
      iconClass !== "fa fa-keyboard-o" ? (
        <MoreInfoInputIcon label={label} />
      ) : (
        ""
      )}
      <input
        name={name}
        className="form-control"
        type={type}
        id={id}
        placeholder={placeholder}
        defaultValue={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
      />
      <i className={iconClass}></i>
    </div>
  );
};

export default InputFields;
