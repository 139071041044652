import React from "react";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import Footer from "../../../shared/Footer";
import { Link } from "react-router-dom";
import { CURRENT_YEAR } from "../../../shared/Constants/util-functions";

const CopyRight = () => {
  return (
    <div id="page">
      <PagesHeader />
      <div className="sub_header_in sticky_header">
        <div className="container p-2">
          <h1 className="wow bounceIn">© Copyright Statement</h1>
        </div>
      </div>
      <main className="container">
        <h6 className="mb-5">
          <strong>
            Please also refer to our{" "}
            <Link to="/terms">Terms and Conditions</Link> and{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </strong>
        </h6>
        <div className=" ">
          <h6 className="">
            <strong>
              Copyright © {CURRENT_YEAR} 100xHelper. All Rights Reserved
            </strong>
          </h6>
          <p>
            The content, design, graphics, and other materials on this website are protected by copyright and intellectual property laws. This website and its contents are the property of 100xHelper.
          </p>
          <p>
           Unauthorized use or reproduction of any part of this website or its content is strictly prohibited. You may not use any content from this website for commercial purposes without our prior written consent.
          </p>
          <p>
            You are permitted to download and print a copy of the content for personal, non-commercial use, provided that you do not modify the content or remove any copyright or proprietary notices.
          </p>
          <p>
            By using this website, you agree to comply with all copyright and intellectual property laws, as well as any additional terms and conditions specified on this website. We reserve the right to take legal action against any individual or entity that violates these terms and conditions.
          </p>
          <p>
            For questions or concerns about our copyright statement or the use of content on this website, please contact us at{" "}
            <a href="mailto:support@100xHelper.de.">support@100xHelper.de.</a>.
          </p>
          <p>
            Thank you for respecting our copyright and intellectual property rights.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CopyRight;
