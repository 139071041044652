import React, { useEffect, useState } from "react";
import InputFields from "../../../../shared/InputFieldsWithLabels";
import LocationSearch from "./LocationSearch";
import { toast } from "react-toastify";
import LocalAddressInput from "./LocalAddressInput";

const PrimaryDetails = ({
  setAddress,
  setFormValues,
  formValues,
  address,
  newAddress,
  localAddress,
  setLocalAddress,
  setLatLong,
  setTempAddress,
  warning,
  setTempStreetAddress,
  streetWarning,
  setStreetWarning,
  setWarning,
  listingType,
}) => {
  const [newWarning, setNewWarning] = useState(warning);
  const [newStreetWarning, setNewStreetWarning] = useState(streetWarning);

  useEffect(() => {
    setNewWarning(warning);
  }, [warning]);

  useEffect(() => {
    setNewStreetWarning(streetWarning);
  }, [streetWarning]);

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    const maxLength = {
      title: 250,
      phone: 20,
      website: 2048,
    };

    if (value.length > maxLength[name]) {
      toast.error(
        `${name.charAt(0).toUpperCase() + name.slice(1)} should be max. ${
          maxLength[name]
        } characters.`
      );
      setFormValues({
        ...formValues,
        [name]: "",
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-10 col-md-10">
          <div className="box_account">
            <small className="float-end pt-5">* Required Fields</small>
            <div className="form_container">
              <h3 className="fw-bold">Primary Listing Details</h3>
              <hr />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                  <InputFields
                    label="Title *"
                    type="text"
                    id="title"
                    name="title"
                    value={formValues.title}
                    onBlur={handleChangeForm}
                    labelClass="fw-bold"
                    placeholder="For ex: 1 Room Apartment Available In Mitte!"
                    required={true}
                  />
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5">
                  <p>
                    <b>Tip: </b>
                    Make your ad shine with a descriptive and concise title.
                    Highlight key features like location and amenities to grab
                    attention. A meaningful title increases clicks and interest!
                  </p>
                </div>

                {listingType === "OFFERING" && (
                  <>
                    <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                      <LocalAddressInput
                        newAddress={newAddress}
                        localAddress={localAddress}
                        setLocalAddress={setLocalAddress}
                        setLatLong={setLatLong}
                        address={address}
                        setTempStreetAddress={setTempStreetAddress}
                        warning={newStreetWarning}
                        setStreetWarning={setStreetWarning}
                        listingType={listingType}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                      <InputFields
                        label="Address Line 2 (Optional)"
                        type="text"
                        id="address1"
                        name="address1"
                        value={formValues.address1}
                        onBlur={handleChangeForm}
                        labelClass="fw-bold"
                        placeholder="Additional details here, such as building number/name, floor, etc.."
                      />
                    </div>
                  </>
                )}

                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                  <LocationSearch
                    setAddress={setAddress}
                    address={address}
                    newAddress={newAddress}
                    setLatLong={setLatLong}
                    setTempAddress={setTempAddress}
                    warning={newWarning}
                    setWarning={setWarning}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                  <InputFields
                    label="Mobile/WhatsApp Number *"
                    type="text"
                    id="phone"
                    name="phone"
                    value={formValues.phone}
                    onBlur={handleChangeForm}
                    labelClass="fw-bold"
                    placeholder="111-111-1234"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                  <p>
                    <b>
                      We don't share your PHONE NUMBER with ANYONE. It's only
                      used to maintain the authenticity of users on our
                      platform.
                    </b>
                  </p>
                </div>

                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                  {" "}
                  <InputFields
                    label="Website or External Link"
                    type="text"
                    id="website"
                    name="website"
                    value={formValues.website}
                    onBlur={handleChangeForm}
                    labelClass="fw-bold"
                    placeholder="https://"
                    required={false}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                  <p>
                    <b>Tip: </b>
                    Any external links, it could be your portfolio link or could
                    be a link to the ad you posted on other platforms.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- /form_container --> */}
          </div>
          {/* <!-- /box_account --> */}
        </div>
      </div>
      {/* <!-- /row --> */}
    </div>
  );
};

export default PrimaryDetails;
