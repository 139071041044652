import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const HeaderSelect = ({ classes }) => {
  return (
    <div className="col-lg-4 col-md-12 mt-2">
      <div className="d-flex justify-content-end">
        <span className="fw-bold mt-2 me-1">Display In</span>
        <select
          className="form-select form-control"
          aria-label="Default select example"
          style={{ width: "9rem" }}
        >
          <option selected>Content Area</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
        <div className="ms-4">
          <Link to="/add-events" className="text-light mx-2">
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
            >
              <AddCircleIcon fontSize="small" />
              Add
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderSelect;
