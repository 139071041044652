import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { isEmpty } from "lodash";
import { VerifiedIcon } from "../../../shared/Constants/icons";

const ShowProfilePopup = ({ isOpen, handleClose, profileData }) => {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="text--left"
          sx={{ fontSize: { xs: "15px", md: "17px" }, fontWeight: "500", color: "#283350" }}
        >
          {`${profileData?.userDisplayName}'s Profile`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="position-absolute"
          sx={{ right: 8, top: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {!isEmpty(profileData) && (
            <Box className="d-flex flex-column align-items-center justify-content-center">
              <Avatar
                alt="profile"
                src={profileData?.userProfileImageUrl}
                sx={{ width: "5rem", height: "5rem" }}
              />
              <Box className="mt-3">
                <Box mt={1}>
                  <label className="fw-bold m-0">Full Name : &nbsp;</label>
                  {profileData?.userDisplayName || ""}
                </Box>
                {profileData?.aboutMe && 
                  <Box mt={1}>
                    <label className="fw-bold m-0">About me : &nbsp;</label>
                    {profileData?.aboutMe || ""}
                  </Box>
                }
                <Box className="d-flex align-items-center" gap={2}>
                  <Box className="d-flex align-items-center " mt={1}>
                    <label className="fw-bold m-0">
                      Email Verified : &nbsp;
                    </label>
                    {profileData?.emailVerified ? (
                      <VerifiedIcon size="18" />
                    ) : (
                      "No"
                    )}
                  </Box>
                  <Box mt={1}>
                    <label className="fw-bold m-0">
                      Active Helper : &nbsp;
                    </label>
                    {profileData?.userActiveHelper ? "Yes" : "No"}
                  </Box>
                </Box>
                  <Box mt={1}>
                    <label className="fw-bold m-0">
                      Phone Verified : &nbsp;
                    </label>
                    {profileData?.phoneVerified ? (
                      <VerifiedIcon size="18" />
                    ) : (
                      "No"
                    )}
                  </Box>
                  <Box mt={1}>
                    <label className="fw-bold m-0">
                      ID Verified : &nbsp;
                    </label>
                    {profileData?.idVerified ? (
                      <VerifiedIcon size="18" />
                    ) : (
                      "No"
                    )}
                  </Box>
                {profileData?.nationality && 
                  <Box mt={1}>
                    <label className="fw-bold m-0">Nationality : &nbsp;</label>
                    {profileData?.nationality || ""}
                  </Box>
                }
                {!isEmpty(profileData.languages) && (
                  <Box mt={1}>
                    <label className="fw-bold m-0">Languages : &nbsp;</label>
                    {profileData.languages.toString()}
                  </Box>
                )}
                {!isEmpty(profileData.skills) && (
                  <Box mt={1}>
                    <label className="fw-bold m-0">Skills : &nbsp;</label>
                    {profileData.skills.toString()}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShowProfilePopup;
