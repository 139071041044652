import React from "react";
import CardWrapper from "../../Listing/AddListing/CardWrapper.js";
import InputFields from "../../../../shared/InputFieldsWithLabels/index.js";

const TitleCard = ({ setTitle }) => {
  return (
    <CardWrapper>
      <InputFields
        label="Event Title"
        type="text"
        id="rent"
        name={"rent"}
        onChange={(e) => setTitle(e.target.value)}
        // onBlur={handleChangeForm}
        labelClass="fw-bold"
        placeholder="title"
      />
    </CardWrapper>
  );
};

export default TitleCard;
