import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import EditListingFeatures from "./EditListingFeatures";
import AddListingFeatures from "./AddListingFeatures";
import CardWrapper from "../CardWrapper.js";

const ListingFeatures = ({ getInputObject, setChosenValue }) => {
  const params = useParams();

  const getAllCategories = useSelector(
    (state) => state.categoryReducer.categories
  );

  const [dynamicInputs, setDynamicInputs] = useState(getInputObject);
  const [category, setCategory] = React.useState([]);
  const [categoryListingData, setCategoryListingData] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    setDynamicInputs(getInputObject);
  }, [getInputObject]);

  useEffect(() => {
    setCategory(getAllCategories);
  }, [getAllCategories]);

  useEffect(() => {
    if (category) {
      const listingCategory = category?.filter(
        (item) => item.id === dynamicInputs?.categoryId
      );
      setCategoryListingData(listingCategory);
    }
  }, [category, dynamicInputs?.categoryId]);

  useEffect(() => {
    if (params.id) {
      const resultArray = categoryListingData[0]?.categoryFeatures?.filter(
        (addObj) => {
          // Check if the addObj id exists in editArray
          return !dynamicInputs?.listingFeatures?.some(
            (editObj) => editObj.categoryFeature.id === addObj.id
          );
        }
      );
      setFilteredArray(resultArray);
    }
  }, [categoryListingData, dynamicInputs?.listingFeatures, params.id]);
  return (
    <CardWrapper>
      {dynamicInputs?.categoryFeatures?.length ? (
        <AddListingFeatures
          formFields={dynamicInputs?.categoryFeatures}
          setChosenValue={setChosenValue}
        />
      ) : (
        <EditListingFeatures
          formFields={dynamicInputs?.listingFeatures}
          newArray={filteredArray}
          setChosenValue={setChosenValue}
        />
      )}
    </CardWrapper>
  );
};

export default ListingFeatures;
