import { get } from "lodash";

export const getErrorMessage = (err) => {
  const errors =
    get(err, "err.response.data.errors", []) ||
    get(err, "err.response.data.error", "");
  const status = get(err, "err.response.data.status");
  if (status == 403) {
    return "You are not allowed to access this page! Please contact us at support@100xhelper.de if you are facing any difficulties accessing our platform.";
  }
  if (Array.isArray(errors) && errors.length > 0) {
    return errors[0];
  }
  if (typeof errors === "string") {
    return errors;
  }
  return "";
};

export const CURRENT_YEAR = new Date().getFullYear();
