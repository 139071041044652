import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as categoryAction from "../../../../redux/ListingCategory/action";
import "./listing.css";
import { isEmpty } from "lodash";

const SubHeader = ({
  handleSubmit,
  listingData,
  setPlaceId,
  placeId,
  address,
  selectedCategories,
  setSelectedCategories,
  setAddress,
}) => {
  const params = useLocation();
  const dispatch = useDispatch();
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const getAllCategories = useSelector(
    (state) => state?.categoryReducer?.categories
  );

  const [categories, setCategories] = useState();
  const [selectCategory, setSelectCategory] = useState(selectedCategories);
  const [countryId, setCountryId] = useState(null);
  const storedCountryId = localStorage.getItem("countryId");

  useEffect(() => {
    dispatch(categoryAction.getCategory());
  }, [dispatch]);

  useEffect(() => {
    setCountryId(JSON.parse(storedCountryId));
  }, [storedCountryId]);

  useEffect(() => {
    setCategories(getAllCategories);
  }, [getAllCategories]);

  useEffect(() => {
    setSelectCategory(selectedCategories);
  }, [selectedCategories]);

  const options = useMemo(
    () => ({
      fields: [
        "formatted_address",
        "address_components",
        "geometry",
        "icon",
        "name",
        "place_id",
      ],
      types: ["(cities)"],
    }),
    []
  );

  useEffect(() => {
    if (window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();

        if (place) {
          const { formatted_address, place_id, address_components } = place;
          setPlaceId(place_id);
          const countryComponent = address_components.find((component) =>
            component.types.includes("country")
          );
          setCountryId(countryComponent.short_name);
          setAddress(formatted_address);
        }
      });
    }
  }, [options, setAddress, setPlaceId]);

  return (
    <div className="sub_header_in sticky_header">
      <div className="container">
        <div className="row justify-content-between align-items-center top-search-bar">
          <div style={{ maxWidth: "300px", width: "100%" }}>
            <h4 className="wow bounceIn text-light" style={{ margin: "0px" }}>
              <strong>
                {listingData?.length
                  ? listingData?.length
                  : params?.state?.data?.content
                  ? params?.state?.data?.content?.length
                  : 0}
              </strong>{" "}
              Tasks Found
            </h4>
          </div>
          <div className="col-lg-8 col-md-8 col-12 search-bar-wrapper d-flex justify-content-end align-items-center gap-2">
            <div className="row g-0 custom-search-input-2 inner wow fadeInRight">
              <div className="location-search">
                <div className="form-group">
                  <input
                    className="form-control"
                    ref={inputRef}
                    required
                    type="text"
                    placeholder="Select Your City"
                    defaultValue={address}
                  />
                  <i className="fa fa-location-dot"></i>
                </div>
              </div>
              <div className="search-category">
                <select
                  className="nice-select wide d-block"
                  onChange={({ target: { value } }) => {
                    if (value) {
                      setSelectCategory(value);
                      setSelectedCategories(value);
                    }
                  }}
                  value={selectCategory || ""}
                  placeholder="Select Category"
                  required
                >
                  <option disabled="disabled">Category</option>
                  {!isEmpty(categories) && (
                    <option value={"all"}>Browse all tasks</option>
                  )}
                  {categories?.map((val, index) => (
                    <option value={val.id} key={index}>
                      {val.categoryName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="search-bar-mobile">
                <div className="search-category">
                  <select
                    className="nice-select wide d-block"
                    onChange={({ target: { value } }) => {
                      if (value) {
                        setSelectCategory(value);
                        setSelectedCategories(value);
                      }
                    }}
                    value={selectCategory || ""}
                    placeholder="Select Category"
                    required
                  >
                    <option disabled="disabled">Category</option>
                    {!isEmpty(categories) && (
                      <option value={"all"}>Browse all tasks</option>
                    )}
                    {categories?.map((val, index) => (
                      <option value={val.id} key={index}>
                        {val.categoryName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-2 mb-sm-0 position-relative search-button-mobile">
                  <input
                    style={{
                      width: "100%",
                      paddingLeft: "8px",
                    }}
                    type="submit"
                    onClick={() =>
                      handleSubmit(placeId, selectCategory, countryId)
                    }
                  />
                </div>
              </div>
              <div className="mb-2 mb-sm-0 position-relative search-button">
                <input
                  type="submit"
                  onClick={() =>
                    handleSubmit(placeId, selectCategory, countryId)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /container --> */}
    </div>
  );
};

export default SubHeader;
