import { Close } from "@mui/icons-material";
import { Avatar, Backdrop, Badge, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { listingMediaUpload } from "../../../../helper/APIs/USER/Listing.js";
import MoreInfoInputIcon from "../../../../shared/InputFieldsWithLabels/MoreInfoInputIcon.js";
import CardWrapper from "./CardWrapper.js";
import MediaPreview from "./MediaPreview.js";
const MediaUpload = ({
  files,
  setFiles,
  mediaImages,
  setMediaImages,
  editMediaImages,
  setEditMediaImages,
}) => {
  const [loading, setLoading] = useState(false);

  const handleFileDrop = (acceptedFiles) => {
    let allFilesWithinLimit = true;
    setLoading(true);

    // Filter out files that exceed the size limit
    const newFiles = acceptedFiles.filter((file) => {
      if (file.size > 5 * 1024 * 1024) {
        toast.error(`${file.name} size exceeds the 5MB limit`);
        allFilesWithinLimit = false;
        return false;
      }
      return true;
    });

    if (allFilesWithinLimit) {
      // Combine new files with existing files
      const updatedFiles = [...files, ...newFiles];

      setFiles(updatedFiles);

      const uploadPromises = newFiles.map((file) => {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        return listingMediaUpload(fileFormData)
          .then((response) => ({
            id: "",
            listingId: "",
            mediaUrl: response.data.mediaUrl,
            isFeaturedImage: false,
          }))
          .catch((error) => {
            console.error("Error uploading file:", file.name, error);
            toast.error(`Error uploading ${file.name}`);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((responses) => {
          // Filter out null values which represent skipped files
          const validResponses = responses.filter(
            (response) => response !== null
          );
          setMediaImages((prevMediaImages) => [
            ...prevMediaImages,
            ...validResponses,
          ]);
          setLoading(false);
          // toast.success("All files uploaded successfully.");
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error uploading files", err);
          setMediaImages([]);
          // toast.error("Error uploading files");
        });
    } else {
      setLoading(false);
      setFiles([]); // Clear the files state if any file exceeds the limit
      setMediaImages([]);
    }
  };

  const handleRemoveImage = (val) => {
    const updatedImages = mediaImages.filter(
      (item) => item.mediaUrl !== val.mediaUrl
    );
    setMediaImages(updatedImages);
  };

  return (
    <div>
      <CardWrapper>
        <label className="fw-bold">Media</label>
        <MoreInfoInputIcon label={"Media"} />
        <hr />
        <div className="row">
          <div className="col-12">
            {editMediaImages && (
              <MediaPreview
                medias={editMediaImages}
                setEditMediaImages={setEditMediaImages}
              />
            )}
            {mediaImages.map((val, index) => (
              <Badge
                color="primary"
                sx={{
                  margin: "1rem",
                  borderRadius: "50%",
                }}
                badgeContent={
                  <Close
                    sx={{ padding: "0.2rem", cursor: "pointer" }}
                    onClick={() => handleRemoveImage(val)}
                  />
                }
                key={index}
              >
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    border: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  variant="square"
                  alt="Remy Sharp"
                  src={val.mediaUrl}
                />
              </Badge>
            ))}
          </div>
          <div className="col-12 col-sm-12 col-md-7 col-lg-7">
            <label className="fw-bold">Images *</label>
            <Dropzone onDrop={handleFileDrop}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  {loading ? (
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    ""
                  )}
                  <div
                    {...getRootProps()}
                    style={{
                      height: 200,
                      border: "2px dashed #eeeeee",
                      borderRadius: 2,
                      background: "#FAFAFA",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <input
                      {...getInputProps({
                        accept: "image/jpeg, image/png, image/gif",
                        multiple: true,
                      })}
                    />
                    <i
                      className=" icon-upload-cloud"
                      style={{ fontSize: "2rem", color: "#bdbdbd" }}
                    ></i>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ color: "#bdbdbd", marginBottom: "1rem" }}
                    >
                      Only .jpg, .png files are accepted. Image should not be
                      larger than 10mb.
                    </Typography>
                    <Button variant="outlined" component="label">
                      Browse Files
                    </Button>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="col-12 col-sm-12 col-md-5 col-lg-5">
            <b>
              <u>Enhance Your Ad with Authentic Photos! 📸🏡</u>
            </b>
            <p className="mt-4 fw-bold">
              At 100xHelper, we believe that pictures speak a thousand words.
              Whether you're offering an accommodation or searching for one,
              adding photos to your ad can make all the difference.
            </p>

            <p className="fw-bold">If You're Offering Accommodation:</p>
            <ul>
              <li>
                Upload clear and enticing images of your space. Let potential
                tenants see the beauty and comfort you have to offer. A picture
                is worth a thousand inquiries!
              </li>
            </ul>

            <p className="fw-bold">If You're Looking for Accommodation:</p>
            <ul>
              <li>
                Show landlords who you are by uploading your own photos. It's a
                great way to make your ad stand out and demonstrate your
                sincerity.
              </li>
            </ul>

            <p>
              Remember, pictures help build trust, create a genuine connection,
              and enhance the overall experience. So, get ready to share your
              world through your lens! 📷✨
            </p>
          </div>
        </div>
      </CardWrapper>
    </div>
  );
};

export default MediaUpload;
