import {
  Avatar,
  Backdrop,
  Badge,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { PLACE_OPTIONS, PlaceIdArray } from "../../../Constants/constants";
import Dropzone, { useDropzone } from "react-dropzone";
import { listingMediaUpload } from "../../../../helper/APIs/USER/Listing";
import { toast } from "react-toastify";
import MoreInfoInputIcon from "../../../InputFieldsWithLabels/MoreInfoInputIcon";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const SecondStep = ({
  handleChange,
  values,
  setIsFormValid,
  publicMediaUpload,
  previews,
  removePublicImage,
}) => {
  const isLoggedIn = useSelector((state) => state?.loginReducer?.isLoggedIn);

  const [taskAddressType, setTaskAddressType] = useState(values.locationType);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [mediaImages, setMediaImages] = useState(values.listingMedias);
  const params = useParams();

  const inputRef = useRef();
  const autoCompleteRef = useRef();

  const options = useMemo(
    () => ({
      fields: [
        "formatted_address",
        "address_components",
        "geometry",
        "icon",
        "name",
        "place_id",
      ],
      types: ["geocode", "establishment"],
    }),
    [values?.locationType]
  );

  useEffect(() => {
    if (window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", async () => {
        const place = await autoCompleteRef.current.getPlace();
        if (place) {
          const { address_components, formatted_address, geometry } = place;
          const cityName = address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name;
          const countryName = address_components.find((component) =>
            component.types.includes("country")
          )?.long_name;
          const addressLine1 = address_components.find((component) =>
            component.types.includes("route")
          )?.long_name;
          const addressLine2 = address_components.find((component) =>
            component.types.includes("street_number")
          )?.long_name;
          const postalCode = address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name;
          const locationPlaceId = formatted_address;
          const placeId =
            PlaceIdArray.find((item) => item.key === cityName)?.value ||
            cityName;

          let inputValue = {
            city: {
              cityName: cityName,
              placeId: placeId,
              country: {
                countryName: countryName,
              },
            },
            latitude: geometry.location.lat(),
            longitude: geometry.location.lng(),
            locationPlaceId,
          };
          if (addressLine1) {
            inputValue = {
              ...inputValue,
              addressLine1: addressLine1,
              addressLine2: addressLine2,
            };
          }
          if (postalCode) {
            inputValue = {
              ...inputValue,
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              postalCode: postalCode,
            };
          }
          if (params.id) {
            handleChange(
              {
                target: {
                  value: { id: values.listingLocation.id, ...inputValue },
                  name: "listingLocation",
                },
              },
              {}
            );
          } else {
            handleChange(
              {
                target: {
                  value: { ...inputValue },
                  name: "listingLocation",
                },
              },
              {}
            );
          }
        }
      });
    }
  }, [options, params.id, values.listingLocation]);

  useEffect(() => {
    const isValid =
      values.description.trim() !== "" &&
      values.description.trim().length >= 20 &&
      ((values.locationType === "IN_PERSON" &&
        values.listingLocation.locationPlaceId !== "") ||
        values.locationType === "REMOTE");

    setIsFormValid((prevState) => ({
      ...prevState,
      stepTwo: isValid,
    }));
  }, [values, setIsFormValid]);

  const handleRemoveImage = (index) => {
    const images = [...mediaImages];
    if (index > -1 && index < images.length) {
      images.splice(index, 1);
    }

    handleChange({
      target: {
        name: "listingMedias",
        value: images,
      },
    });
    setMediaImages(images);
  };

  const handleFileDrop = (acceptedFiles) => {
    let allFilesWithinLimit = true;
    setLoading(true);

    // Filter out files that exceed the size limit
    const newFiles = acceptedFiles.filter((file) => {
      if (file.size > 5 * 1024 * 1024) {
        toast.error(`${file.name} size exceeds the 5MB limit`);
        allFilesWithinLimit = false;
        return false;
      }
      return true;
    });

    if (allFilesWithinLimit) {
      // Combine new files with existing files
      const updatedFiles = [...files, ...newFiles];

      setFiles(updatedFiles);

      const uploadPromises = newFiles.map((file) => {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        return listingMediaUpload(fileFormData)
          .then((response) => ({
            featuredImage: false,
            mediaUrl: response.data.mediaUrl,
            isFeaturedImage: false,
          }))
          .catch((error) => {
            console.error("Error uploading file:", file.name, error);
            toast.error(`Error uploading ${file.name}`);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((responses) => {
          // Filter out null values which represent skipped files
          const validResponses = responses.filter(
            (response) => response !== null
          );
          setMediaImages((prevMediaImages) => [
            ...prevMediaImages,
            ...validResponses,
          ]);
          handleChange({
            target: {
              name: "listingMedias",
              value: [...mediaImages, ...validResponses],
            },
          });
          setLoading(false);
          // toast.success("All files uploaded successfully.");
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error uploading files", err);
          setMediaImages([]);
          // toast.error("Error uploading files");
        });
    } else {
      setLoading(false);
      setFiles([]); // Clear the files state if any file exceeds the limit
      setMediaImages([]);
    }
  };

  return (
    <Box>
      <Box
        className="d-flex mt--20"
        gap={{
          xs: "10px",
          sm: "10px",
          md: "20px",
          lg: "20px",
        }}
      >
        {PLACE_OPTIONS.map((item) => {
          return (
            <Box
              key={item.label}
              onClick={() => {
                handleChange(
                  {
                    target: {
                      name: "locationType",
                      value: item.value,
                    },
                  },
                  {
                    listingLocation: {
                      city: {
                        cityName: "",
                        placeId: "",
                        country: {
                          countryName: "",
                        },
                      },
                      latitude: 0,
                      longitude: 0,
                      locationPlaceId: "",
                    },
                  }
                );
                setTaskAddressType(item.value);
              }}
              className={`cursor-pointer ${
                taskAddressType === item.value
                  ? "active-flexible"
                  : "tasker-items"
              } flexible-wrapper`}
            >
              {item.icon}
              <Typography
                className="item-title"
                sx={{
                  fontFamily: "Poppins",
                }}
              >
                {item.label}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                }}
              >
                {item.subText}
              </Typography>
            </Box>
          );
        })}
      </Box>

      {taskAddressType === "IN_PERSON" && (
        <FormControl
          sx={{
            maxWidth: "300px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Box>
            <label
              className="fw-bold"
              style={{
                fontSize: "17px",
              }}
            >
              Where do you need this done? *
            </label>
            <Box className="position-relative opacity-100">
              <input
                className="form-control city-search"
                type="text"
                placeholder="Enter your address"
                ref={inputRef}
                defaultValue={values.listingLocation?.locationPlaceId}
              />
              <i
                className="fa fa-location-dot"
                style={{
                  position: "absolute",
                  top: "36%",
                  right: "13px",
                }}
              ></i>
            </Box>
          </Box>
        </FormControl>
      )}

      <Box className="mt--20">
        <TextField
          fullWidth
          label="Description*"
          size="small"
          name="description"
          value={values.description}
          onChange={(e) => handleChange(e, {})}
          margin="normal"
          variant="outlined"
          multiline
          rows={3}
          inputProps={{ maxLength: 2048 }}
          placeholder="Describe your task in detail. Include any specific tools or equipment the helper needs to bring."
          sx={{
            "& .MuiInputLabel-root": {
              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
            },
            "& .MuiOutlinedInput-input": {
              fontFamily: "Poppins !important",

              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
            },
            "& .MuiFormLabel-root": {
              fontFamily: "Poppins !important",
              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
            },
            "& .MuiInputBase-root": {
              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
            },

            "& .MuiInputBase-input::placeholder": {
              fontFamily: "Poppins !important",
              "@media (max-width: 600px)": {
                fontSize: "0.9rem",
              },
            },
          }}
          InputProps={{
            sx: {
              input: {
                fontFamily: "Poppins",
              },
            },
          }}
        />
        <FormHelperText
          className="m--0"
          sx={{
            fontFamily: "Poppins",
          }}
        >
          Note: Description should be 20 characters or more
        </FormHelperText>
      </Box>
      <Box className="mt--20">
        <div className="col-12 col-sm-12 col-md-7 col-lg-7 p--0">
          <label className="fw-bold d-flex align-items--center">
            Add images (optional)
            <MoreInfoInputIcon
              label={
                "Only .jpg, .png files are accepted. Image should not be larger than 10mb."
              }
            />
          </label>
          <Box
            className="mt--20"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                flexWrap: "wrap",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {isLoggedIn ? (
                <>
                  {values.listingMedias.map((val, index) => (
                    <Badge
                      color="primary"
                      badgeContent={
                        <Box
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleRemoveImage(index)}
                        >
                          &#x2715;
                        </Box>
                      }
                      key={index}
                    >
                      <Avatar
                        sx={{
                          width: 80,
                          height: 80,
                          borderRadius: "8px",
                          border: "1px solid black",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        variant="square"
                        alt="Remy Sharp"
                        src={val.mediaUrl}
                      />
                    </Badge>
                  ))}
                </>
              ) : (
                <>
                  {previews.map((val, index) => (
                    <Badge
                      color="primary"
                      badgeContent={
                        <Box
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => removePublicImage(val)}
                        >
                          &#x2715;
                        </Box>
                      }
                      key={index}
                    >
                      <Avatar
                        sx={{
                          width: 80,
                          height: 80,
                          borderRadius: "8px",
                          border: "1px solid black",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        variant="square"
                        alt="Remy Sharp"
                        src={val}
                      />
                    </Badge>
                  ))}
                </>
              )}
            </Box>

            {(isLoggedIn ? values.listingMedias : previews).length < 5 && (
              <Dropzone
                accept={{
                  "image/jpeg": [],
                  "image/png": [],
                  "image/jpg": [],
                }}
                onDrop={(acceptedFiles) => {
                  if (isLoggedIn) {
                    handleFileDrop(acceptedFiles);
                  } else {
                    publicMediaUpload(acceptedFiles);
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    {loading ? (
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    ) : (
                      ""
                    )}
                    <div {...getRootProps()} className="stepper-media-upload">
                      <input
                        {...getInputProps({
                          accept: "image/jpeg, image/png, image/jpeg",
                          multiple: true,
                        })}
                      />

                      <svg width="24" height="24" viewBox="0 0 24 24">
                        <path
                          d="M12 24C10.34 24 8.78 23.6848 7.32 23.0544C5.86 22.4248 4.59 21.57 3.51 20.49C2.43 19.41 1.5752 18.14 0.9456 16.68C0.3152 15.22 0 13.66 0 12C0 10.34 0.3152 8.78 0.9456 7.32C1.5752 5.86 2.43 4.59 3.51 3.51C4.59 2.43 5.86 1.5748 7.32 0.9444C8.78 0.3148 10.34 0 12 0C13.66 0 15.22 0.3148 16.68 0.9444C18.14 1.5748 19.41 2.43 20.49 3.51C21.57 4.59 22.4248 5.86 23.0544 7.32C23.6848 8.78 24 10.34 24 12C24 13.66 23.6848 15.22 23.0544 16.68C22.4248 18.14 21.57 19.41 20.49 20.49C19.41 21.57 18.14 22.4248 16.68 23.0544C15.22 23.6848 13.66 24 12 24ZM12 21.6C14.68 21.6 16.95 20.67 18.81 18.81C20.67 16.95 21.6 14.68 21.6 12C21.6 9.32 20.67 7.05 18.81 5.19C16.95 3.33 14.68 2.4 12 2.4C9.32 2.4 7.05 3.33 5.19 5.19C3.33 7.05 2.4 9.32 2.4 12C2.4 14.68 3.33 16.95 5.19 18.81C7.05 20.67 9.32 21.6 12 21.6Z"
                          fill="#283350"
                        ></path>
                        <path
                          d="M13.2 18H10.8V13.2H6V10.8H10.8V6H13.2V10.8H18V13.2H13.2V18Z"
                          fill="#283350"
                        ></path>
                      </svg>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );
};
export default SecondStep;
